import { FC } from 'react';
import { Trans } from 'react-i18next';

import { TDebtData } from '../../../../../shared/data-access/debts/debt-data.model';
import { Spinner } from '../../../../../shared/desig-system/spinner.component';
import { twMerge } from '../../../../../shared/desig-system/tailwind.config';
import { Typography } from '../../../../../shared/desig-system/typography.component';
import { usePaymentAgreementProposalsQuery } from '../../../data-access-payment-agreement-proposal/hooks';
import { CompactDebtCard } from '../../../ui-card-payment/debt-card.component';
import { PaperOffer } from './paper-offer.component';

interface IPaymentAgreementProposalsForClaimProps {
  debt: TDebtData;
  className?: string;
}

export const PaymentAgreementProposalsForClaim: FC<IPaymentAgreementProposalsForClaimProps> = ({ debt, className }) => {
  const proposalsQuery = usePaymentAgreementProposalsQuery(debt.ClaimId);

  if (proposalsQuery.isLoading) return <Spinner />;
  if (proposalsQuery.isError) {
    return (
      <Typography variant="h1" className="text-center break-normal">
        <Trans i18nKey={'PaymentAgreementProposals.ErrorLoadingProposals'} />
      </Typography>
    );
  }

  const proposals = proposalsQuery.data;

  const shouldShowOfferPapers = proposals?.length;
  if (!shouldShowOfferPapers) {
    return <CompactDebtCard key={debt.ClaimId} debt={debt} />;
  }

  return (
    <div className={twMerge('grid grid-cols-paymentagreementproposals gap-4 break-normal', className)}>
      {proposals.map((proposal) => (
        <PaperOffer proposal={proposal} key={`${proposal.PaymentAgreementProposalId}-${proposal.SortOrder}`} />
      ))}
    </div>
  );
};

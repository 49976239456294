import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { TClaimId } from '../../../shared/data-access/debts/debt-data.model';
import { B2Button } from '../../../shared/desig-system/button.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { TInstalment } from '../data-access-plan-list/payment-plan.model';
import {
  useUpcomingPaymentListForClaimQuery,
  useUpcomingPaymentListForInstalmentsQuery,
} from '../data-access-upcoming-payment/hooks';
import { TUpcomingPayment } from '../data-access-upcoming-payment/upcoming-payment.model';

interface IBasePaymentButtonProps {
  showNote?: boolean;
  className?: string;
  buttonClassName?: string;
}

interface IPaymentButtonProps extends IBasePaymentButtonProps {
  upcomingPayments: TUpcomingPayment[];
}

export interface IClaimPaymentButtonsProps extends IBasePaymentButtonProps {
  claimId: TClaimId;
}

export interface IInstalmentPaymentButtonsProps extends IBasePaymentButtonProps {
  instalments: TInstalment[];
  nextInstalmentOnly: boolean;
}

const PaymentButtons: FC<IPaymentButtonProps> = ({ showNote, className, upcomingPayments, buttonClassName }) => {
  return (
    <div className={className}>
      {upcomingPayments.map((payment) => (
        <Fragment key={payment.UpcomingPaymentId}>
          {showNote && <Typography variant="h6">{payment.NoteToUser}</Typography>}
          <Link to={`/payments/pay/${payment.UpcomingPaymentId}`} className="w-full">
            <B2Button className={buttonClassName} variant={showNote ? 'secondary' : 'primary'}>
              <Typography variant="button">{payment.Description}</Typography>
            </B2Button>
          </Link>
        </Fragment>
      ))}
    </div>
  );
};

export const ClaimPaymentButtons: FC<IClaimPaymentButtonsProps> = ({ claimId, ...props }) => {
  const upcomingClaimPayments = useUpcomingPaymentListForClaimQuery(claimId);
  return upcomingClaimPayments.data ? (
    <PaymentButtons upcomingPayments={upcomingClaimPayments.data} {...props} />
  ) : null;
};

export const InstalmentPaymentButtons: FC<IInstalmentPaymentButtonsProps> = ({
  instalments,
  nextInstalmentOnly,
  ...props
}) => {
  const upcomingInstalmentPayments = useUpcomingPaymentListForInstalmentsQuery(instalments);

  const renderPaymentButtons: TUpcomingPayment[] = [];

  if (nextInstalmentOnly) {
    const orderedInstalmentsByDueDate = instalments.sort((a, b) => Date.parse(a.DueDate) - Date.parse(b.DueDate));
    for (let i = 0; i < orderedInstalmentsByDueDate.length; i++) {
      const firstPayment = upcomingInstalmentPayments.data?.find(
        (p) => p.InstalmentsCovered?.some((ic) => ic === orderedInstalmentsByDueDate[i].InstalmentId)
      );
      if (firstPayment) {
        renderPaymentButtons.push(firstPayment);
        break;
      }
    }
  } else {
    if (upcomingInstalmentPayments.data) {
      renderPaymentButtons.push(...upcomingInstalmentPayments.data);
    }
  }

  return renderPaymentButtons ? <PaymentButtons upcomingPayments={renderPaymentButtons} {...props} /> : null;
};

import { useEffect } from 'react';

export const useScrollToTop = () => {
  useEffect(() => {
    const element = document.getElementById('root');
    element?.scrollTo({
      top: 0,
    });
  });

  return null;
};

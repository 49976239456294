import { Trans } from 'react-i18next';

import { B2Modal } from '../../desig-system/b2modal.component';
import { B2Button } from '../../desig-system/button.component';
import { Typography } from '../../desig-system/typography.component';

type TCookieModalProps = {
  toggleIsVisible: () => void;
  openCookieManagementModal: () => void;
  acceptAllCookies: () => void;
  acceptOnlyNecessaryCookies: () => void;
  isVisible: boolean;
};

export const CookieModal = ({
  toggleIsVisible,
  openCookieManagementModal,
  acceptAllCookies,
  acceptOnlyNecessaryCookies,
  isVisible,
}: TCookieModalProps) => {
  return (
    <B2Modal isOpen={isVisible} onRequestClose={toggleIsVisible} className="max-w-[400px]">
      <B2Modal.Title>
        <Trans i18nKey={'CookieConsent.Title'} />
      </B2Modal.Title>

      <Typography variant="body">
        <Trans i18nKey={'CookieConsent.Description'} />
      </Typography>

      <div className="flex flex-col align-middle space-y-4 mt-4 mb-4">
        <B2Button
          variant="primary"
          onClick={() => {
            acceptAllCookies();
            toggleIsVisible();
          }}
        >
          <Typography variant="button">
            <Trans i18nKey={'CookieConsent.AllowAllButton'} />
          </Typography>
        </B2Button>
        <B2Button
          variant="link"
          onClick={() => {
            acceptOnlyNecessaryCookies();
            toggleIsVisible();
          }}
        >
          <Typography variant="button">
            <Trans i18nKey={'CookieConsent.AcceptOnlyNecessaryButton'} />
          </Typography>
        </B2Button>
        <B2Button
          variant="secondary"
          onClick={() => {
            toggleIsVisible();
            openCookieManagementModal();
          }}
        >
          <Typography variant="button">
            <Trans i18nKey={'CookieConsent.ManageCookiesButton'} />
          </Typography>
        </B2Button>
      </div>
    </B2Modal>
  );
};

import { useQuery } from '@tanstack/react-query';

import { fetchAllFeatureFlags, fetchFeatureFlag, fetchFeatureFlagsByPrefix } from './api';

export const useAllFeatureFlagsQuery = () =>
  useQuery({
    queryKey: ['all-feature-flags'],
    queryFn: () => fetchAllFeatureFlags(),
  });

export const useFeatureFlagQuery = (featureFlagName: string) =>
  useQuery({
    queryKey: ['feature-flag'],
    queryFn: () => fetchFeatureFlag(featureFlagName),
  });

export const useFeatureFlagByPrefixQuery = (prefix: string) =>
  useQuery({
    queryKey: ['feature-flags-by-prefix'],
    queryFn: () => fetchFeatureFlagsByPrefix(prefix),
  });

import { z } from 'zod';

const currencySchema = z.union([z.literal('EUR'), z.literal('GBP'), z.literal('USD'), z.literal('PLN')]);
const cmsIdSchema = z.string().brand<'cmsId'>();
const customerIdSchema = z.string().brand<'customerId'>();
const claimIdSchema = z.string().brand<'claimId'>();
const paymentAgreementProposalIdSchema = z.string().brand<'paymentAgreementProposalId'>();

const paymentAgreementRequestSchema = z.object({
  PaymentAgreementProposalId: paymentAgreementProposalIdSchema,
  FirstPaymentDate: z.string().optional(),
  // FirstPaymentDate: z.date().optional(), // TODO: [date] check if switching to date type will work out of the box
  ConfirmationTime: z.string(),
  // ConfirmationTime: z.date(), // TODO: [date] check if switching to date type will work out of the box
});

const proposalSchema = z.object({
  // FirstInstalmentDate: z.date(), // TODO: [date] check if switching to date type will work out of the box
  FirstInstalmentDate: z.string(),
  PlanTotalAmount: z.number(),
  InstalmentAmount: z.number(),
  NumberOfInstalments: z.number(),
  Discount: z.number().optional(),
  BenefitsText: z.string().optional(),
});

const paymentAgreementProposalSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  PaymentAgreementProposalId: paymentAgreementProposalIdSchema,
  OfferName: z.string(),
  SortOrder: z.number(),
  ClaimsCovered: z.array(claimIdSchema),
  Currency: currencySchema,
  OfferOptions: z.array(proposalSchema),
  FreezeOfInterest: z.boolean().optional(),
  OfferValidUntil: z.string().optional(),
  // OfferValidUntil: z.date().optional(), // TODO: [date] check if switching to date type will work out of the box
});

export const paymentAgreementProposalsSchema = z.array(paymentAgreementProposalSchema);

export type TPaymentAgreementRequest = z.infer<typeof paymentAgreementRequestSchema>;
export type TPaymentAgreementProposalId = z.infer<typeof paymentAgreementProposalIdSchema>;
export type TPaymentAgreementProposal = z.infer<typeof paymentAgreementProposalSchema>;
export type TProposal = z.infer<typeof proposalSchema>;

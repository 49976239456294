import { useIsAuthenticated } from '@azure/msal-react';
import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { twMerge } from '../desig-system/tailwind.config';
import { Typography } from '../desig-system/typography.component';
import { useBreakpoint } from '../utils/media-queries';
import { CookieContext } from './cookies/cookie-management.provider';

export interface IFooterProps {
  className?: string;
  feedbackLink?: string;
}

export const Footer: FC<IFooterProps> = ({ className, feedbackLink }) => {
  const isDesktop = useBreakpoint('desktop');
  return (
    <div
      className={twMerge(
        'bg-contentbglight flex flex-col text-center items-center desktop:flex-row desktop:items-start justify-start text-center p-16px desktop:pb-24px desktop:px-32px',
        className
      )}
    >
      <div className="flex flex-col h-full">
        <Typography variant="h6" className="max-w-[340px] desktop:w-[340px] desktop:text-start">
          <Trans i18nKey={'Footer.CompanyName'} />
        </Typography>
        <Typography variant="body" className="max-w-[340px] desktop:w-[340px] desktop:text-start">
          <Trans i18nKey={'Footer.BodyText'} />
        </Typography>
        <Typography
          variant="body"
          className="text-center max-w-[340px] desktop:w-[340px] text-start desktop:flex flex-col justify-end grow"
        >
          <Trans i18nKey={'Footer.CopyrightText'} />
        </Typography>
      </div>
      <div className="flex flex-col h-full justify-center mt-24px desktop:mt-0 desktop:flex-grow">
        {FooterContactInfo(isDesktop)}
      </div>
      <div className="flex flex-col h-full justify-center mt-24px desktop:self-end">
        <FooterLinks feedbackLink={feedbackLink} />
      </div>
    </div>
  );
};

const ServiceTimes: FC<{ isDesktop: boolean }> = ({ isDesktop }) => {
  if (isDesktop) {
    return (
      <Typography variant="body" className={isDesktop ? 'text-start' : ''}>
        <Trans i18nKey={'ContactInfo.TitleTime'} /> <Trans ns="configuration" i18nKey={'ContactInfo.ServiceTimes'} />
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="body" className={isDesktop ? 'text-start' : ''}>
        <Trans i18nKey={'ContactInfo.TitleTime'} />
      </Typography>
      <Typography variant="body" className={isDesktop ? 'text-start' : ''}>
        <Trans ns="configuration" i18nKey={'ContactInfo.ServiceTimes'} />
      </Typography>
    </>
  );
};

const FooterContactInfo = (isDesktop: boolean) => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) return <></>;

  return (
    <>
      <Typography variant="h6" className={isDesktop ? 'text-start' : ''}>
        <Trans i18nKey={'ContactInfo.TitleMain'} />
      </Typography>
      <ServiceTimes isDesktop={isDesktop} />
      <>
        <a href={`tel:${t('ContactInfo.NumberPhone', { ns: 'configuration' })}`}>
          <div className="text-primaryblue hover:underline">
            <Typography variant="button" className={isDesktop ? ' my-auto text-start' : ''}>
              <i className="bx bx-phone mr-2 text-xl mb-[1px]" />
              <Trans i18nKey={'ContactInfo.TitlePhone'} />{' '}
              <Trans ns="configuration" i18nKey={'ContactInfo.NumberPhone'} />
            </Typography>
          </div>
        </a>
        <Link to="/contact" className={isAuthenticated ? 'visible' : 'invisible'}>
          <div className="text-primaryblue hover:underline">
            <Typography variant="button" className={isDesktop ? ' my-auto text-start' : ''}>
              <i className="bx bx-envelope mr-2 text-xl mb-[1px]" />
              <Trans i18nKey={'ContactInfo.ButtonMessage'} />
            </Typography>
          </div>
        </Link>
      </>
    </>
  );
};

const FooterLinks: FC<IFooterProps> = ({ feedbackLink }) => {
  const { setCookieManagementIsOpen } = useContext(CookieContext);
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <div className="text-start grow">
        {isAuthenticated && !!feedbackLink && (
          <a href={feedbackLink} rel="noopener noreferrer" target="_blank">
            <Typography variant="button" className="text-primaryblue pl-1">
              <Trans i18nKey={'Footer.Links.FeedbackLink'} />
            </Typography>
          </a>
        )}
      </div>
      <div className="text-center flex flex-col divide-indigo-500 desktop:flex-row desktop:divide-x gap-1">
        <Typography variant="button" className="text-primaryblue pl-1">
          <Link to="/terms-of-use" className="hover:underline">
            <Trans i18nKey={'Footer.Links.TermsOfUse'} />
          </Link>
        </Typography>
        <Typography variant="button" className="text-primaryblue pl-1">
          <Link to="/privacy-policy" className="hover:underline">
            <Trans i18nKey={'Footer.Links.PrivacyPolicy'} />
          </Link>
        </Typography>
        <Typography variant="button" className="text-primaryblue pl-1">
          <Link to="/cookie-policy" className="hover:underline">
            <Trans i18nKey={'Footer.Links.CookiePolicy'} />
          </Link>
        </Typography>
        <Typography variant="button" className="text-primaryblue pl-1">
          <button className="hover:underline" onClick={() => setCookieManagementIsOpen(true)}>
            <Trans i18nKey={'Footer.Links.CookieSettings'} />
          </button>
        </Typography>
      </div>
    </>
  );
};

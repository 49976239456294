import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TClaimId } from '../../../shared/data-access/debts/debt-data.model';
import { useDebtQuery } from '../../../shared/data-access/debts/hooks';
import { Spinner } from '../../../shared/desig-system/spinner.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { DebtCard } from '../ui-card-payment/debt-card.component';

export const DebtDetails = () => {
  const { id } = useParams();
  const debt = useDebtQuery(id as TClaimId);
  if (debt.isLoading) return <Spinner />;
  if (debt.isError)
    return (
      <Typography variant="h1" className="text-center">
        <Trans i18nKey={'DebtPage.ingDebt'} />
      </Typography>
    );
  if (!debt.data) {
    return (
      <Typography variant="h1" className="text-center">
        <Trans i18nKey={'DebtPage.NoDebtData'} />
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h1" className="text-center">
        {debt.data.OriginalCreditor || debt.data.NameOfProduct}
      </Typography>
      <DebtCard debt={debt.data} showDetails />
    </>
  );
};

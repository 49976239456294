import { ReactNode, useEffect } from 'react';

import { useFeatureFlagByPrefixQuery } from '../features/account/data-access-feature-flag/hooks';

type TFeatureFlagProviderProps = {
  children: ReactNode;
};

export const FeatureFlagProvider = ({ children }: TFeatureFlagProviderProps) => {
  const { data } = useFeatureFlagByPrefixQuery('Portal');

  useEffect(() => {
    if (data && !sessionStorage.getItem('features')) {
      sessionStorage.setItem('features', JSON.stringify(data));
    }
  }, [data]);

  return <>{children}</>;
};

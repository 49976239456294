import Axios from 'axios';

import { env } from '../../env.config';
import { msalInstance } from '../../index';

export const axiosInstance = Axios.create({
  baseURL: env.apiUrl,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const account = await msalInstance.getAllAccounts()[0];
    const tokenResult = await msalInstance.acquireTokenSilent({
      scopes: env.auth.scopes,
      account,
    });
    config.headers.set({
      ...(config.headers ?? {}),
      'Api-Version': env.apiVersion,
      Authorization: `Bearer ${tokenResult.accessToken}`,
    });

    return config;
  },
  (error) => {
    throw error;
  }
);

import { useEffect, useState } from 'react';

import { theme } from '../../../tailwind.config';

export const useMediaQuery = (query: string): boolean => {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
};

const breakpoints = theme.screens;
type TBreakpointKey = keyof typeof breakpoints;
export const useBreakpoint = <K extends TBreakpointKey>(breakpointKey: K) => {
  const matches = useMediaQuery(`(min-width: ${breakpoints[breakpointKey]})`);
  return matches;
};

export const useCurrentScreenSize = (): TBreakpointKey => {
  const isMobile = useBreakpoint('mobile');
  const isTablet = useBreakpoint('tablet');
  const isDesktop = useBreakpoint('desktop');

  if (isDesktop) return 'desktop';
  if (isTablet) return 'tablet';
  if (isMobile) return 'mobile';
  return 'mobile';
};

interface IEnvConfig {
  production: boolean;
  cmsId: string;
  publicUrl: string;
  apiUrl: string;
  apiVersion: string;
  languages: string[];
  auth: {
    authorityDomain: string;
    clientId: string;
    scopes: string[];
    flows: {
      signUpSignIn: string;
      changePassword: string;
      removeAccount: string;
      changeEmail: string;
      changePhoneNumber: string;
    };
  };
  matomo: {
    baseUrl: string;
    siteId: string;
  };
  logger: {
    applicationInsightKey: string;
  };
}

export const getEnvConfig = (): IEnvConfig => ({
  production: process.env.NODE_ENV !== 'development',
  cmsId: process.env.REACT_APP_CMSID || '',
  publicUrl: process.env.PUBLIC_URL || '',
  apiUrl: process.env.REACT_APP_API_BASE_URL || '',
  apiVersion: process.env.REACT_APP_API_VERSION || '0.0.0',
  languages: (process.env?.REACT_APP_CONFIGURABLE_LANGUAGES || 'en').split(','),
  auth: {
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN || '',
    clientId: process.env.REACT_APP_OAUTH_CLIENTID || '',
    scopes: process.env.REACT_APP_OAUTH_CLIENTID ? [`${process.env.REACT_APP_OAUTH_CLIENTID}`] : [],
    flows: {
      signUpSignIn: process.env.REACT_APP_B2C_SIGNUPSIGNIN_FLOW_URL || '',
      changePassword: process.env.REACT_APP_B2C_CHANGEPASSWORD_FLOW_URL || '',
      removeAccount: process.env.REACT_APP_B2C_REMOVEACCOUNT_FLOW_URL || '',
      changeEmail: process.env.REACT_APP_B2C_CHANGEEMAIL_FLOW_URL || '',
      changePhoneNumber: process.env.REACT_APP_B2C_CHANGEPHONENUMBER_FLOW_URL || '',
    },
  },
  matomo: {
    baseUrl: process.env.REACT_APP_MATOMO_BASE_URL || '',
    siteId: process.env.REACT_APP_MATOMO_SITE_ID || '',
  },
  logger: {
    applicationInsightKey: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING || '',
  },
});

export const env = getEnvConfig();

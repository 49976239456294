import { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { SpinnerWrapper } from '../../../shared/desig-system/spinner.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { usePlansListQuery } from '../data-access-plan-list/hooks';
import { TPaymentAgreement } from '../data-access-plan-list/payment-plan.model';
import { PlanListCard } from '../ui-card-payment/plan-list-card.component';

export const PlanList = () => {
  const plansQueryResult = usePlansListQuery();
  const isDesktop = useBreakpoint('desktop');

  const activePlans: TPaymentAgreement[] = useMemo(
    () => (plansQueryResult.data?.length ? plansQueryResult.data : ([] as TPaymentAgreement[])),
    [plansQueryResult]
  );

  return (
    <>
      <Typography variant="h1">
        <Trans i18nKey="PlansView.TitleList" />
      </Typography>
      <SpinnerWrapper isLoading={plansQueryResult.isLoading}>
        <div className={`${isDesktop ? 'flex flex-col gap-5' : 'max-w-[340px] flex flex-col gap-5'}`}>
          {plansQueryResult.isError ? (
            <Typography variant="body" className="text-error">
              <Trans i18nKey={'PlansView.ErrorLoadingPaymentPlans'} />
            </Typography>
          ) : (
            !activePlans.length && (
              <Typography variant="body">
                <Trans i18nKey={'PlansView.NoPlans'} />
              </Typography>
            )
          )}
          {!plansQueryResult.isError &&
            activePlans.map((plan) => <PlanListCard key={plan.PaymentAgreementId} plan={plan} />)}
        </div>
      </SpinnerWrapper>
    </>
  );
};

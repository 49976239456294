import { useMutation, useQuery } from '@tanstack/react-query';

import { TClaimId } from '../../../shared/data-access/debts/debt-data.model';
import { fetchPaymentAgreementProposals, submitPaymentAgreementRequest } from './api';
import { TPaymentAgreementProposalId, TPaymentAgreementRequest } from './payment-agreement-proposal.model';

export const usePaymentAgreementProposalsQuery = (forDebt: TClaimId) =>
  useQuery({
    queryKey: ['payment-agreement-proposals'],
    queryFn: () => fetchPaymentAgreementProposals(),
    select: (data) => {
      if (data?.length) {
        return data
          .filter((p) => p.ClaimsCovered?.map((claimId) => claimId.toString()).includes(forDebt))
          .sort((a, b) => a.SortOrder - b.SortOrder);
      }
      return [];
    },
  });

export const usePaymentAgreementProposalsByIdQuery = (proposalId: TPaymentAgreementProposalId) =>
  useQuery({
    queryKey: ['payment-agreement-proposals'],
    queryFn: () => fetchPaymentAgreementProposals(),
    select: (data) => data.find((p) => p.PaymentAgreementProposalId === proposalId),
  });

export const usePaymentAgreementRequestMutation = () =>
  useMutation({
    mutationKey: ['payment-agreement-request'],
    mutationFn: (data: TPaymentAgreementRequest) => submitPaymentAgreementRequest(data),
  });

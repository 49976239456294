module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    fontFamily: {
      sans: ['Source Sans Pro', 'sans-serif'],
    },
    screens: {
      mobile: '320px',
      tablet: '720px',
      desktop: '1024px',
    },
    extend: {
      colors: {
        black: '#000000',
        error: '#FF0000',
        backdropoverlay: '#303030',
        primaryblue: '#2F347E',
        primarywhite: '#FFFFFF',
        primarytext: '#303030',
        placeholdertext: '#727272',
        textdisabled: '#BDBDBD',
        primarybutton: '#2F347E',
        primarybuttonhover: '#005580',
        buttondisabled: '#E0E0E0',
        linkbutton: '#D8EDFF',
        offerlabel: '#D8EDFF',
        linkbuttonhover: '#BBDFFE',
        buttonhover: '#005580',
        buttonfocus: '#339999',
        buttontextdisabled: '#8E8E8E',
        contentbgdark: '#DCDCDC',
        contentbglight: '#F1F1F1',
        formborders: '#CACACA',
        dividersdark: '#484848',
        dividerslight: '#DFDFDF',
      },
      fontSize: {
        48: ['48px', '64px'],
        40: ['40px', '48px'],
        32: ['32px', '40px'],
        26: ['26px', '32px'],
        24: ['24px', '32px'],
        20: ['20px', '24px'],
        18: ['18px', '24px'],
        16: ['16px', '24px'],
        14: ['14px', '16px'],
        12: ['12px', '16px'],
      },
      padding: {
        '4px': '4px',
        '8px': '8px',
        '12px': '12px',
        '16px': '16px',
        '24px': '24px',
        '32px': '32px',
        '40px': '40px',
      },
      margin: {
        '4px': '4px',
        '12px': '12px',
        '16px': '16px',
        '24px': '24px',
        '32px': '32px',
        '40px': '40px',
      },
      flexGrow: {
        2: 2,
        3: 3,
        4: 4,
      },
      boxShadow: {
        buttonhover: '0px 4px 8px rgba(0, 0, 0, 0.25)',
        navbar: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        card: '0px 3px 5px -1px rgba(0, 0, 0, 0.15), 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.08)',
      },
      gridTemplateColumns: {
        paymentagreementproposals: 'repeat(auto-fit, minmax(360px, 1fr))',
      },
    },
  },
  plugins: [],
};

type TMatomoEvents =
  | 'Start'
  | 'InputPin'
  | 'ChooseType'
  | 'VerifyStart'
  | 'VerifyComplete'
  | 'CreateEmail'
  | 'VerifyEmail'
  | 'CreatePassword'
  | 'InputEmail'
  | 'PaymentStart'
  | 'PaymentComplete'
  | 'PaymentFailed'
  | 'Select'
  | 'Confirm'
  | 'Failed'
  | 'Pending'
  | 'AuthComplete'
  | 'FailedCmsIdMismatch'
  | 'Success'
  | 'AcceptTerms'
  | 'ChangeEmailAddressEvent'
  | 'ChangePhonenumber';

type TMatomoCategoryType = {
  Category: string;
};

type TMatomoActionType<T extends TMatomoEvents> = {
  [key in T]: {
    Action: string;
    Name: string;
  };
};

export type TMatomoEventType<T extends TMatomoEvents> = TMatomoCategoryType & TMatomoActionType<T>;

/**
 * These events are listed in the Excel sheet "Self Service Analytics - Specification"
 * found in Teams channel Group.PRJ Self-service > Features > Files.
 */

// -------------------- AUTHENTICATION EVENTS --------------------

export const LoginEvent: TMatomoEventType<'Start' | 'AuthComplete' | 'FailedCmsIdMismatch' | 'Success'> = {
  Category: 'Login',
  Start: {
    Action: 'Start',
    Name: 'LoginStart',
  },
  AuthComplete: {
    Action: 'Authentication Complete',
    Name: 'LoginAuthComplete',
  },
  FailedCmsIdMismatch: {
    // NOT PART OF A FUNNEL
    Action: 'Failed CmsId Mismatch',
    Name: 'LoginFailedCmsIdMismatch',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess',
  },
};

export const RegisterEvent: TMatomoEventType<
  | 'Start'
  | 'InputPin'
  | 'ChooseType'
  | 'VerifyStart'
  | 'VerifyComplete'
  | 'CreateEmail'
  | 'VerifyEmail'
  | 'CreatePassword'
  | 'Success'
> = {
  Category: 'Register',
  Start: {
    Action: 'Start',
    Name: 'RegStart',
  },
  InputPin: {
    Action: 'Input PIN',
    Name: 'RegInputPin',
  },
  ChooseType: {
    Action: 'Choose Type',
    Name: 'RegChooseType',
  },
  VerifyStart: {
    Action: 'Verify Start',
    Name: 'RegVerifyStart',
  },
  VerifyComplete: {
    Action: 'Verify Complete',
    Name: 'RegVerifyComplete',
  },
  CreateEmail: {
    Action: 'Create Email',
    Name: 'RegCreateEmail',
  },
  VerifyEmail: {
    Action: 'Verify Email',
    Name: 'RegVerifyEmail',
  },
  CreatePassword: {
    Action: 'Create Password',
    Name: 'RegCreatePassword',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess', // NO UNIQUE EVENT YET
  },
};

export const SignicatRegisterEvent: TMatomoEventType<'Start' | 'AcceptTerms' | 'Success'> = {
  Category: 'Signicat Register',
  Start: {
    Action: 'Start',
    Name: 'RegSignicatStart',
  },
  AcceptTerms: {
    Action: 'Accept Terms',
    Name: 'RegAcceptTerms',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess', // NO UNIQUE EVENT YET
  },
};

export const SignicatLoginEvent: TMatomoEventType<'Start' | 'Success'> = {
  Category: 'Signicat Login',
  Start: {
    Action: 'Start',
    Name: 'LoginSignicatStart',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess', // NO UNIQUE EVENT YET
  },
};

export const ChangePasswordEvent: TMatomoEventType<'Start' | 'CreatePassword' | 'Success'> = {
  Category: 'Change Password',
  Start: {
    Action: 'Start',
    Name: 'ChangePassStart',
  },
  CreatePassword: {
    Action: 'Create Password',
    Name: 'ChangePassCreatePassword',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess', // NO UNIQUE EVENT YET
  },
};

export const ChangeEmailEvent: TMatomoEventType<'Start' | 'ChangeEmailAddressEvent' | 'Success'> = {
  Category: 'Change Contact Information',
  Start: {
    Action: 'Start',
    Name: 'ChangeContactStart',
  },
  ChangeEmailAddressEvent: {
    Action: 'Change Email address',
    Name: 'ChangeEmail',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess', // NO UNIQUE EVENT YET
  },
};

export const ChangePhonenumberEvent: TMatomoEventType<'Start' | 'ChangePhonenumber' | 'Success'> = {
  Category: 'Change Contact Information',
  Start: {
    Action: 'Start',
    Name: 'ChangeContactStart',
  },
  ChangePhonenumber: {
    Action: 'Change phonenumber',
    Name: 'ChangePhonenumber',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess', // NO UNIQUE EVENT YET
  },
};

export const ResetPasswordEvent: TMatomoEventType<
  'Start' | 'InputEmail' | 'VerifyEmail' | 'CreatePassword' | 'Success'
> = {
  Category: 'Reset Password',
  Start: {
    Action: 'Start',
    Name: 'ResetPassStart',
  },
  InputEmail: {
    Action: 'Input Email',
    Name: 'ResetPassEmail',
  },
  VerifyEmail: {
    Action: 'Verify Email',
    Name: 'ResetPassVerify',
  },
  CreatePassword: {
    Action: 'Create Password',
    Name: 'ResetPassCreatePassword',
  },
  Success: {
    Action: 'Success',
    Name: 'LoginSuccess', // NO UNIQUE EVENT YET
  },
};

export const SignOutEvent: TMatomoEventType<'Start' | 'Success'> = {
  Category: 'Sign Out',
  Start: {
    Action: 'Start',
    Name: 'LogoutStart',
  },
  Success: {
    Action: 'Success',
    Name: 'LogoutSuccess',
  },
};

// -------------------- PAYMENT EVENTS --------------------

export const PaymentStart = {
  Action: 'Payment Start',
  Name: 'PaymentStart',
};
export const PaymentComplete = {
  Action: 'Payment Complete',
  Name: 'PaymentComplete',
};
export const PaymentFailed = {
  // NOT PART OF A FUNNEL
  Action: 'Payment Failed',
  Name: 'PaymentFailed',
};

export const PayFullDebtEvent: TMatomoEventType<
  'Start' | 'PaymentStart' | 'PaymentComplete' | 'PaymentFailed' | 'Success'
> = {
  Category: 'Pay Full Debt',
  Start: {
    Action: 'Start',
    Name: 'PayFullStart',
  },
  PaymentStart,
  PaymentComplete,
  PaymentFailed,
  Success: {
    Action: 'Success',
    Name: 'PayFullSuccess',
  },
};

export const PayAllFullDebtEvent: TMatomoEventType<
  'Start' | 'PaymentStart' | 'PaymentComplete' | 'PaymentFailed' | 'Success'
> = {
  Category: 'Pay All Debts',
  Start: {
    Action: 'Start',
    Name: 'PayAllFullStart',
  },
  PaymentStart,
  PaymentComplete,
  PaymentFailed,
  Success: {
    Action: 'Success',
    Name: 'PayAllFullSuccess',
  },
};

export const PayInstallmentEvent: TMatomoEventType<
  'Start' | 'PaymentStart' | 'PaymentComplete' | 'PaymentFailed' | 'Success'
> = {
  Category: 'Pay Installment',
  Start: {
    Action: 'Start',
    Name: 'PayInstallmentStart',
  },
  PaymentStart,
  PaymentComplete,
  PaymentFailed,
  Success: {
    Action: 'Success',
    Name: 'PayInstallmentSuccess',
  },
};

export const PayCustomEvent: TMatomoEventType<
  'Start' | 'PaymentStart' | 'PaymentComplete' | 'PaymentFailed' | 'Success'
> = {
  Category: 'Pay Custom Amount',
  Start: {
    Action: 'Start',
    Name: 'PayCustomStart',
  },
  PaymentStart,
  PaymentComplete,
  PaymentFailed,
  Success: {
    Action: 'Success',
    Name: 'PayCustomSuccess',
  },
};

export const PaymentPlanEvent: TMatomoEventType<'Start' | 'Select' | 'Confirm' | 'Failed' | 'Pending' | 'Success'> = {
  Category: 'Make Payment Plan',
  Start: {
    Action: 'Start',
    Name: 'PaymentPlanStart',
  },
  Select: {
    Action: 'Select Offer',
    Name: 'PaymentPlanSelect',
  },
  Confirm: {
    Action: 'Confirm Offer',
    Name: 'PaymentPlanConfirm',
  },
  Failed: {
    // NOT PART OF A FUNNEL
    Action: 'Failed',
    Name: 'PaymentPlanFailed',
  },
  Pending: {
    // NOT PART OF A FUNNEL
    Action: 'Pending',
    Name: 'PaymentPlanPending',
  },
  Success: {
    Action: 'Success',
    Name: 'PaymentPlanASuccess',
  },
};

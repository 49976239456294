import { z } from 'zod';

const paymentIdSchema = z.string().brand<'paymentId'>();

export const initiatePaymentResponseSchema = z.object({
  PaymentId: paymentIdSchema,
  RedirectLink: z.string().optional(),
});

export type TInitiatePaymentResponse = z.infer<typeof initiatePaymentResponseSchema>;

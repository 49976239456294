import { useQuery } from '@tanstack/react-query';

import { TClaimId } from '../../../shared/data-access/debts/debt-data.model';
import { TInstalment } from '../data-access-plan-list/payment-plan.model';
import { fetchUpcomingPayments } from './api';
import { TClaimPayment, TInstallmentPayment, UPCOMING_PAYMENT_TYPE } from './upcoming-payment.model';
import { TUpcomingPayment } from './upcoming-payment.model';

export const useUpcomingPaymentListForClaimQuery = (id: TClaimId) =>
  useQuery({
    queryKey: ['upcoming-payments-list'],
    queryFn: () => fetchUpcomingPayments(),
    select: (data): TClaimPayment[] => {
      if (data?.length) {
        return data
          .filter((payment): payment is TClaimPayment => payment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.Claim)
          .filter((payment) => payment.ClaimsCovered?.includes(id));
      }
      return [];
    },
  });

export const useUpcomingPaymentListForInstalmentsQuery = (instalments?: TInstalment[]) =>
  useQuery({
    queryKey: ['upcoming-payments-list'],
    queryFn: () => fetchUpcomingPayments(),
    select: (data): TInstallmentPayment[] => {
      if (data?.length) {
        return data
          .filter(
            (payment): payment is TInstallmentPayment =>
              payment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.Instalment
          )
          .filter(
            (payment) =>
              instalments
                ?.filter((instalment) => !!instalment)
                .some((instalment) => payment.InstalmentsCovered?.includes(instalment.InstalmentId))
          );
      }
      return [];
    },
    enabled: !!instalments,
  });

export const useUpcomingPaymentByIdQuery = (upcomingPaymentId: string | undefined) =>
  useQuery({
    queryKey: ['upcoming-payments-list'],
    queryFn: () => fetchUpcomingPayments(),
    select: (upcomingPayments): TUpcomingPayment | undefined =>
      upcomingPayments.find((upcomingPayment) => upcomingPayment.UpcomingPaymentId === upcomingPaymentId),
  });

export const useAllUpcomingInstalmentsPaymentListQuery = () =>
  useQuery({
    queryKey: ['upcoming-payments-list'],
    queryFn: () => fetchUpcomingPayments(),
    select: (data): TInstallmentPayment[] => {
      if (data?.length) {
        return data.filter(
          (payment): payment is TInstallmentPayment =>
            payment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.NextInstalments ||
            payment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.Instalment
        );
      }
      return [];
    },
  });

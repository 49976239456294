import { FC, MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import { twMerge } from './tailwind.config';

export interface ITopbarProps {
  children?: ReactNode | ReactNode[];
  onClick?: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}
export const Topbar: FC<ITopbarProps> = ({ children, onClick, className }) => {
  return (
    <div
      onClick={onClick}
      className={twMerge('relative bg-primarywhite shadow-navbar flex flex-row justify-start items-center', className)}
    >
      {children}
    </div>
  );
};

import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Cookies from 'js-cookie';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { CustomerInfoContext } from '../../../providers/customer-info.provider';
import { getInitialCookies, removeAllCookies, TCookieConsent, validateCookies } from '../../utils/cookies';
import { useBreakpoint } from '../../utils/media-queries';
import { CookieFooter } from './cookie-footer.component';
import { CookieManagementModal } from './cookie-management-moda.component';
import { CookieModal } from './cookie-modal.component';

interface ICookieContext {
  cookies: TCookieConsent[];
  cookiesAreValid: boolean;
  cookieConsentIsOpen: boolean;
  cookieManagementIsOpen: boolean;
  setCookieConsentIsOpen: (isOpen: boolean) => void;
  setCookieManagementIsOpen: (isOpen: boolean) => void;
  acceptAllCookies: () => void;
  acceptOnlyNecessaryCookies: () => void;
  setCookiesToClient: () => void;
}

export const CookieContext = createContext<ICookieContext>({
  cookies: [] as TCookieConsent[],
  cookiesAreValid: false,
  cookieConsentIsOpen: false,
  cookieManagementIsOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCookieConsentIsOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCookieManagementIsOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  acceptAllCookies: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  acceptOnlyNecessaryCookies: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCookiesToClient: () => {},
});

type TCookieProviderProps = {
  children: ReactNode;
};

export const CookieManagementProvider = ({ children }: TCookieProviderProps) => {
  const isDesktop = useBreakpoint('desktop');
  const { pushInstruction } = useMatomo();
  const appInsights = useAppInsightsContext();
  const cookieMgr = appInsights.getCookieMgr();
  const { data: customerInfoData } = useContext(CustomerInfoContext);

  const [cookies, setCookies] = useState(getInitialCookies());
  const [cookiesAreValid, setCookiesAreValid] = useState(true);
  const [cookieConsentIsOpen, setCookieConsentIsOpen] = useState(!Cookies.get('consent') || !cookiesAreValid);
  const [cookieManagementIsOpen, setCookieManagementIsOpen] = useState(false);
  const [canTrackUserId, setCanTrackUserId] = useState(false);

  useEffect(() => {
    setCookiesAreValid(validateCookies(cookies));
  }, [cookies]);

  useEffect(() => {
    if (canTrackUserId && customerInfoData?.CustomerNumber) {
      pushInstruction('setUserId', customerInfoData.CustomerNumber);
    }
  }, [canTrackUserId, pushInstruction, customerInfoData]);

  const acceptAllCookies = () => {
    pushInstruction('rememberCookieConsentGiven'); // Sets Matomo consent cookie
    cookieMgr.setEnabled(true); // Enables Application Insights cookies
    setCanTrackUserId(true); // Enables Matomo user ID tracking
    const newCookies = cookies.map((cookie) => ({
      ...cookie,
      accepted: true,
    }));
    setCookies(newCookies);
    Cookies.set('consent', JSON.stringify(newCookies)); // Set portal consent cookie here to avoid race condition
  };

  const acceptOnlyNecessaryCookies = () => {
    removeAllCookies(); // Remove all old cookies that may still be present from previous sessions
    cookieMgr.setEnabled(false); // Disables Application Insights cookies
    pushInstruction('forgetCookieConsentGiven'); // Removes Matomo consent cookie
    const newCookies = cookies.map((cookie) => {
      if (!cookie.strictlyNecessary) {
        return {
          ...cookie,
          accepted: false,
        };
      }
      return cookie;
    });
    setCookies(newCookies);
    Cookies.set('consent', JSON.stringify(newCookies)); // Set portal consent cookie here to avoid race condition
  };

  const setCookiesToClient = () => {
    removeAllCookies(); // Remove all old cookies that may still be present from previous sessions
    cookies.forEach((cookie) => {
      if (cookie.id === 'azureApplicationInsights') {
        if (cookie.accepted) {
          cookieMgr.setEnabled(true);
        } else {
          cookieMgr.setEnabled(false);
        }
      }
      if (cookie.id === 'matomoFirstParty') {
        if (cookie.accepted) {
          pushInstruction('rememberCookieConsentGiven'); // Sets Matomo consent cookie
        } else {
          pushInstruction('forgetCookieConsentGiven'); // Removes Matomo consent cookie
        }
      }
      if (cookie.id === 'matomoUserId') {
        if (cookie.accepted) {
          setCanTrackUserId(true); // Enables Matomo user ID tracking
        } else {
          setCanTrackUserId(false); // Disables Matomo user ID tracking
          pushInstruction('resetUserId');
        }
      }
    });
    Cookies.set('consent', JSON.stringify(cookies));
  };

  return (
    <CookieContext.Provider
      value={{
        cookies,
        cookiesAreValid,
        cookieConsentIsOpen,
        cookieManagementIsOpen,
        setCookieConsentIsOpen,
        setCookieManagementIsOpen,
        acceptAllCookies,
        acceptOnlyNecessaryCookies,
        setCookiesToClient,
      }}
    >
      {children}
      {isDesktop && (
        <CookieFooter
          toggleIsVisible={() => setCookieConsentIsOpen(!cookieConsentIsOpen)}
          openCookieManagementModal={() => setCookieManagementIsOpen(true)}
          acceptAllCookies={acceptAllCookies}
          acceptOnlyNecessaryCookies={acceptOnlyNecessaryCookies}
          isVisible={cookieConsentIsOpen}
        />
      )}

      <div className="absolute">
        {!isDesktop && (
          <CookieModal
            toggleIsVisible={() => setCookieConsentIsOpen(!cookieConsentIsOpen)}
            openCookieManagementModal={() => setCookieManagementIsOpen(true)}
            acceptAllCookies={acceptAllCookies}
            acceptOnlyNecessaryCookies={acceptOnlyNecessaryCookies}
            isVisible={cookieConsentIsOpen}
          />
        )}
      </div>
      <div className="absolute">
        <CookieManagementModal
          setIsVisible={() => setCookieManagementIsOpen(!cookieManagementIsOpen)}
          isVisible={cookieManagementIsOpen}
        />
      </div>
    </CookieContext.Provider>
  );
};

import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDebtsListQuery } from '../../../shared/data-access/debts/hooks';
import { Spinner } from '../../../shared/desig-system/spinner.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { usePlanQuery } from '../data-access-plan-list/hooks';
import { TPaymentAgreementId } from '../data-access-plan-list/payment-plan.model';
import { PlanDetailsCard } from '../ui-card-payment/plan-details-card.component';

export const PlanDetails = () => {
  const { id } = useParams();
  const plan = usePlanQuery(id as TPaymentAgreementId);
  const debts = useDebtsListQuery();
  const isDesktop = useBreakpoint('desktop');

  if (plan.isLoading) return <Spinner />;
  if (plan.isError)
    return (
      <>
        <Typography variant="h1" className="text-center">
          <Trans i18nkey={'Errors.DebtLoadError'} />
        </Typography>
        <Typography variant="h3" className="text-center">
          <Trans i18nkey={'Errors.DebtLoadInfo'} />
        </Typography>
      </>
    );
  if (!plan.data) {
    return (
      <Typography variant="h1" className={`${isDesktop ? 'text-center' : 'text-start'}`}>
        <Trans i18nkey={'Errors.DebtLoadError'} />
      </Typography>
    );
  }

  return <PlanDetailsCard allDebts={debts.data} plan={plan.data} />;
};

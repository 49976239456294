import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import { twMerge } from './tailwind.config';

export interface ICardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
}

export const Card: FC<ICardProps> = ({ children, className, ...otherProps }) => {
  return (
    <div {...otherProps} className={twMerge('bg-primarywhite rounded shadow-card w-full', className)}>
      {children}
    </div>
  );
};

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { t } from 'i18next';
import { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  TClaimId,
  TCurrency,
  TDebtData,
  TDebtsTotalAmountResponse,
} from '../../../shared/data-access/debts/debt-data.model';
import { B2Button } from '../../../shared/desig-system/button.component';
import { DataCard } from '../../../shared/desig-system/data-card.component';
import { NotificationPanel } from '../../../shared/desig-system/notification-panel.component';
import { Spinner } from '../../../shared/desig-system/spinner.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { formatCurrencyValue } from '../../../shared/utils/formatting';
import { PaymentPlanEvent } from '../../../shared/utils/matomo.event';
import { useAiMatomo } from '../../../shared/utils/tracking';
import { usePaymentAgreementProposalsQuery } from '../data-access-payment-agreement-proposal/hooks';
import { useByDebtPlanQuery } from '../data-access-plan-list/hooks';
import { DUE_STATUS } from '../data-access-plan-list/payment-plan.model';
import { ClaimPaymentButtons, InstalmentPaymentButtons } from './payment-buttons.component';

export interface ITotalDebtCardProps {
  debtsTotalAmountData: TDebtsTotalAmountResponse;
}

export interface IDebtCardProps {
  showDetails?: boolean;
  showOffers?: boolean;
  debt: TDebtData;
  useWeakAmountStyle?: boolean;
  hideButtons?: boolean;
  hidePayInstalmentButton?: boolean;
}

type TDebtDetails = Omit<TDebtData, 'CmsId' | 'CustomerId' | 'ClaimId' | 'Currency' | 'ClaimStatus' | 'Notifications'>;

type TDebtDataFields = Record<
  keyof TDebtDetails,
  {
    title: JSX.Element;
    formatFunc: (value: TDebtDetails[keyof TDebtDetails], obj: TDebtData) => string | JSX.Element;
  }
>;

const debtDataFields: TDebtDataFields = {
  ClaimName: {
    title: <Trans i18nKey="DebtCard.Data.ClaimName" />,
    formatFunc: (value) => `${value}`,
  },
  ClaimOwner: {
    title: <Trans i18nKey="DebtCard.Data.ClaimOwner" />,
    formatFunc: (value) => `${value}`,
  },
  OriginalCreditor: {
    title: <Trans i18nKey="DebtCard.Data.OriginalCreditor" />,
    formatFunc: (value) => `${value}`,
  },
  NameOfProduct: {
    title: <Trans i18nKey="DebtCard.Data.NameOfProduct" />,
    formatFunc: (value) => `${value}`,
  },
  OriginalContractNumber: {
    title: <Trans i18nKey="DebtCard.Data.OriginalContractNumber" />,
    formatFunc: (value) => <span data-matomo-mask={true}>{value}</span>,
  },
  ClaimNumber: {
    title: <Trans i18nKey="DebtCard.Data.ClaimNumber" />,
    formatFunc: (value) => <span data-matomo-mask={true}>{value}</span>,
  },
  DateOfClaimAcquisition: {
    title: <Trans i18nKey="DebtCard.Data.DateOfClaimAcquisition" />,
    formatFunc: (value) => `${new Date(value || '').toLocaleDateString()}`,
  },
  PrincipalAmount: {
    title: <Trans i18nKey="DebtCard.Data.PrincipalAmount" />,
    formatFunc: (value, obj) => formatCurrencyValue(value as number, obj.Currency),
  },
  SumOfInterest: {
    title: <Trans i18nKey="DebtCard.Data.SumOfInterest" />,
    formatFunc: (value, obj) => formatCurrencyValue(value as number, obj.Currency),
  },
  SumOfFees: {
    title: <Trans i18nKey="DebtCard.Data.SumOfFees" />,
    formatFunc: (value, obj) => formatCurrencyValue(value as number, obj.Currency),
  },
  SumOfOtherCosts: {
    title: <Trans i18nKey="DebtCard.Data.SumOfOtherCosts" />,
    formatFunc: (value, obj) => formatCurrencyValue(value as number, obj.Currency),
  },
  TotalAmount: {
    title: <Trans i18nKey="DebtCard.Data.TotalAmount" />,
    formatFunc: (value, obj) => formatCurrencyValue(value as number, obj.Currency),
  },
  ClaimLegalStatus: {
    title: <Trans i18nKey="DebtCard.Data.ClaimLegalStatus" />,
    formatFunc: (value) => `${value}`,
  },
  DistraintHandler: {
    title: <Trans i18nKey="DebtCard.Data.DistraintHandler" />,
    formatFunc: (value) => `${value}`,
  },
};

const DebtCardHeader: FC<{
  amount?: number;
  currency: TCurrency;
  title: ReactNode;
  variant: 'primary' | 'secondary';
  useWeakAmountStyle: boolean;
}> = ({ amount, currency, title, variant, useWeakAmountStyle }) => {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col gap-1`}>
      <Typography variant="h6">{title}</Typography>
      <p
        className={`
        ${useWeakAmountStyle ? 'text-textdisabled' : 'text-primarytext'}`}
        style={{
          marginTop: '-0.25rem',
        }}
      >
        <Trans i18nKey="DebtCard.RemainingAmount" />
      </p>
      <div className="flex flex-col">
        <p
          className={`
            ${variant === 'primary' && !useWeakAmountStyle ? 'text-48' : 'text-32'}
            font-sans 
            ${variant === 'primary' && !useWeakAmountStyle ? 'font-semibold' : 'font-normal'}  
            ${useWeakAmountStyle ? 'text-textdisabled' : 'text-primarytext'}`}
        >
          {amount !== undefined && formatCurrencyValue(amount, currency)}
          {amount === undefined && formatCurrencyValue(t('DebtCard.AmountUnknownValue'), currency)}
        </p>
        {amount === undefined && (
          <Typography variant="bodyTiny" className="mb-3">
            {`${t('DebtCard.AmountUnknownExplaination')}`}
          </Typography>
        )}
      </div>
    </div>
  );
};

const PaymentPlanSection = ({ claimId }: { claimId: TClaimId }) => {
  const plan = useByDebtPlanQuery(claimId as TClaimId);
  if (!plan.data) return null;
  const instalmentAmount = plan.data.InstalmentSchedule[0]?.InstalmentAmount;
  const currency = plan.data.InstalmentSchedule[0]?.Currency;
  const nextPayment = [...plan.data.InstalmentSchedule]
    .sort((a, b) => Date.parse(a.DueDate) - Date.parse(b.DueDate))
    .find(
      (instalment) =>
        instalment.DueStatus === DUE_STATUS.Late ||
        (instalment.DueStatus !== DUE_STATUS.Paid && new Date() < new Date(instalment.DueDate))
    );

  return (
    <>
      <hr />
      <div className="flex flex-col p-4 gap-6">
        <div className="flex desktop:flex-row flex-col justify-between gap-4">
          <div className="flex flex-col gap-2 desktop:max-w-[50%]">
            <Typography variant="h6">
              <Trans i18nKey="DebtCard.TitlePlan" />
            </Typography>
            <div className="flex flex-row gap-4 justify-between">
              <Typography variant="body">
                <Trans i18nKey="DebtCard.TitleNextPayment" />
              </Typography>
              <Typography
                variant="body"
                className={
                  nextPayment && new Date().getTime() - new Date(nextPayment.DueDate).getTime() > 0 ? 'text-error' : ''
                }
              >
                {nextPayment && new Date(nextPayment.DueDate).toLocaleDateString()}
              </Typography>
              <Typography variant="h6">{formatCurrencyValue(instalmentAmount, currency)}</Typography>
            </div>
            <Link to={`/payment-plans/${plan.data.PaymentAgreementId}`} className="flex flex-row text-primaryblue">
              <Typography variant="body">
                <Trans i18nKey="DebtCard.LinkPlanInfo" />
              </Typography>
              <i className="bx bx-chevron-right my-auto" />
            </Link>
          </div>
          <InstalmentPaymentButtons
            nextInstalmentOnly={true}
            instalments={plan.data.InstalmentSchedule}
            className="flex flex-col min-w-[260px] h-max gap-1"
          />
        </div>
      </div>
    </>
  );
};

const DebtDetails = ({ debt }: { debt: Omit<TDebtData, 'Notifications'> }) => {
  // eslint-disable-next-line no-unused-vars
  const { CmsId, CustomerId, ClaimId, Currency, ClaimStatus, ...details } = debt;

  return (
    <>
      {Object.keys(details).map((key) => (
        <div className="px-8 " key={key}>
          <hr className="border-t-1 border-t-dividerslight" />
          <Typography variant="body" className="mt-2 mb-1">
            {debtDataFields[key as keyof typeof details]?.title}
          </Typography>
          <Typography variant="h6" className="mb-2 mt-1 first-letter:capitalize">
            {debtDataFields[key as keyof typeof details]?.formatFunc(details[key as keyof typeof details], debt)}
          </Typography>
        </div>
      ))}
    </>
  );
};

export const DebtCard: FC<IDebtCardProps> = ({ showDetails = false, debt, useWeakAmountStyle }) => {
  const navigate = useNavigate();

  return (
    <DataCard
      collapsibleFooter={{
        collapsedLabel: <Trans i18nKey={'DebtCard.SectionDetails'} />,
        onLabelClick: () => {
          navigate(debt.ClaimId);
        },
        isExpanded: showDetails,
        expandedComponent: <DebtDetails debt={debt} />,
        expandToggleable: false,
      }}
    >
      <div className="flex flex-col desktop:flex-row justify-between gap-4 p-8">
        <DebtCardHeader
          amount={debt.TotalAmount}
          currency={debt.Currency}
          title={showDetails ? <Trans i18nKey={'DebtCard.TitleSingle'} /> : debt.ClaimName}
          variant={showDetails ? 'primary' : 'secondary'}
          useWeakAmountStyle={!!useWeakAmountStyle}
        />
        <div className="flex flex-col my-auto min-w-[260px] gap-2">
          <ClaimPaymentButtons claimId={debt.ClaimId} className="flex flex-col gap-2" />
        </div>
      </div>
      {debt.Notifications && debt.Notifications.length > 0 && (
        <div className="m-4">
          {debt.Notifications.map((i) => (
            <NotificationPanel
              key={i.Header}
              extraClassName="cms-notification"
              title={i.Header}
              icon={<i className="bx bx-message-error text-black text-2xl pr-12px pl-12px pt-12px"></i>}
              message={i.Message}
            />
          ))}
        </div>
      )}
      {showDetails && <PaymentPlanSection claimId={debt.ClaimId} />}
    </DataCard>
  );
};

export const TotalDebtCard: FC<ITotalDebtCardProps> = ({
  debtsTotalAmountData: { SomeClaimsExcludedFromTotal, TotalAmount, Currency },
}) => {
  const amount = SomeClaimsExcludedFromTotal ? undefined : TotalAmount;
  return (
    <div className="flex flex-col desktop:flex-row justify-between px-4">
      <div className={`flex flex-col`}>
        <Typography variant="h6-thin">
          <Trans i18nKey={'DebtCard.TitleTotal'} />
        </Typography>
        <div className="flex flex-col">
          <Typography variant="h1">
            {amount !== undefined && formatCurrencyValue(amount, Currency)}
            {amount === undefined && <Trans i18nKey="DebtCard.AmountUnknownValue" />}
          </Typography>
          {amount === undefined && (
            <Typography variant="bodyTiny" className="mb-3">
              {`${t('DebtCard.AmountUnknownExplaination')}`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export const CompactDebtCard: FC<Omit<IDebtCardProps, 'showDetails'>> = ({
  debt,
  showOffers,
  useWeakAmountStyle,
  hideButtons: hideButtons,
  hidePayInstalmentButton: hidePayInstalmentButton,
}) => {
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  const paymentPlan = useByDebtPlanQuery(debt.ClaimId);
  const paymentOffers = usePaymentAgreementProposalsQuery(debt.ClaimId);

  return (
    <DataCard className="desktop:max-w-xs">
      <div className="flex flex-col gap-3 p-4 h-full">
        <DebtCardHeader
          amount={debt.TotalAmount}
          currency={debt.Currency}
          title={debt.ClaimName}
          variant="secondary"
          useWeakAmountStyle={!!useWeakAmountStyle}
        />
        {!!debt.DistraintHandler && (
          <div className="flex flex-col mt-auto">
            <Typography variant="body">
              <Trans i18nKey="DebtCard.Data.DistraintHandler" />
            </Typography>
            <Typography variant="h6" className="first-letter:capitalize">
              {debt.DistraintHandler}
            </Typography>
          </div>
        )}
        <Link to={`/debts/${debt.ClaimId}`} className="flex flex-row text-primaryblue">
          <Typography variant="body">
            <Trans i18nKey="Navigationlink.DebtInformation" />
          </Typography>
          <i className="bx bx-chevron-right my-auto" />
        </Link>
        <div className="flex flex-col gap-4 mt-auto">
          {paymentPlan.isLoading && !hideButtons && <Spinner className="relative" />}
          {paymentPlan.data && !hideButtons && (
            <>
              <ClaimPaymentButtons showNote claimId={debt.ClaimId} className="flex flex-col gap-4" />
              {!hidePayInstalmentButton && (
                <InstalmentPaymentButtons
                  nextInstalmentOnly={true}
                  instalments={paymentPlan.data.InstalmentSchedule}
                  className="flex flex-col gap-4"
                />
              )}
            </>
          )}
          {paymentOffers.isLoading && showOffers && (
            <>
              <div className="flex flex-row gap-1 justify-center">
                <hr className="w-full my-auto" />
                {/* OR here if needed, hard to check if above buttons render */}
                <hr className="w-full my-auto" />
              </div>
              <div className="flex flex-col gap-1 justify-center">
                <Typography variant="bodyTiny" className="">
                  <Trans i18nKey="DebtCard.WaitForPaymentProposals" />
                </Typography>
                <Spinner className="relative" size="small" noMargin />
              </div>
            </>
          )}
          {showOffers && paymentOffers.data && paymentOffers.data.length > 0 && (
            <>
              {
                <div className="flex flex-row gap-1 justify-center">
                  <hr className="w-full my-auto" />
                  {/* OR here if needed, hard to check if above buttons render */}
                  <hr className="w-full my-auto" />
                </div>
              }
              <Link
                to={`/debts/${debt.ClaimId}/payment-agreement-proposals`}
                onClick={() => {
                  trackEvent({
                    category: PaymentPlanEvent.Category,
                    action: PaymentPlanEvent.Start.Action,
                    name: PaymentPlanEvent.Start.Name,
                  });
                }}
              >
                <B2Button variant="primary">
                  <Typography variant="button">
                    <Trans i18nKey={'DebtCard.ButtonChoosePlan'} />
                  </Typography>
                </B2Button>
              </Link>
            </>
          )}
        </div>
      </div>
    </DataCard>
  );
};

import { useMsal } from '@azure/msal-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';

import companyLogo from '../assets/company_logo.png';
import { env } from '../env.config';
import { B2Button } from '../shared/desig-system/button.component';
import { Card } from '../shared/desig-system/card.component';
import { Typography } from '../shared/desig-system/typography.component';
import { Footer } from '../shared/ui/footer.component';
import { LanguageSelector } from '../shared/ui/language-select.component';
import { loginRequest } from '../shared/utils/auth/auth.config';
import { getCookiesBySource, getLanguageFromCookie } from '../shared/utils/cookies';
import { useBreakpoint } from '../shared/utils/media-queries';
import { trackLogoutSuccess, useAiMatomo } from '../shared/utils/tracking';

export const LandingPage = () => {
  const isDesktop = useBreakpoint('desktop');
  const { instance } = useMsal();
  const { trackEvent } = useAiMatomo(useAppInsightsContext());

  useEffect(() => {
    trackLogoutSuccess(trackEvent);
  }, [trackEvent]);

  useEffect(() => {
    const key = 'sessionExpire';
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  }, []);

  return (
    <div className="flex flex-col flex-grow items-start justify-between">
      <img
        alt="Company logo"
        src={companyLogo}
        className={`h-12 desktop:h-16 ml-auto mr-auto desktop:mb-24 mb-6 mt-4 pointer-events-none`}
      />
      <div className="absolute flex top-4 right-6 gap-4">
        <LanguageSelector
          renderOption={(option, isSelected, selectOption) => {
            return (
              <span
                key={option.value}
                className={`text-lg select-none ${
                  isSelected ? 'underline cursor-default' : 'hover:underline cursor-pointer'
                }`}
                onClick={() => {
                  if (!isSelected) {
                    selectOption();
                  }
                }}
              >
                {option.value.toUpperCase()}
              </span>
            );
          }}
        />
      </div>

      <div
        className={`${
          isDesktop && 'items-center self-center'
        } max-w-[400px] desktop:max-w-[min(calc(80vw-240px),1024px)] m-auto`}
      >
        <Typography variant="h1" className="mb-10 text-center">
          <Trans i18nKey="LandingPage.Title" />
        </Typography>
        <div className="flex desktop:flex-row flex-col-reverse gap-6 pb-12">
          <Card className="p-5 flex flex-col gap-4">
            <Typography variant="h4">
              <Trans i18nKey={'LandingPage.Card.Title'} />
            </Typography>
            <Typography variant="body">
              <Trans i18nKey={'LandingPage.Card.Description'} />
            </Typography>
            <Typography variant="body" className="font-bold">
              <Trans i18nKey={'LandingPage.Card.BulletsTitle'} />
            </Typography>
            <ul className="list-disc ml-6">
              <li>
                <Typography variant="body">
                  <Trans i18nKey={'LandingPage.Card.Bullet1'} />
                </Typography>
              </li>
              <li>
                <Typography variant="body">
                  <Trans i18nKey={'LandingPage.Card.Bullet2'} />
                </Typography>
              </li>
              <li>
                <Typography variant="body">
                  <Trans i18nKey={'LandingPage.Card.Bullet3'} />
                </Typography>
              </li>
            </ul>
            <Typography variant="body" className="font-bold">
              <Trans i18nKey={'LandingPage.Card.Subtitle'} />
            </Typography>
          </Card>

          <Card className="p-5 flex flex-col gap-4">
            <B2Button
              variant="primary"
              onClick={() => {
                instance.loginRedirect({
                  ...loginRequest,
                  extraQueryParameters: {
                    ui_locales: getLanguageFromCookie(),
                    matomo_site_id: env.matomo.siteId,
                    consent: window.btoa(
                      encodeURIComponent(
                        JSON.stringify(
                          getCookiesBySource('Matomo').map(({ id, accepted }) => ({
                            id,
                            accepted,
                          }))
                        )
                      )
                    ),
                  },
                });
              }}
            >
              <Trans i18nKey={'LandingPage.Card.AccessButton'} />
            </B2Button>
            <Typography variant="body">
              <Trans i18nKey={'LandingPage.Card.AccessDescription'} />
            </Typography>
          </Card>
        </div>
      </div>

      <Footer className="w-full" />
    </div>
  );
};

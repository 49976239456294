import { Trans, useTranslation } from 'react-i18next';

import { B2Button } from '../../../../shared/desig-system/button.component';
import { Card } from '../../../../shared/desig-system/card.component';
import { Typography } from '../../../../shared/desig-system/typography.component';

export const CallUsButton = () => {
  const { t } = useTranslation();
  return (
    <a href={`tel:${t('ContactInfo.NumberPhone', { ns: 'configuration' })}`}>
      <B2Button variant="secondary">
        <div className="flex justify-center">
          <i className="bx bx-phone mr-2 text-xl mb-[1px]" />
          <Typography variant="button" className="my-auto">
            <Trans i18nKey={'ContactInfo.TitlePhone'} />{' '}
            <Trans ns="configuration" i18nKey={'ContactInfo.NumberPhone'} />
          </Typography>
        </div>
      </B2Button>
    </a>
  );
};

export const PhoneCard = ({ isDesktop }: { isDesktop: boolean }) => {
  return (
    <Card className={`${!isDesktop ? 'max-w-[340px] m-0 p-2 flex flex-col' : 'p-8 flex flex-col gap-4'}`}>
      <Typography variant="h4">
        <Trans i18nKey={'ContactInfo.TitlePhone'} />
      </Typography>
      <div>
        <Typography variant="body">
          <Trans i18nKey={'ContactInfo.TitleTimeLong'} />
        </Typography>
        <Typography variant="body">
          <Trans ns="configuration" i18nKey={'ContactInfo.ServiceTimes'} />
        </Typography>
      </div>
      <CallUsButton />
    </Card>
  );
};

import { useMutation } from '@tanstack/react-query';

import { submitMessage } from './api';
import { TMessage } from './message.model';

export const useSavePostalAddressFormSubmitMutation = () =>
  useMutation({
    mutationKey: ['form-submit'],
    mutationFn: (data: TMessage) => submitMessage(data),
  });

import Cookies from 'js-cookie';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { languagesInConfiguration, supportedLanguages } from '../../i18n';

export interface ILanguageOption {
  value: string;
  label: ReactNode;
}

export interface ILanguageSelectorProps {
  renderOption: (option: ILanguageOption, isSelected: boolean, selectOption: () => void) => ReactNode;
}

export const LanguageSelector: FC<ILanguageSelectorProps> = ({ renderOption }) => {
  const { t, i18n } = useTranslation();

  const languageOptions = useMemo(() => {
    const options: ILanguageOption[] = [];
    supportedLanguages.forEach((language) => {
      if (languagesInConfiguration.includes(language)) {
        options.push({
          value: language,
          label: t(`LanguageNames.${language.toUpperCase()}`),
        });
      }
    });
    return options;
  }, [t]);

  const currentLanguage = languageOptions.find((lang) => i18n.language === lang.value);
  const languageCookie = Cookies.get('lang');

  useEffect(() => {
    // Set language cookie, if not already set
    if (!languageCookie) {
      Cookies.set('lang', currentLanguage?.value || languageOptions[0].value);
    }
  }, [languageCookie, currentLanguage, languageOptions]);

  useEffect(() => {
    // Set language from cookie, if there's a mismatch
    if (languageCookie && languageCookie !== currentLanguage?.value) {
      i18n.changeLanguage(languageCookie).catch((e) => console.error('Could not set language: ', e));
    }
  }, [languageCookie, currentLanguage, i18n]);

  if (!currentLanguage || languageOptions.length < 2) return null;

  return (
    <>
      {languageOptions.map((languageOption) =>
        renderOption(languageOption, currentLanguage.value === languageOption.value, () => {
          i18n.changeLanguage(languageOption.value).catch((e) => console.error('Could not set language: ', e));
          Cookies.set('lang', languageOption.value);
        })
      )}
    </>
  );
};

import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, useMatches } from 'react-router-dom';

import { env } from '../../env.config';
import { loginRequest } from '../utils/auth/auth.config';
import { getCookiesBySource, getLanguageFromCookie } from '../utils/cookies';
import { LoginEvent } from '../utils/matomo.event';
import { trackLoginViaCallback, useAiMatomo } from '../utils/tracking';

export const RequireLoginProvider: FC<PropsWithChildren> = ({ children }) => {
  const matches = useMatches();
  const allowUnauthenticated = matches.every((match) => {
    return (match.handle as { allowUnauthenticated?: boolean })?.allowUnauthenticated === true;
  });

  if (!allowUnauthenticated) {
    return <RequireLogin>{children}</RequireLogin>;
  }
  return <>{children}</>;
};

const RequireLogin: FC<PropsWithChildren> = ({ children }) => {
  const { accounts, instance } = useMsal();
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  const authResult = useMsalAuthentication(InteractionType.Redirect, {
    ...loginRequest,
    extraQueryParameters: {
      ui_locales: getLanguageFromCookie(),
      matomo_site_id: env.matomo.siteId,
      // Cookies (with id and accepted) --> stringify --> encodeURI --> Base64
      consent: window.btoa(
        encodeURIComponent(
          JSON.stringify(
            getCookiesBySource('Matomo').map(({ id, accepted }) => ({
              id,
              accepted,
            }))
          )
        )
      ),
    },
  });

  useEffect(() => {
    if (
      authResult &&
      authResult.result &&
      (authResult.result?.idTokenClaims as { extension_CmsId?: string })?.extension_CmsId !== env.cmsId
    ) {
      trackEvent({
        category: LoginEvent.Category,
        action: LoginEvent.FailedCmsIdMismatch.Action,
        name: LoginEvent.FailedCmsIdMismatch.Name,
      });
      instance
        .logoutRedirect({
          account: accounts[0],
        })
        .then(() => <Navigate replace to="/" />)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error('Login failed: ' + e);
        });
    }
  }, [authResult, instance, accounts, trackEvent]);

  useEffect(() => {
    trackLoginViaCallback(instance, trackEvent);
  }, [instance, trackEvent]);

  const retryOnCancel = async () => {
    try {
      await instance.handleRedirectPromise();
    } catch (e: unknown) {
      // Only print out errors that aren't caused by user cancellation error AADB2C90091
      if (!(e as { message?: string })?.message?.includes('AADB2C90091')) {
        // eslint-disable-next-line no-console
        console.error('instance.handleRedirectPromise error', e);
      }
    }

    // If user cancelled login, it will throw an error with code AADB2C90091
    // We want to retry in order to redirect user back to login screen after cancellation
    if (authResult.error?.errorMessage?.includes('AADB2C90091')) {
      try {
        await authResult.login();
      } catch (e: unknown) {
        if ((e as { message?: string })?.message?.includes('interaction_in_progress')) {
          // Suppress interaction in progress errors, but notify in console when in development mode
          if (!env.production) {
            // eslint-disable-next-line no-console
            console.log("Suppressed 'interaction_in_progress' error");
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }
  };

  retryOnCancel().catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
  });

  if (accounts.length === 0) return null;
  instance.setActiveAccount(accounts[0]);

  return <>{children}</>;
};

import { ReactNode } from 'react';

import { useFeedbackLinkQuery } from '../../shared/data-access/config/hooks';
import ConfigurationContext from './configuration.context';

const ConfigurationProvider = ({ children }: { children: ReactNode }) => {
  const result = useFeedbackLinkQuery();

  return (
    <ConfigurationContext.Provider
      value={{
        feedbackLink: result.data?.DisplayFeedbackLink || '',
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};
export default ConfigurationProvider;

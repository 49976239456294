import { useQuery } from '@tanstack/react-query';

import {
  fetchBankAccountNumber,
  fetchContactFormTopics,
  fetchFeedbackLink,
  fetchFromConfigWithoutAuth,
  fetchLocale,
  fetchPaymentProviders,
} from './api';
import { TConfigWithoutAuthRequest } from './config.model';

export const useFetchFromConfigWithoutAuthQuery = (request: TConfigWithoutAuthRequest) =>
  useQuery({
    queryKey: ['fetch-from-config-without-auth'],
    queryFn: () => fetchFromConfigWithoutAuth(request),
  });

export const useContactFormTopics = () =>
  useQuery({
    queryKey: ['fetch-from-config'],
    queryFn: () => fetchContactFormTopics(),
  });

export const useLocaleQuery = () =>
  useQuery({
    queryKey: ['locale'],
    queryFn: () => fetchLocale(),
  });

export const usePaymentProviderListQuery = () =>
  useQuery({
    queryKey: ['payment-providers-list'],
    queryFn: () => fetchPaymentProviders(),
  });

export const useBankAccountNumberQuery = () =>
  useQuery({
    queryKey: ['bank-account-number'],
    queryFn: () => fetchBankAccountNumber(),
  });

export const useFeedbackLinkQuery = () =>
  useQuery({
    queryKey: ['feedback-link'],
    queryFn: () => fetchFeedbackLink(),
  });

import { t } from 'i18next';
import { createContext, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { TCustomerInfoData } from '../features/account/data-access-customer-info/customer-info.model';
import { useCustomerInfoQuery } from '../features/account/data-access-customer-info/hooks';
import { useLocaleQuery } from '../shared/data-access/config/hooks';
import { initializeCurrencyFormatter } from '../shared/utils/formatting';

export type TCustomerInfo = {
  data: TCustomerInfoData | undefined;
  isLoading: boolean;
  isError: boolean;
};

export const CustomerInfoContext = createContext({
  data: undefined as TCustomerInfoData | undefined,
  isLoading: false,
  isError: false,
});

type TCustomerInfoProviderProps = {
  children: ReactNode;
};

export const CustomerInfoProvider = ({ children }: TCustomerInfoProviderProps) => {
  const { data, isLoading, isError } = useCustomerInfoQuery();
  const locale = useLocaleQuery();

  const location = useLocation();
  useEffect(() => {
    const parts = location.pathname.split('/');
    const companyName = t('Document.CompanyName');

    // if path is /, replace it with word Home
    let visiblePath = parts.length > 1 ? parts[1] : 'home';
    // page routes are separated with dash if there is multiple words
    visiblePath = visiblePath.replace('-', ' ');
    // capitalize first letter
    visiblePath = `${visiblePath.charAt(0).toUpperCase()}${visiblePath.slice(1)}`;
    // combine
    if (visiblePath.length > 0) visiblePath += ' - ';

    const pageTitle = `${visiblePath}${companyName}`;

    document.title = pageTitle;
  }, [location]);

  useEffect(() => {
    initializeCurrencyFormatter(locale.data?.Locale);
  }, [locale]);

  return (
    <CustomerInfoContext.Provider
      value={{
        data,
        isLoading,
        isError,
      }}
    >
      {children}
    </CustomerInfoContext.Provider>
  );
};

export const getPrimaryRecord = <T extends { IsPrimary?: boolean }>(
  recordArray: T[] | undefined | null
): T | undefined => {
  if (!recordArray || !recordArray.length) return;
  const primaryRecord = recordArray.find((x: T) => x.IsPrimary);
  if (primaryRecord) return primaryRecord;
  return recordArray[0];
};

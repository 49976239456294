import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useState } from 'react';

import { twMerge } from '../tailwind.config';

export const TextField = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(({ className, ...props }, ref) => {
  const [length, setLength] = useState(0);
  return (
    <div className={twMerge('relative', className)}>
      <input
        className={`
          rounded
          border
          h-12
          w-full
          px-3
          py-4
          focus:outline-none
          disabled:text-textdisabled
          disabled:bg-contentbglight
          placeholder:text-placeholdertext
          placeholder:disabled:text-buttondisabled
          border-formborders
          ${className || ''}
        `}
        ref={ref}
        {...props}
        onChange={(e) => {
          if (props.onChange) props.onChange(e);
          setLength(e.target.value.toString().length);
        }}
      />
      {props.maxLength && (
        <span
          className={`absolute bottom-[1px] right-2 opacity-50 text-sm ${props.disabled && 'text-textdisabled'}`}
        >{`${length}/${props.maxLength}`}</span>
      )}
    </div>
  );
});

TextField.displayName = 'TextField';

import { Trans } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useDebtsListQuery, useDebtsTotalAmountQuery } from '../../../shared/data-access/debts/hooks';
import { SpinnerWrapper } from '../../../shared/desig-system/spinner.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { DebtCard } from '../ui-card-payment/debt-card.component';

export const DebtList = () => {
  const debts = useDebtsListQuery();
  const total = useDebtsTotalAmountQuery();
  const isDesktop = useBreakpoint('desktop');
  const isLoading = debts.isLoading || total.isLoading;
  if (debts.data && debts.data.length === 1) return <Navigate to={debts.data[0].ClaimId} replace />;

  return (
    <>
      <Typography variant="h1">
        <Trans i18nKey="DebtListView.Title" />
      </Typography>
      <SpinnerWrapper isLoading={isLoading}>
        <div className={`${!isDesktop ? 'w-[340px] flex flex-col gap-5' : 'w-full flex flex-col gap-5'}`}>
          {debts.data && !debts.isError && debts.data.map((debt) => <DebtCard key={debt.ClaimId} debt={debt} />)}
          {debts.isError ? (
            <Typography variant="body" className="text-error">
              <Trans i18nKey={'DebtListView.ErrorLoadingDebts'} />
            </Typography>
          ) : (
            !debts.data?.length && (
              <Typography variant="body">
                <Trans i18nKey={'DebtListView.NoDebts'} />
              </Typography>
            )
          )}
        </div>
      </SpinnerWrapper>
    </>
  );
};

import { useMsal } from '@azure/msal-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback } from 'react';

import { getCookiesBySource, getLanguageFromCookie } from '../cookies';
import { ChangePhonenumberEvent } from '../matomo.event';
import { useAiMatomo } from '../tracking';
import { changePhonenumberRequest } from './auth.config';

export const useChangePhoneNumber = () => {
  const appInsights = useAppInsightsContext();
  const { trackEvent } = useAiMatomo(appInsights);
  const { instance } = useMsal();

  return useCallback(() => {
    trackEvent({
      category: ChangePhonenumberEvent.Category,
      action: ChangePhonenumberEvent.Start.Action,
      name: ChangePhonenumberEvent.Start.Name,
    });
    instance.loginRedirect({
      ...changePhonenumberRequest,
      extraQueryParameters: {
        ui_locales: getLanguageFromCookie(),
        consent: window.btoa(
          encodeURIComponent(
            JSON.stringify(
              getCookiesBySource('Matomo').map(({ id, accepted }) => ({
                id,
                accepted,
              }))
            )
          )
        ),
      },
    });
  }, [instance, trackEvent]);
};

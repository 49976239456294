import { useMsal } from '@azure/msal-react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Trans, useTranslation } from 'react-i18next';
import { createBrowserRouter, Navigate, Outlet, redirect, RouteObject, RouterProvider } from 'react-router-dom';

import { NotFoundPage } from './features/404.component';
import { routes as contactRoutes } from './features/account/feature-contact/route.config';
import { routes as cookieRoutes } from './features/account/feature-cookie-policy/route.config';
import { routes as myDataRoutes } from './features/account/feature-my-data/route.config';
import { routes as privacyPolicyRoutes } from './features/account/feature-privacy-policy/route.config';
import { routes as termsOfUseRoutes } from './features/account/feature-terms-of-use/route.config';
import { routes as thankYouRoutes } from './features/account/feature-thank-you/route.config';
import { DashboardPage } from './features/debt-service/feature-dashboard/dashboard.component';
import { routes as debtInformationRoutes } from './features/debt-service/feature-debt-information/route.config';
import { routes as paymentRoutes } from './features/debt-service/feature-payment/route.config';
import { routes as paymentPlanRoutes } from './features/debt-service/feature-payment-plan/route.config';
import { LandingPage } from './features/landing-page.component';
import { NavLayout } from './layouts/nav-layout.component';
import { CustomerInfoProvider } from './providers/customer-info.provider';
import { FeatureFlagProvider } from './providers/feature-flag.provider';
import { BasicBreadCrumb } from './shared/desig-system/breadcrumbs.component';
import { CookieManagementProvider } from './shared/ui/cookies/cookie-management.provider';
import { LogoutHandler } from './shared/ui/logout-handler.component';
import { withPageViewTracking } from './shared/ui/page-view-tracker.component';
import { RequireLoginProvider } from './shared/ui/require-login.component';
import { TimeoutHandler } from './shared/ui/timeout-handler.component';

const routes: () => RouteObject[] = () => [
  {
    path: '/',
    element: (
      <RequireLoginProvider>
        <CustomerInfoProvider>
          <FeatureFlagProvider>
            <CookieManagementProvider>
              <Outlet />
            </CookieManagementProvider>
          </FeatureFlagProvider>
        </CustomerInfoProvider>
      </RequireLoginProvider>
    ),
    errorElement: <Navigate to={'/not-found'} replace={true} />,
    handle: {
      allowUnauthenticated: true,
    },
    children: [
      {
        path: '/',
        element: withPageViewTracking(<LandingPage />),
        handle: {
          allowUnauthenticated: true,
        },
      },
      {
        path: '/home',
        element: withPageViewTracking(
          <NavLayout>
            <DashboardPage />
          </NavLayout>
        ),
        handle: {
          breadcrumb: (
            <BasicBreadCrumb linkTo={'/home'}>
              <Trans i18nKey={'Breadcrumbs.DashboardPage'} />
            </BasicBreadCrumb>
          ),
        },
      },
      {
        path: '/account_deleted',
        loader: async () => {
          if (window.location.hash.startsWith('#error=')) {
            const urlParams = new URLSearchParams(window.location.hash);
            // Note: MS has tought it a good approach to only include the error code as a part of the description.
            //       Lets try to parse it out from there.
            const errDesc = urlParams.get('error_description');
            const errCode = errDesc?.split(':')[0];

            // Note: Handle user cancellation, which is not an actual error
            if (errCode === 'AADB2C90091') {
              // Note: the state parameter bassed to B2C on redirect contains the view where the user must be redirected on cancel.
              const stateValues = urlParams.get('state');

              // Note: The stateValue contains multiple values separated by the pipe char
              if (stateValues && stateValues.length > 1) {
                const data = JSON.parse(stateValues.split('|')[1]);

                // Note: Remove the window.location.hash by using history api
                history.replaceState(null, '', ' ');

                return redirect(data.cancelToView);
              }
            }
          } else {
            // Note: B2C delete flow successful logout and redirect user to landing page
            return redirect('/logout');
          }
        },
      },
      {
        path: '/not-found',
        element: (
          <NavLayout>
            <NotFoundPage />
          </NavLayout>
        ),
        handle: {
          allowUnauthenticated: true,
          breadcrumb: (
            <BasicBreadCrumb linkTo={'/not-found'}>
              <Trans i18nKey={'Breadcrumbs.NotFoundPage'} />
            </BasicBreadCrumb>
          ),
        },
      },
      {
        path: '/logout',
        element: withPageViewTracking(<LogoutHandler />),
        handle: {
          hideBackButton: true,
        },
      },
      ...debtInformationRoutes,
      ...paymentRoutes,
      ...paymentPlanRoutes,
      ...myDataRoutes,
      ...contactRoutes,
      ...termsOfUseRoutes,
      ...privacyPolicyRoutes,
      ...cookieRoutes,
      ...thankYouRoutes,
    ],
  },
];

const router = () => createBrowserRouter(routes());

export const Router = () => {
  const { i18n } = useTranslation();
  const { enableLinkTracking } = useMatomo();
  const { accounts } = useMsal();
  enableLinkTracking();

  return (
    <>
      <RouterProvider key={i18n.language} router={router()} fallbackElement={<Navigate to="/not-found" />} />
      {!!accounts?.length && <TimeoutHandler />}
    </>
  );
};

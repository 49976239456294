import { z } from 'zod';

const currencySchema = z.union([z.literal('EUR'), z.literal('GBP'), z.literal('USD'), z.literal('PLN')]);
const cmsIdSchema = z.string().brand<'cmsId'>();
const customerIdSchema = z.string().brand<'customerId'>();
const claimIdSchema = z.string().brand<'claimId'>();

const claimNotificationSchema = z.object({
  Header: z.string(),
  Message: z.string(),
});

const debtDataSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  ClaimId: claimIdSchema,
  Currency: currencySchema,
  ClaimNumber: z.string(),
  ClaimName: z.string().optional(),
  ClaimOwner: z.string().optional(),
  ClaimStatus: z.union([z.literal('active'), z.literal('closed')]),
  ClaimLegalStatus: z.union([z.literal('amicable'), z.literal('legal')]).optional(),
  OriginalCreditor: z.string().optional(),
  NameOfProduct: z.string().optional(),
  OriginalContractNumber: z.string().optional(),
  DateOfClaimAcquisition: z.string(),
  // DateOfClaimAcquisition: z.date(), // TODO: [date] check if switching to date type will work out of the box
  TotalAmount: z.number().optional(),
  PrincipalAmount: z.number().optional(),
  SumOfInterest: z.number().optional(),
  SumOfFees: z.number().optional(),
  SumOfOtherCosts: z.number().optional(),
  DistraintHandler: z.string().optional(),
  Notifications: z.array(claimNotificationSchema).optional(),
});

export const debtsDataSchema = z.array(debtDataSchema);

export const debtsTotalAmountResponseSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  Currency: currencySchema,
  TotalAmount: z.number().optional(),
  SomeClaimsExcludedFromTotal: z.boolean().optional(),
});

export type TClaimId = z.infer<typeof claimIdSchema>;
export type TCurrency = z.infer<typeof currencySchema>;
export type TDebtData = z.infer<typeof debtDataSchema>;
export type TDebtsTotalAmountResponse = z.infer<typeof debtsTotalAmountResponseSchema>;

import { t } from 'i18next';
import { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';

import { Typography } from '../typography.component';

export interface IFileProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange' | 'type'> {
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  setText: (text: string) => void;
  resetFunc: () => void;
}

export const File: FC<IFileProps> = ({ onFileChange, text, setText, resetFunc, ...props }) => {
  return (
    <div className="relative h-12">
      <div
        className="
          flex
          gap-4
          absolute
          rounded
          border
          h-12
          w-full
          px-3
          py-1
          focus:outline-none
          disabled:text-textdisabled
          disabled:bg-contentbglight
          placeholder:text-placeholdertext
          placeholder:disabled:text-buttondisabled
          border-formborders"
      >
        <i
          className="
          bx bx-file-blank text-dividersdark text-3xl
          "
        />
        <Typography className="my-auto" variant="body">
          {text.length > 0 ? text : `${t('Input.FileAttach')}`}
        </Typography>
      </div>

      <input
        onChange={onFileChange}
        type="file"
        accept=".pdf,.png,.jpg,.jpeg"
        className="absolute z-2 opacity-0 h-12 w-full cursor-pointer"
        {...props}
      />
      {text !== '' && (
        <i
          className="bx bx-x text-dividersdark text-3xl absolute h-full right-0 pt-[6px] pr-2 z-3 cursor-pointer"
          onClick={() => {
            setText('');
            resetFunc();
          }}
        />
      )}
    </div>
  );
};

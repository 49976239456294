import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { env } from '../../env.config';
import { B2Modal } from '../desig-system/b2modal.component';
import { B2Button } from '../desig-system/button.component';
import { Typography } from '../desig-system/typography.component';
import { useInactivityTimeout } from '../utils/use-inactivity-timeout.hook';

const LOGOUT_TIMEOUT = 300000;
const REMINDER_BEFORE = 60000;

const SessionTimeoutModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}) => {
  const { instance, accounts } = useMsal();
  return (
    <B2Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} className="max-w-[400px]">
      <B2Modal.Title>
        <Trans i18nKey={'Logout.InactivityTitle'} />
      </B2Modal.Title>
      <div className="flex flex-col gap-2">
        <Typography variant="body">
          <Trans i18nKey={'Logout.InactivitySubtitle'} />
        </Typography>
        <Typography variant="h1">
          <div className="flex flex-row">
            <i className="bx bx-time-five text-dividersdark my-auto mr-3" />
            {(REMINDER_BEFORE / 60000).toFixed(1)} min
          </div>
        </Typography>
        <Typography variant="body">
          <Trans i18nKey={'Logout.InactivityBody'} />
        </Typography>
        <div />
        <div className="flex flex-col gap-3">
          <B2Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Typography variant="button">
              <Trans i18nKey={'Logout.InactivityCancel'} />
            </Typography>
          </B2Button>
          <B2Button
            variant="secondary"
            onClick={() =>
              instance.logoutRedirect({
                account: accounts[0],
                postLogoutRedirectUri: '/',
              })
            }
          >
            <Typography variant="button">
              <Trans i18nKey={'Logout.InactivityLogout'} />
            </Typography>
          </B2Button>
        </div>
      </div>
    </B2Modal>
  );
};

export const TimeoutHandler = () => {
  const { instance, accounts } = useMsal();
  // Token refresh interval
  useEffect(() => {
    const tokenRefreshInterval = setInterval(() => {
      instance.acquireTokenSilent({
        scopes: env.auth.scopes,
        account: accounts[0],
      });
    }, 30000);
    return () => {
      clearInterval(tokenRefreshInterval);
    };
  });

  const [showSessionTimeoutModal, setShowSessionTimeoutModal] = useState(false);
  const resetTimeout = useInactivityTimeout(
    () =>
      instance.logoutRedirect({
        account: accounts[0],
        postLogoutRedirectUri: '/',
      }),
    LOGOUT_TIMEOUT,
    'sessionExpire',
    showSessionTimeoutModal,
    {
      showReminderAction: () => setShowSessionTimeoutModal(true),
      hideReminderAction: () => setShowSessionTimeoutModal(false),
      msBeforeTimeout: REMINDER_BEFORE,
    }
  );
  const setShowModalHandler = (value: boolean) => {
    if (!value) resetTimeout();
    setShowSessionTimeoutModal(value);
  };
  return <SessionTimeoutModal showModal={showSessionTimeoutModal} setShowModal={setShowModalHandler} />;
};

import Cookies from 'js-cookie';
import { validate } from 'jsonschema';

import cookieConfig from '../../cookies.config.json';

export type TCookieConsent = {
  id: string;
  titleTranslationId: string;
  descriptionTranslationId: string;
  source: string;
  strictlyNecessary: boolean;
  accepted: boolean;
};

export const consentIsSet = (() => !!Cookies.get('consent'))();

export const validateCookies = (cookies: unknown): cookies is TCookieConsent[] => {
  return validate(cookies, cookieConfig.schema).valid;
};

export const getInitialCookies = (): TCookieConsent[] => {
  const cookiesFromClient = Cookies.get('consent');
  if (cookiesFromClient) {
    const parsedCookies = JSON.parse(cookiesFromClient);

    if (validateCookies(parsedCookies)) {
      return parsedCookies;
    }
  }
  return cookieConfig.cookies;
};

export const getCookiesBySource = (source: string) => {
  const initialCookies = getInitialCookies();
  return initialCookies.filter((cookie) => cookie.source === source);
};

export const getLanguageFromCookie = (fallback: string | undefined = 'en'): string => {
  const language = Cookies.get('lang');
  if (language) return language;
  return fallback;
};

export const removeAllCookies = () => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    const neededAttributes = {
      /**
       * If you passed any custom attributes to a created cookie, they must be
       * included here for successfully removing the cookie
       */
    };
    Cookies.remove(cookieName, neededAttributes);
  });
};

import { useMsal } from '@azure/msal-react';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation, useMatches } from 'react-router-dom';

import { BreadCrumbsDisplayer } from '../shared/desig-system/breadcrumbs.component';
import { LogoutConfirmationModal } from '../shared/desig-system/logout-confirmation-modal.component';
import { FadeInDiv } from '../shared/ui/fade-in.component';
import { Footer } from '../shared/ui/footer.component';
import { useBreakpoint } from '../shared/utils/media-queries';
import { Sidebar } from './sidebar.component';
import { NavLayoutTopBar } from './topbar.component';

export interface IDocumentLayoutProps {
  children: ReactNode | ReactNode[];
  itemsCentered?: boolean;
  topbarIsHidden?: boolean;
  sidebarIsHidden?: boolean;
  menuButtonIsHidden?: boolean;
  footerIsHidden?: boolean;
}

/*
 * Copied from NavLayout: Requires rethinking!
 * */
export const MainLayout: FC<IDocumentLayoutProps> = ({
  children,
  itemsCentered,
  topbarIsHidden,
  sidebarIsHidden,
  menuButtonIsHidden,
  footerIsHidden,
}) => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const matches = useMatches();
  const location = useLocation();
  const routeKey = matches[matches.length - 1].pathname;
  const isDesktopLayout = useBreakpoint('desktop');

  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const openSidebar = useCallback(() => setSidebarOpen(true), [setSidebarOpen]);
  const closeSidebar = useCallback(() => setSidebarOpen(false), [setSidebarOpen]);

  useEffect(() => {
    if (showLogoutConfirmationModal) {
      closeSidebar();
    }
  }, [showLogoutConfirmationModal, closeSidebar]);

  useEffect(() => {
    closeSidebar();
  }, [location, closeSidebar]);

  const closeSidebarListener = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') closeSidebar();
    },
    [closeSidebar]
  );

  useEffect(() => {
    window.addEventListener('keydown', closeSidebarListener);
    return () => window.removeEventListener('keydown', closeSidebarListener);
  }, [closeSidebarListener]);

  useEffect(() => {
    if (isDesktopLayout) {
      closeSidebar();
    }
  }, [closeSidebar, isDesktopLayout]);

  if (isDesktopLayout) {
    footerIsHidden = false;
    // topbarIsHidden = false;
    menuButtonIsHidden = false;
  }

  return (
    <>
      <LogoutConfirmationModal setShowModal={setShowLogoutConfirmationModal} showModal={showLogoutConfirmationModal} />
      <div className="flex flex-col flex-grow">
        {isAuthenticated && !sidebarIsHidden && (
          <Sidebar
            isDesktop={false}
            open={sidebarOpen}
            setShowLogoutConfirmationModal={setShowLogoutConfirmationModal}
            hidden={isDesktopLayout}
          />
        )}

        {!topbarIsHidden && (
          <NavLayoutTopBar
            openSidebar={openSidebar}
            closeSidebar={closeSidebar}
            showLogoutConfirmationModal={() => setShowLogoutConfirmationModal(true)}
            isAuthenticated={isAuthenticated}
            menuButtonIsHidden={menuButtonIsHidden}
          />
        )}
        <div id="content-row" className={`flex flex-row flex-grow`}>
          {isDesktopLayout && isAuthenticated && !sidebarIsHidden && (
            <Sidebar open={true} isDesktop={true} setShowLogoutConfirmationModal={setShowLogoutConfirmationModal} />
          )}
          <div
            onClick={closeSidebar}
            className={`flex flex-col flex-grow
            ${isDesktopLayout ? 'mx-[10%]' : 'mx-16px'}
          `}
          >
            <div className={`${!isDesktopLayout && 'items-center self-center max-w-[400px]'}`}>
              <BreadCrumbsDisplayer className="mt-16px" />

              <FadeInDiv
                key={routeKey}
                className={`relative flex flex-col flex-grow mt-16px mb-32px gap-5 w-full ${
                  isDesktopLayout && !itemsCentered
                    ? 'items-start max-w-[min(calc(80vw-240px),1024px)]'
                    : 'overflow-x-scroll'
                }`}
              >
                {children}
              </FadeInDiv>
            </div>
          </div>
        </div>

        {!footerIsHidden && <Footer className={`${sidebarOpen ? 'pointer-events-none' : ''}`} />}
      </div>
    </>
  );
};

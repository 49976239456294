import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

import { env } from '../../../env.config';
import { getCookiesBySource, getLanguageFromCookie } from '../cookies';
import { removeAccountRequest } from './auth.config';

export const useRemoveAccount = () => {
  const { instance } = useMsal();

  return useCallback(
    ({ redirectUri, state }: { redirectUri?: string; state?: { cancelToView: string } } = {}) => {
      /** MSAL doesn't provide a redirect or a config call
       * for removals so we use the same session and redirect with login calls */
      const request = {
        ...removeAccountRequest,
        extraQueryParameters: {
          ui_locales: getLanguageFromCookie(),
          matomo_site_id: env.matomo.siteId,
          consent: window.btoa(
            encodeURIComponent(
              JSON.stringify(
                getCookiesBySource('Matomo').map(({ id, accepted }) => ({
                  id,
                  accepted,
                }))
              )
            )
          ),
        },
      };

      if (redirectUri) {
        request.redirectUri = redirectUri;
      }

      if (state) {
        request.state = JSON.stringify(state);
      }

      instance.loginRedirect(request);
    },
    [instance]
  );
};

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { TClaimId } from '../../../shared/data-access/debts/debt-data.model';
import { fetchPlans } from './api';
import { TPaymentAgreement, TPaymentAgreementId } from './payment-plan.model';

export const usePlansListQuery = (): UseQueryResult<TPaymentAgreement[], unknown> =>
  useQuery({
    queryKey: ['plans-list'],
    queryFn: () => fetchPlans(),
  });

export const usePlanQuery = (id: TPaymentAgreementId): UseQueryResult<TPaymentAgreement | undefined, unknown> =>
  useQuery({
    queryKey: ['plans-list'],
    queryFn: () => fetchPlans(),
    select: (data) => data.find((plan) => plan.PaymentAgreementId === id),
  });

export const useByDebtPlanQuery = (id: TClaimId) =>
  useQuery({
    queryKey: ['plans-by-debt'],
    queryFn: () => fetchPlans(),
    select: (data) => data.find((plan) => plan.ClaimsCovered?.includes(id)),
  });

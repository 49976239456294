import { t } from 'i18next';
import { FC, FormEvent, useState } from 'react';
import { Trans } from 'react-i18next';

import { getPrimaryRecord } from '../../../providers/customer-info.provider';
import { B2Button } from '../../../shared/desig-system/button.component';
import { Card } from '../../../shared/desig-system/card.component';
import { TextField } from '../../../shared/desig-system/input/text-field.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { TCustomerInfoData } from '../data-access-customer-info/customer-info.model';
import { useSavePostalAddressFormSubmitMutation } from '../data-access-message-relay/hooks';
import { TMessage } from '../data-access-message-relay/message.model';

interface IPostalAddressFormProps {
  data: TCustomerInfoData;
}

interface IPostalAddress {
  AddressLine: string;
  AddressLine2?: string;
  PostalCode: string;
  City: string;
}

export const PostalAddressForm: FC<IPostalAddressFormProps> = ({ data: customer }) => {
  const isDesktop = useBreakpoint('desktop');
  const [postalAddress, setAddress] = useState<IPostalAddress>({
    AddressLine: '',
    PostalCode: '',
    City: '',
  });
  const { mutate } = useSavePostalAddressFormSubmitMutation();
  const submitted = localStorage.getItem('form_submitted');
  const formattedAddress = getPrimaryRecord(customer.PostalAddresses)?.FormattedAddress ?? null;

  if (formattedAddress) {
    if (submitted) {
      localStorage.removeItem('form_submitted');
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submittedAddress: TMessage = {
      CmsId: customer.CmsId,
      TemplateId: 'change-postal-address',
      Placeholders: {
        CustomerId: customer.CustomerId,
        StreetAddress: postalAddress.AddressLine2
          ? `${postalAddress.AddressLine}, ${postalAddress.AddressLine2}`
          : postalAddress.AddressLine,
        City: postalAddress.City,
        PostalCode: postalAddress.PostalCode,
      },
      Receivers: ['customer-support'],
      Attachments: [],
    };

    mutate(submittedAddress);
    localStorage.setItem('form_submitted', t('MyDataPage.PostalAddressSubmitted'));
  };

  return (
    <Card className={isDesktop ? 'max-w min-w-[500px] flex flex-col p-8' : 'max-w-[340px] flex flex-col p-2'}>
      {submitted ? (
        <div>
          <Typography variant="body" className="font-bold">
            <Trans i18nKey="MyDataPage.PostalAddressSubmitted" />
          </Typography>
        </div>
      ) : formattedAddress ? (
        // TODO: This is same as PersonalDataField in parent view. Move it to some component and use it here.
        <div>
          <Typography variant="body" className="font-bold">
            <Trans i18nKey="MyDataPage.PostalAddress" />
          </Typography>
          <Typography variant="body">
            <span data-matomo-mask={true}>{formattedAddress}</span>
          </Typography>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className={isDesktop ? 'max-w min-w-[500px] flex flex-col gap-2' : 'max-w-[340px] flex flex-col gap-2'}
        >
          <Typography variant="body" className="font-bold">
            <Trans i18nKey="MyDataPage.AddressLine" />
          </Typography>
          <TextField
            type="text"
            name="AddressLine"
            onChange={(event) =>
              setAddress((prevAddress) => ({
                ...prevAddress,
                AddressLine: event.target.value,
              }))
            }
            required
          />
          <Typography variant="body" className="font-bold flex flex-row gap-2">
            <Trans i18nKey="MyDataPage.AddressLine2" />
            <small className="font-normal">
              <em>
                <Trans i18nKey="MyDataPage.Optional" />
              </em>
            </small>
          </Typography>
          <TextField
            type="text"
            name="AddressLine2"
            onChange={(event) =>
              setAddress((prevAddress) => ({
                ...prevAddress,
                AddressLine2: event.target.value,
              }))
            }
          />
          <Typography variant="body" className="font-bold">
            <Trans i18nKey="MyDataPage.ZipCode" />
          </Typography>
          <TextField
            type="text"
            name="PostalCode"
            onChange={(event) =>
              setAddress((prevAddress) => ({
                ...prevAddress,
                PostalCode: event.target.value,
              }))
            }
            required
          />
          <Typography variant="body" className="font-bold">
            <Trans i18nKey="MyDataPage.City" />
          </Typography>
          <TextField
            type="text"
            name="City"
            onChange={(event) =>
              setAddress((prevAddress) => ({
                ...prevAddress,
                City: event.target.value,
              }))
            }
            required
          />
          <B2Button variant="primary" type="submit">
            <Trans i18nKey="MyDataPage.ButtonSavePostalAddress" />
          </B2Button>
        </form>
      )}
    </Card>
  );
};

export default PostalAddressForm;

import { FC, PropsWithChildren } from 'react';

import { twMerge } from './tailwind.config';

export interface ITypographyProps extends PropsWithChildren {
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h3-bold'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'h6-bold'
    | 'h6-thin'
    | 'body'
    | 'label'
    | 'bodyTiny'
    | 'button';
  className?: string;
}

export const Typography: FC<ITypographyProps> = ({ variant, children, className = '' }) => {
  switch (variant) {
    case 'h1':
      return (
        <h1 className={twMerge('desktop:text-48 text-32 font-light text-primarytext text-inherit', className)}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={twMerge('desktop:text-40 text-26 font-light text-primarytext text-inherit', className)}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={twMerge('desktop:text-32 text-24 font-light text-primarytext text-inherit', className)}>
          {children}
        </h3>
      );
    case 'h3-bold':
      return (
        <h3 className={twMerge('desktop:text-32 text-24 font-bold text-primarytext text-inherit', className)}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          className={twMerge(
            'desktop:text-24 desktop:font-bold text-20 font-semibold text-primarytext text-inherit',
            className
          )}
        >
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5
          className={twMerge(
            'desktop:text-20 desktop:font-normal text-18 font-bold text-primarytext text-inherit',
            className
          )}
        >
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6
          className={twMerge(
            'desktop:text-18 desktop:font-semibold text-16 font-bold text-primarytext text-inherit',
            className
          )}
        >
          {children}
        </h6>
      );
    case 'h6-bold':
      return (
        <h6
          className={twMerge(
            'desktop:text-18 desktop:font-bold text-16 font-bold text-primarytext text-inherit',
            className
          )}
        >
          {children}
        </h6>
      );
    case 'h6-thin':
      return (
        <h6 className={twMerge('desktop:text-18 text-16 text-primarytext text-inherit', className)}>{children}</h6>
      );
    case 'body':
      return <p className={twMerge('text-16 text-primarytext text-inherit', className)}>{children}</p>;
    case 'label':
      return <label className={twMerge('text-14 text-primarytext text-inherit', className)}>{children}</label>;
    case 'bodyTiny':
      return <p className={twMerge('text-12 text-primarytext text-inherit', className)}>{children}</p>;
    case 'button':
      return <p className={twMerge('text-16 font-semibold text-primarytext text-inherit', className)}>{children}</p>;
  }
};

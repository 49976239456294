import { axiosInstance } from '../../utils/axios';
import {
  debtsDataSchema,
  debtsTotalAmountResponseSchema,
  TDebtData,
  TDebtsTotalAmountResponse,
} from './debt-data.model';

export async function fetchDebts(): Promise<TDebtData[]> {
  const res = await axiosInstance.get<TDebtData[]>('customers/self/claims');
  return debtsDataSchema.parse(res.data);
}

export async function fetchDebtsTotalAmount(): Promise<TDebtsTotalAmountResponse> {
  const res = await axiosInstance.get<TDebtsTotalAmountResponse>('customers/self/claims/active-claims-total');
  return debtsTotalAmountResponseSchema.parse(res.data);
}

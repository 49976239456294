import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

import { env } from './env.config';

export const supportedLanguages = ['et', 'es', 'lt', 'en']; // Officially supported languages
export const languagesInConfiguration = env.languages;

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: false,
    partialBundledLanguages: true,
    resources: {},
    defaultNS: ['configuration', 'cookies', 'translation'],
    fallbackNS: ['translation'],
    lng: Cookies.get('lang') ?? env.languages[0],
    fallbackLng: 'en',
    returnEmptyString: true,
    backend: {
      crossDomain: true,
      // TODO: Fallback on locally served files?
      loadPath: `${env.apiUrl}translations/${env.cmsId}/{{lng}}/{{ns}}`,
      // TODO: addPath is only needed during development
      addPath: `${env.publicUrl}/locales/add/{{lng}}/{{ns}}`,
    },
    interpolation: {
      escapeValue: false, // React already protects from xss
    },
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
  })
  .then((t) => {
    document.title = t('Document.CompanyName');
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', t('Document.CompanyMetaDataDescription'));
  })
  .catch((e) => console.error('Error initializing i18next: ', e));

export default i18n;

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { B2Button } from '../../../../../shared/desig-system/button.component';
import { Typography } from '../../../../../shared/desig-system/typography.component';
import { formatCurrencyValue } from '../../../../../shared/utils/formatting';
import { PaymentPlanEvent } from '../../../../../shared/utils/matomo.event';
import { useAiMatomo } from '../../../../../shared/utils/tracking';
import { TPaymentAgreementProposal } from '../../../data-access-payment-agreement-proposal/payment-agreement-proposal.model';

const PaperOfferCheckmarkRow = ({ children }: PropsWithChildren) => {
  return (
    <Typography variant="body" className="flex flex-row items-center break-normal">
      <i className="bx bx-check font-normal text-2xl mr-2 break-normal" />
      <span className="flex flex-row flex-grow justify-between break-normal">{children}</span>
    </Typography>
  );
};

interface IOfferPaperProps {
  proposal: TPaymentAgreementProposal;
}

export const PaperOffer: FC<IOfferPaperProps> = ({ proposal }) => {
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  return (
    <div className="border-formborders border relative mt-4 min-w-[360px] max-w-[620px] flex-grow rounded-md break-normal">
      <Typography
        variant="body"
        className="absolute left-4 font-bold bg-offerlabel px-2 rounded-sm -top-3 break-normal"
      >
        {proposal.OfferName}
      </Typography>
      <div className="flex flex-col p-4 mt-2 gap-2 h-full justify-between">
        <div>
          <Typography variant="h3" className="font-semibold">
            <span className="font-bold">
              {formatCurrencyValue(proposal.OfferOptions[0].InstalmentAmount, proposal.Currency)}
            </span>{' '}
            <span className="font-normal text-[50%] lowercase">
              {' / '}
              <Trans i18nKey={'DatesAndTimes.Month'} />
            </span>
          </Typography>
          <div className="border border-contentbglight" />
          <PaperOfferCheckmarkRow>
            <Trans i18nKey={'PaymentAgreementProposals.NumberOfInstalments'} />
            <span className="font-bold">{proposal.OfferOptions[0].NumberOfInstalments}</span>
          </PaperOfferCheckmarkRow>
          <PaperOfferCheckmarkRow>
            <Trans i18nKey={'PaymentAgreementProposals.TotalAmountPaid'} />
            <span className="font-bold">
              {formatCurrencyValue(proposal.OfferOptions[0].PlanTotalAmount, proposal.Currency)}
            </span>
          </PaperOfferCheckmarkRow>
          {proposal.OfferOptions[0]?.Discount !== undefined && (
            <PaperOfferCheckmarkRow>
              <Trans i18nKey={'PaymentAgreementProposals.Discount'} />
              <span className="font-bold">
                {formatCurrencyValue(proposal.OfferOptions[0].Discount, proposal.Currency)}
              </span>
            </PaperOfferCheckmarkRow>
          )}
          {proposal.OfferOptions[0]?.BenefitsText && (
            <PaperOfferCheckmarkRow>{proposal.OfferOptions[0].BenefitsText}</PaperOfferCheckmarkRow>
          )}
        </div>

        <div>
          <Link
            className="justify-self-end"
            to={`/debts/${proposal.ClaimsCovered[0]}/payment-agreement-proposals/${proposal.PaymentAgreementProposalId}`}
            onClick={() => {
              trackEvent({
                category: PaymentPlanEvent.Category,
                action: PaymentPlanEvent.Select.Action,
                name: PaymentPlanEvent.Select.Name,
                documentTitle: proposal.PaymentAgreementProposalId,
              });
            }}
          >
            <B2Button variant="primary">
              <Trans i18nKey={'PaymentAgreementProposals.ChooseProposalButton'} />
            </B2Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

import { Trans, useTranslation } from 'react-i18next';
import { Outlet, RouteObject, useParams } from 'react-router-dom';

import { NavLayout } from '../../../layouts/nav-layout.component';
import { TClaimId } from '../../../shared/data-access/debts/debt-data.model';
import { useDebtQuery } from '../../../shared/data-access/debts/hooks';
import { BasicBreadCrumb } from '../../../shared/desig-system/breadcrumbs.component';
import { withPageViewTracking } from '../../../shared/ui/page-view-tracker.component';
import { usePaymentAgreementProposalsByIdQuery } from '../data-access-payment-agreement-proposal/hooks';
import { TPaymentAgreementProposalId } from '../data-access-payment-agreement-proposal/payment-agreement-proposal.model';
import { DebtDetails } from './debt-details.component';
import { DebtList } from './debt-list.component';
import { PaymentAgreementProposalDetails } from './payment-agreement-proposal-details/payment-agreement-proposal-details.component';
import { PaymentAgreementProposalList } from './payment-agreement-proposal-list/payment-agreement-proposal-list.component';

export const DebtBreadCrumb = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useDebtQuery(id as TClaimId);
  return (
    <BasicBreadCrumb linkTo={'/debts/:id'}>
      {t('Breadcrumbs.Debt', {
        ClaimName: data?.ClaimName ?? 'Debt',
      })}
    </BasicBreadCrumb>
  );
};

export const PaymentAgreementProposalBreadCrumb = () => {
  const { t } = useTranslation();
  const { proposalId } = useParams();
  const { data } = usePaymentAgreementProposalsByIdQuery(proposalId as TPaymentAgreementProposalId);
  return (
    <BasicBreadCrumb linkTo={'/debts/:id/payment-agreement-proposals/:proposalId'}>
      {t('Breadcrumbs.DebtPaymentAgreementProposal', {
        OfferName: data?.OfferName ?? 'Payment agreement proposal',
      })}
    </BasicBreadCrumb>
  );
};

export const routes: RouteObject[] = [
  {
    path: '/debts',
    element: (
      <NavLayout>
        <Outlet />
      </NavLayout>
    ),
    handle: {
      breadcrumb: (
        <BasicBreadCrumb linkTo={'/debts'}>
          <Trans i18nKey={'Breadcrumbs.DebtInformation'} />
        </BasicBreadCrumb>
      ),
    },
    children: [
      {
        path: '/debts',
        element: withPageViewTracking(<DebtList />),
      },
      {
        path: '/debts/:id',
        element: <Outlet />,
        handle: {
          breadcrumb: <DebtBreadCrumb />,
        },
        children: [
          {
            path: '/debts/:id',
            element: withPageViewTracking(<DebtDetails />),
          },
          {
            path: '/debts/:id/payment-agreement-proposals',
            element: <Outlet />,
            handle: {
              breadcrumb: (
                <BasicBreadCrumb linkTo={'/debts/:id/payment-agreement-proposals'}>
                  <Trans i18nKey={'Breadcrumbs.DebtPaymentAgreementProposals'} />
                </BasicBreadCrumb>
              ),
            },
            children: [
              {
                path: '/debts/:id/payment-agreement-proposals',
                element: withPageViewTracking(<PaymentAgreementProposalList />),
              },
              {
                path: '/debts/:id/payment-agreement-proposals/:proposalId',
                element: withPageViewTracking(<PaymentAgreementProposalDetails />),
                handle: {
                  breadcrumb: <PaymentAgreementProposalBreadCrumb />,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

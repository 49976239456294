import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CustomerInfoContext, TCustomerInfo } from '../../../providers/customer-info.provider';
import { useDebtsListQuery, useDebtsTotalAmountQuery } from '../../../shared/data-access/debts/hooks';
import { SpinnerWrapper } from '../../../shared/desig-system/spinner.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { usePlansListQuery } from '../data-access-plan-list/hooks';
import { CompactDebtCard, TotalDebtCard } from '../ui-card-payment/debt-card.component';
import { NotificationList } from './notification-list.component';
import { PaymentAgreementList } from './payment-agreement-list.component';

const getCustomerInfo = (customerInfo?: TCustomerInfo) => {
  if (!customerInfo) {
    return '';
  }

  if (customerInfo?.data?.GivenName) {
    return customerInfo?.data?.GivenName;
  } else if (customerInfo?.data?.FullName) {
    return customerInfo?.data?.FullName;
  }
};

export const DashboardPage = () => {
  const debts = useDebtsListQuery();
  const total = useDebtsTotalAmountQuery();
  const plans = usePlansListQuery();
  const isDesktop = useBreakpoint('desktop');
  const { t } = useTranslation();
  const customerInfo = useContext(CustomerInfoContext);
  const isLoading = debts.isLoading || total.isLoading || customerInfo.isLoading || plans.isLoading;

  return (
    <>
      <SpinnerWrapper isLoading={isLoading} className={`min-h-[100px] ${!isDesktop && 'max-w-[340px]'}`}>
        <Typography variant="h1" className={`${isDesktop && 'text-start'} ${!isDesktop && 'max-w-[330px]'}`}>
          <span data-matomo-mask={true}>
            {t('DashboardView.Title', {
              userName: getCustomerInfo(customerInfo),
            })}
          </span>
        </Typography>

        <NotificationList customerInfo={customerInfo} paymentAgreements={plans.data || []} />

        {total.data && (
          <>
            <TotalDebtCard debtsTotalAmountData={total.data} />
            <hr className="h-0.5 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-400" />
          </>
        )}

        <PaymentAgreementList paymentAgreements={plans.data || []} debts={debts.data || []} />
        {debts.data && !debts.error && (
          <div className="mt-8">
            <Typography variant="h3" className="py-8">
              <Trans i18nKey={'DashboardView.TitleDebtsOffers'} />
            </Typography>
          </div>
        )}
        {debts.data && debts.data.length > 0 && (
          <div className="flex flex-row flex-wrap items-stretch gap-8">
            {debts.data.map((debt) => (
              <CompactDebtCard key={debt.ClaimId} debt={debt} hidePayInstalmentButton={true} showOffers={true} />
            ))}
          </div>
        )}
        {debts.isError && (
          <Typography variant="body" className="text-error">
            <Trans i18nKey={'DashboardView.ErrorLoadingDebts'} />
          </Typography>
        )}
        {!debts.data?.length && (
          <Typography variant="body">
            <Trans i18nKey={'DashboardView.NoDebts'} />
          </Typography>
        )}
      </SpinnerWrapper>
    </>
  );
};

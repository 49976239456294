import 'react-datepicker/dist/react-datepicker.css';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { enGB, es, et, fi, lt } from 'date-fns/locale';
import { FC, useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { B2Button } from '../../../../../shared/desig-system/button.component';
import { SpinnerWrapper } from '../../../../../shared/desig-system/spinner.component';
import { twMerge } from '../../../../../shared/desig-system/tailwind.config';
import { Typography } from '../../../../../shared/desig-system/typography.component';
import { formatCurrencyValue } from '../../../../../shared/utils/formatting';
import { PaymentPlanEvent } from '../../../../../shared/utils/matomo.event';
import { useAiMatomo } from '../../../../../shared/utils/tracking';
import { usePaymentAgreementRequestMutation } from '../../../data-access-payment-agreement-proposal/hooks';
import {
  TPaymentAgreementProposal,
  TProposal,
} from '../../../data-access-payment-agreement-proposal/payment-agreement-proposal.model';
import { PaymentAgreementProposalPending } from './payment-agreement-proposal-pending.component';
import { PaymentAgreementProposalSuccess } from './payment-agreement-proposal-success.component';

registerLocale('en', enGB);
registerLocale('et', et);
registerLocale('es', es);
registerLocale('fi', fi);
registerLocale('lt', lt);

interface IPaymentAgreementProposalDetailsProps {
  proposal: TPaymentAgreementProposal;
  className?: string;
}

const Divider = () => {
  return <div className="border border-contentbglight" />;
};
export const PaymentAgreementProposalData: FC<IPaymentAgreementProposalDetailsProps> = ({ proposal, className }) => {
  const { t, i18n } = useTranslation();
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  const navigate = useNavigate();

  const {
    mutate: submitProposalRequest,
    isError: requestError,
    isPending: requestLoading,
    isSuccess: requestSuccess,
    data: requestData,
  } = usePaymentAgreementRequestMutation();

  const [chosenProposalOption, setChosenProposalOption] = useState<
    | (TProposal & {
        parsedDate: Date;
      })
    | null
  >(null);
  const proposalOptionsWithDates = proposal.OfferOptions.map((option) => {
    return {
      ...option,
      parsedDate: new Date(option.FirstInstalmentDate),
    };
  }).sort((a, b) => a.parsedDate.getTime() - b.parsedDate.getTime());

  useEffect(() => {
    if (chosenProposalOption == null) {
      setChosenProposalOption(proposalOptionsWithDates[0]);
    }
  }, [proposalOptionsWithDates, chosenProposalOption]);

  if (!chosenProposalOption) return null;

  if (requestSuccess && requestData.state === 'success') {
    return (
      <PaymentAgreementProposalSuccess createdPlanId={requestData.planId} chosenProposalOption={chosenProposalOption} />
    );
  }
  if (requestSuccess && requestData.state === 'pending') {
    return <PaymentAgreementProposalPending />;
  }

  return (
    <div className={twMerge('flex flex-col flex-grow', className)}>
      <Typography variant="h3">
        <span className="font-bold">
          {formatCurrencyValue(chosenProposalOption.InstalmentAmount, proposal.Currency)}
        </span>{' '}
        <span className="font-normal text-[50%] lowercase">
          {' / '}
          <Trans i18nKey={'DatesAndTimes.Month'} />
        </span>
      </Typography>
      <Typography variant="h6" className="font-semibold">
        {`${chosenProposalOption.NumberOfInstalments} ${t('DatesAndTimes.Months')}`}
      </Typography>
      <Typography variant="h4" className="font-bold mt-7">
        <Trans i18nKey={'PaymentAgreementProposals.ChoosePaymentDate'} />
      </Typography>
      <DatePicker
        locale={i18n.language}
        showPopperArrow={false}
        includeDates={proposalOptionsWithDates.map((option) => option.parsedDate)}
        className="border border-formborders rounded-md focus:border-contentbglight p-3 w-full mt-4"
        calendarContainer={({ className, children }) => {
          return (
            <CalendarContainer className={className}>
              <div
                style={{
                  position: 'relative',
                }}
              >
                {children}
              </div>
            </CalendarContainer>
          );
        }}
        onChange={(value) => {
          if (value) {
            setChosenProposalOption(
              proposalOptionsWithDates.find(
                (option) =>
                  option.parsedDate.getDate() === value.getDate() &&
                  option.parsedDate.getMonth() === value.getMonth() &&
                  option.parsedDate.getFullYear() === value.getFullYear()
              ) || null
            );
          }
        }}
        value={chosenProposalOption.parsedDate.toLocaleDateString(i18n.language)}
        selected={chosenProposalOption.parsedDate}
      />
      <Typography variant="h4" className="font-bold mt-7">
        <Trans i18nKey={'PaymentAgreementProposals.PaymentPlanSummary'} />
      </Typography>
      <div className="flex flex-col gap-3 mt-4">
        <Divider />
        <div>
          <Typography variant="body">
            <Trans i18nKey={'PaymentAgreementProposals.InstalmentAmount'} />
          </Typography>
          <Typography variant="body" className="font-bold">
            {formatCurrencyValue(chosenProposalOption.InstalmentAmount, proposal.Currency)}
          </Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="body">
            <Trans i18nKey={'PaymentAgreementProposals.NumberOfInstalments'} />
          </Typography>
          <Typography variant="body" className="font-bold">
            {chosenProposalOption.NumberOfInstalments}
          </Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="body">
            <Trans i18nKey={'PaymentAgreementProposals.PaymentDateOfFirstInstalment'} />
          </Typography>
          <Typography variant="body" className="font-bold">
            {new Date(chosenProposalOption.FirstInstalmentDate).toLocaleDateString(i18n.language)}
          </Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="body">
            <Trans i18nKey={'PaymentAgreementProposals.TotalAmountOfPaymentAgreement'} />
          </Typography>
          <Typography variant="body" className="font-bold">
            {formatCurrencyValue(chosenProposalOption.PlanTotalAmount, proposal.Currency)}
          </Typography>
        </div>
        {chosenProposalOption.BenefitsText && (
          <>
            <Divider />
            <div>
              <Typography variant="body">
                <Trans i18nKey={'PaymentAgreementProposals.OfferBenefits'} />
              </Typography>
              <Typography variant="body" className="font-bold">
                {chosenProposalOption.BenefitsText}
              </Typography>
            </div>
          </>
        )}
      </div>
      <SpinnerWrapper isLoading={requestLoading} className="flex flex-col desktop:flex-row mt-4 gap-2 w-full relative">
        {requestError && (
          <Typography variant="body" className="text-error">
            <Trans i18nKey={'PaymentAgreementProposals.ErrorSubmitting'} />
          </Typography>
        )}
        <B2Button
          variant="primary"
          onClick={() => {
            trackEvent({
              category: PaymentPlanEvent.Category,
              action: PaymentPlanEvent.Confirm.Action,
              name: PaymentPlanEvent.Confirm.Name,
            });
            submitProposalRequest(
              {
                PaymentAgreementProposalId: proposal.PaymentAgreementProposalId,
                FirstPaymentDate: chosenProposalOption.FirstInstalmentDate,
                ConfirmationTime: new Date().toISOString(),
              },
              {
                onSuccess: () => {
                  trackEvent({
                    category: PaymentPlanEvent.Category,
                    action: PaymentPlanEvent.Success.Action,
                    name: PaymentPlanEvent.Success.Name,
                  });
                },
                onError: () => {
                  trackEvent({
                    category: PaymentPlanEvent.Category,
                    action: PaymentPlanEvent.Failed.Action,
                    name: PaymentPlanEvent.Failed.Name,
                  });
                },
              }
            );
          }}
        >
          <Trans i18nKey={'PaymentAgreementProposals.ConfirmButton'} />
        </B2Button>
        <B2Button variant="secondary" onClick={() => navigate(-1)}>
          <Trans i18nKey={'PaymentAgreementProposals.BackButton'} />
        </B2Button>
      </SpinnerWrapper>
    </div>
  );
};

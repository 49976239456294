import { useQuery } from '@tanstack/react-query';

import { fetchDebts, fetchDebtsTotalAmount } from './api';
import { TClaimId } from './debt-data.model';

export const useDebtsTotalAmountQuery = () =>
  useQuery({
    queryKey: ['debts-total-amount'],
    queryFn: () => fetchDebtsTotalAmount(),
  });

export const useDebtsListQuery = () =>
  useQuery({
    queryKey: ['debts-list'],
    queryFn: () => fetchDebts(),
  });

export const useDebtQuery = (id: TClaimId | null) =>
  useQuery({
    queryKey: ['debts-single'],
    queryFn: () => fetchDebts(),
    select: (data) => data.find((debt) => debt.ClaimId === id),
    enabled: id != null,
  });

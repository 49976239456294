import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { i18n, t } from 'i18next';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TDebtData } from '../../../shared/data-access/debts/debt-data.model';
import { useDebtsListQuery } from '../../../shared/data-access/debts/hooks';
import { B2Button } from '../../../shared/desig-system/button.component';
import { Card } from '../../../shared/desig-system/card.component';
import { DataCard } from '../../../shared/desig-system/data-card.component';
import { SpinnerWrapper } from '../../../shared/desig-system/spinner.component';
import { TabView } from '../../../shared/desig-system/tab-view.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { formatCurrencyValue } from '../../../shared/utils/formatting';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { usePaymentListQuery } from '../data-access-payment/hooks';
import { usePlansListQuery } from '../data-access-plan-list/hooks';
import { DUE_STATUS, TInstalment, TInstalmentId, TPaymentAgreement } from '../data-access-plan-list/payment-plan.model';
import { useAllUpcomingInstalmentsPaymentListQuery } from '../data-access-upcoming-payment/hooks';
import { TInstallmentPayment, UPCOMING_PAYMENT_TYPE } from '../data-access-upcoming-payment/upcoming-payment.model';
import { CompactDebtCard } from './debt-card.component';
import { InstalmentPaymentButtons } from './payment-buttons.component';

const renderDebtsInPaymentPlanSection = (debts: (TDebtData | string)[], isDesktop: boolean) => {
  return (
    <>
      <div className="inline-flex items-center py-2 w-full">
        <hr className="w-full h-px my-8 dark:bg-gray-200" />
        <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2">
          <Typography variant="h4">
            <Trans i18nKey={'PaymentsView.DebtsInUpcomingPayment'} />
          </Typography>
        </span>
      </div>
      <div className="pb-4">
        <div className={'grid gap-2 ' + (isDesktop ? 'grid-cols-3' : 'grid-cols-1')}>
          {debts.map((i) => {
            if ((i as TDebtData)?.ClaimId) {
              return (
                <CompactDebtCard
                  key={(i as TDebtData).ClaimId}
                  debt={i as TDebtData}
                  useWeakAmountStyle={true}
                  hideButtons={true}
                ></CompactDebtCard>
              );
            }
            return (
              <DataCard key={i as string} className="desktop:max-w-xs">
                <div className="p-4">
                  <Trans
                    i18nKey={'PlanDetailsView.MissingDebtData'}
                    values={{
                      claimId: i,
                    }}
                  />
                </div>
              </DataCard>
            );
          })}
        </div>
      </div>
    </>
  );
};

const DueSectionTitle = ({ title }: { title: JSX.Element }) => {
  return (
    <div className="flex flex-row gap-1 pl-4 pt-5 pb-2">
      <i className="bx bx-calendar text-dividersdark text-24" />
      <Typography variant="h6" className="my-auto">
        {title}
      </Typography>
    </div>
  );
};

const getInstalmentLabel = (instalment: TInstalment, language: string) => {
  switch (instalment.DueStatus) {
    case 'late':
      return (
        <span className="flex flex-row items-center">
          <i className="bx bx-error-circle mr-1 desktop:mb-[2px]" />
          <span className="font-normal">
            <Trans i18nKey={'PaymentsView.LabelPastDue'} />
          </span>
          <div className="ml-1 min-w-[90px]">{new Date(instalment.DueDate).toLocaleDateString(language)}</div>
        </span>
      );
    case 'upcoming':
      return (
        <span className="flex flex-row items-center">
          <span className="font-normal">
            <Trans i18nKey={'PaymentsView.LabelNextPayment'} />
          </span>
          <div className="ml-1 min-w-[90px]">{new Date(instalment.DueDate).toLocaleDateString(language)}</div>
        </span>
      );
    default:
      return <></>;
  }
};

const RenderUpcomingInstalmentPayment = (
  payment: TInstallmentPayment,
  firstDueInstallment: TInstalment | undefined,
  allInstalments: TInstalment[],
  paymentPlansData: TPaymentAgreement[],
  debtData: TDebtData[],
  i18n: i18n,
  isDesktop: boolean
) => {
  if (!payment.InstalmentsCovered?.length) {
    return (
      <DataCard className="p-4 mt-2">
        <div>
          <Trans
            i18nKey={'PaymentsView.MissingInstalmentError'}
            values={{
              upcomingPaymentId: payment.UpcomingPaymentId,
            }}
          />
        </div>
      </DataCard>
    );
  }

  const paymentInstalments =
    payment.InstalmentsCovered?.map((ic) => allInstalments.find((i) => i.InstalmentId === ic)).sort(
      (a, b) => a?.DueDate.localeCompare(b?.DueDate || '') || 1
    ) || [];
  const paymentPlans = paymentInstalments.map((i) =>
    i ? paymentPlansData?.find((plan) => plan.PaymentAgreementId == i.PaymentAgreementId) : null
  );

  const instalmentsOk = paymentInstalments.find((i) => i == null) ? null : (paymentInstalments as TInstalment[]);

  return (
    <div key={payment.UpcomingPaymentId}>
      <DataCard
        className="p-4 mt-2"
        label={{
          left: firstDueInstallment ? getInstalmentLabel(firstDueInstallment, i18n.language) : undefined,
        }}
      >
        <div className="pt-4 pb-4">
          <Typography variant="h2">
            <Trans i18nKey={'PaymentsView.PaymentAmount'} />
          </Typography>
          <Typography variant="h3-bold">{formatCurrencyValue(payment.MaxAmount, payment.Currency)}</Typography>
          {instalmentsOk && (
            <div className="flex pt-4">
              <InstalmentPaymentButtons nextInstalmentOnly={true} instalments={instalmentsOk} />
            </div>
          )}
          <div className="pt-4">
            <Typography variant="h6-thin">
              <Trans i18nKey={'PaymentsView.PartOfPlans'} />
              <ul>
                {paymentPlans.map((p) =>
                  p ? (
                    <li key={p?.PaymentAgreementId}>
                      <Link className="flex flex-row text-primaryblue" to={`/payment-plans/${p?.PaymentAgreementId}`}>
                        {p?.PaymentAgreementName}
                        <i className="bx bx-chevron-right my-auto" />
                      </Link>
                    </li>
                  ) : (
                    <Trans
                      key={payment.UpcomingPaymentId}
                      i18nKey={'PaymentsView.MissingPaymentPlanError'}
                      values={{
                        upcomingPaymentId: payment.UpcomingPaymentId,
                      }}
                    />
                  )
                )}
              </ul>
            </Typography>
          </div>
        </div>
        {renderDebtsInPaymentPlanSection(
          paymentPlans
            .filter((plan) => !!plan)
            .flatMap(
              (plan) =>
                plan?.ClaimsCovered.map(
                  (claimId) => debtData?.find((i) => i.ClaimId === claimId) || (claimId as string)
                ) || ''
            ),
          isDesktop
        )}
      </DataCard>
    </div>
  );
};

interface IScheduledPayment {
  instalmentForDueDate: TInstalment | undefined;
  payment: TInstallmentPayment;
}

const DueTab = () => {
  const {
    data: paymentPlansData,
    isLoading: paymentPlansQueryIsLoading,
    isError: paymentPlansQueryIsError,
  } = usePlansListQuery();
  const { data: debtData, isLoading: debtsQueryIsLoading, isError: debtsQueryIsError } = useDebtsListQuery();
  const allUpcomingInstalmentPayments = useAllUpcomingInstalmentsPaymentListQuery();
  const upcomingNextInstallments =
    allUpcomingInstalmentPayments?.data?.filter(
      (i) => i.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.NextInstalments
    ) || [];
  const { i18n } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const appInsights = useAppInsightsContext();
  const isDesktop = useBreakpoint('desktop');

  let allInstalments: TInstalment[] = [];
  if (paymentPlansData?.length) {
    allInstalments = paymentPlansData
      .map((plan) => plan.InstalmentSchedule)
      .flat()
      .filter((instalment) => instalment.DueStatus !== 'paid');
  }

  const isError = paymentPlansQueryIsError || debtsQueryIsError;

  // Note: Log data errors to AI
  {
    const paymentIds = allUpcomingInstalmentPayments.data?.map((i) => i.UpcomingPaymentId) || [];
    const instalmentErrors =
      allUpcomingInstalmentPayments?.data?.reduce<string[]>((accum, payment) => {
        const missing = (payment.InstalmentsCovered || []).filter(
          (id) => !allInstalments.find((i) => i.InstalmentId === id)
        );
        return [...accum, ...missing];
      }, [] as TInstalmentId[]) || [];
    const okInstalments = (
      allUpcomingInstalmentPayments.data?.flatMap(
        (payment) => payment.InstalmentsCovered?.flatMap((i) => allInstalments.find((ai) => ai.InstalmentId == i))
      ) || []
    ).filter((i) => !!i) as TInstalment[];
    const planErrors = okInstalments
      .filter((i) => !paymentPlansData?.find((plan) => plan.PaymentAgreementId === i.PaymentAgreementId))
      .map((i) => i.PaymentAgreementId);

    if (instalmentErrors.length > 0 || planErrors.length > 0) {
      const logMessage = `Data error while rendering UpcomingPayments [${paymentIds.join(
        ', '
      )} ], missing installments [${instalmentErrors.join(', ')}], missing plans [${planErrors.join(', ')}]`;
      appInsights.trackException({
        exception: new Error(logMessage),
        severityLevel: SeverityLevel.Warning,
      });
    }
  }

  // Note: Splitting the array allUpcomingInstalmentPayments based on first intallment due date
  //       so that the rendering is simple.
  const temp = allUpcomingInstalmentPayments.data?.map((payment) => {
    const paymentInstalments =
      payment.InstalmentsCovered?.map((ic) => allInstalments.find((i) => i.InstalmentId === ic)).sort(
        (a, b) => a?.DueDate.localeCompare(b?.DueDate || '') || 1
      ) || [];
    const instalmentForDueDate =
      paymentInstalments.find((i) => i?.DueStatus === DUE_STATUS.Late) ||
      paymentInstalments.find((i) => i?.DueStatus === DUE_STATUS.Upcoming) ||
      paymentInstalments.find((i) => !!i);
    return {
      instalmentForDueDate,
      payment,
    } as IScheduledPayment;
  });

  const initial = {
    pastDue: [] as IScheduledPayment[],
    thisMonth: [] as IScheduledPayment[],
    later: [] as IScheduledPayment[],
  };
  const splitByDate = temp?.reduce<typeof initial>((prev, i) => {
    const now = new Date();
    const oneMonthLater = new Date(new Date(now).setMonth(now.getMonth() + 1));
    if (!i.instalmentForDueDate || i.instalmentForDueDate.DueDate < now.toISOString().substring(0, 10)) {
      prev.pastDue.push(i);
    } else if (i.instalmentForDueDate.DueDate < oneMonthLater.toISOString().substring(0, 10)) {
      prev.thisMonth.push(i);
    } else {
      prev.later.push(i);
    }
    return prev;
  }, initial);

  return (
    <SpinnerWrapper
      isLoading={paymentPlansQueryIsLoading || debtsQueryIsLoading}
      className="flex flex-col gap-1 bg-contentbglight min-h-[100px]"
    >
      {isError && (
        <Typography variant="h1" className="text-center text-error">
          <Trans i18nKey={'PaymentsView.ErrorLoadingUpcomingPayments'} />
        </Typography>
      )}
      {!isError && (
        <>
          {allInstalments && allInstalments?.length > 0 && upcomingNextInstallments.length > 0 && (
            <div className="px-3 pt-6">
              {upcomingNextInstallments.map((nextInstalmentPayment) => (
                <Link
                  to={`/payments/pay/${nextInstalmentPayment.UpcomingPaymentId}`}
                  key={nextInstalmentPayment.UpcomingPaymentId}
                >
                  <B2Button variant="primary">
                    <Trans i18nKey={'PaymentsView.ButtonPayAll'} />
                  </B2Button>
                </Link>
              ))}
            </div>
          )}

          {allInstalments && allInstalments.length === 0 && (
            <Typography variant="h3" className="text-center mt-6">
              <Trans i18nKey={'PaymentsView.NoPaymentsToShow'} />
            </Typography>
          )}

          {(splitByDate?.pastDue?.length || 0) > 0 && debtData && paymentPlansData && (
            <>
              <DueSectionTitle title={<Trans i18nKey={'PaymentsView.TitlePastDue'} />} />
              {splitByDate?.pastDue?.map((payment) => {
                return RenderUpcomingInstalmentPayment(
                  payment.payment,
                  payment.instalmentForDueDate,
                  allInstalments,
                  paymentPlansData || [],
                  debtData || [],
                  i18n,
                  isDesktop
                );
              })}
            </>
          )}

          {(splitByDate?.thisMonth?.length || 0) > 0 && debtData && paymentPlansData && (
            <>
              <DueSectionTitle title={<Trans i18nKey={'PaymentsView.TitleThisMonth'} />} />
              {splitByDate?.thisMonth?.map((payment) => {
                return RenderUpcomingInstalmentPayment(
                  payment.payment,
                  payment.instalmentForDueDate,
                  allInstalments,
                  paymentPlansData || [],
                  debtData || [],
                  i18n,
                  isDesktop
                );
              })}
            </>
          )}

          {showMore && (splitByDate?.later?.length || 0) > 0 && debtData && paymentPlansData && (
            <>
              <DueSectionTitle title={<Trans i18nKey={'PaymentsView.LaterThanThisMonth'} />} />
              {splitByDate?.later?.map((payment) => {
                return RenderUpcomingInstalmentPayment(
                  payment.payment,
                  payment.instalmentForDueDate,
                  allInstalments,
                  paymentPlansData || [],
                  debtData || [],
                  i18n,
                  isDesktop
                );
              })}
            </>
          )}

          {!showMore && (splitByDate?.later?.length || 0) > 0 && (
            <Card className="shadow-none p-4">
              <B2Button variant="secondary" onClick={() => setShowMore(true)}>
                <Trans i18nKey={'PaymentsView.ButtonShowMore'} />
              </B2Button>
            </Card>
          )}
        </>
      )}
    </SpinnerWrapper>
  );
};

const PaidTab = () => {
  const payments = usePaymentListQuery();

  return (
    <SpinnerWrapper isLoading={payments.isLoading} className="min-h-[100px]">
      {!payments.data?.length && (
        <Typography variant="h3" className="text-center mt-6">
          <Trans i18nKey={'PaymentsView.NoPaymentsToShow'} />
        </Typography>
      )}
      {payments.data &&
        payments.data.length > 0 &&
        payments.data
          .sort((a, b) => Date.parse(b.Date) - Date.parse(a.Date))
          .map((payment) => (
            <Fragment key={`${payment.ClaimId}-${payment.Date}`}>
              <div className="flex flex-col gap-2 p-4">
                <div className="flex flex-row justify-between">
                  <Typography variant="body">{new Date(payment.Date).toLocaleDateString()}</Typography>
                  <span className="flex flex-row gap-1">
                    <Typography variant="bodyTiny">
                      ·{' '}
                      {payment.PaymentStatus === 'booked' ? (
                        <Trans i18nKey={'PaymentsView.PaymentStatusBooked'} />
                      ) : (
                        <Trans i18nKey={'PaymentsView.PaymentStatusPending'} />
                      )}
                    </Typography>
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <Typography variant="h6">{payment.ClaimName}</Typography>
                  <Typography variant="h6">{formatCurrencyValue(payment.Amount, payment.Currency)}</Typography>
                </div>
              </div>
              <hr />
            </Fragment>
          ))}
    </SpinnerWrapper>
  );
};

export const PaymentTabs = () => {
  const isDesktop = useBreakpoint('desktop');

  return (
    <Card className={`${!isDesktop && 'max-w-[340px]'}`}>
      <TabView headers={[t('PaymentsView.TitleDue'), t('PaymentsView.TitlePaid')]}>
        <DueTab />
        <PaidTab />
      </TabView>
    </Card>
  );
};

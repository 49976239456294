import { useMutation } from '@tanstack/react-query';

import { TDebtData } from '../../../shared/data-access/debts/debt-data.model';
import { submitMessage } from '../data-access-message-relay/api';
import { TMessage } from '../data-access-message-relay/message.model';
import { IFormValues } from '../feature-contact/components/contact-form.component';

function getBase64(file: File): Promise<string> {
  const promise = new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataurl = reader.result as string;
      resolve(dataurl.replace(/^data:[A-z/]+;base64,/, ''));
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

  return promise;
}

export const useFormSubmitMutation = (debts: Array<TDebtData>) =>
  useMutation({
    mutationKey: ['form-submit'],
    mutationFn: async (values: IFormValues) => {
      const data: TMessage = {
        CmsId: values.CmsId,
        Placeholders: {
          topic: values.Topic?.value || '',
          customerId: values.CustomerId || '',
          claimIds: values.SelectedIndexes?.map((i) => debts[i].ClaimNumber).join(','),
          email: values.EmailAddress || '',
          phone: values.PhoneNumber || '',
          subject: values.Topic?.value || '',
          message: values.MessageText,
        },
        Receivers: ['customer-support'],
        TemplateId: 'contact-request',
        Attachments: [],
      };

      if (values.Files) {
        const filePromises: Promise<unknown>[] = [];
        Array.from(values.Files).forEach((file) => {
          filePromises.push(
            getBase64(file).then((r) =>
              data.Attachments.push({
                FileContent: r,
                MimeType: file.type,
              })
            )
          );
        });
        await Promise.all(filePromises);
      }
      return submitMessage(data);
    },
  });

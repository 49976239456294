import { axiosInstance } from '../../../shared/utils/axios';
import { TPaymentAgreementId } from '../data-access-plan-list/payment-plan.model';
import {
  paymentAgreementProposalsSchema,
  TPaymentAgreementProposal,
  TPaymentAgreementRequest,
} from './payment-agreement-proposal.model';

export async function fetchPaymentAgreementProposals(): Promise<TPaymentAgreementProposal[]> {
  const res = await axiosInstance.get<TPaymentAgreementProposal[]>('/customers/self/payment-agreement-proposals');
  return paymentAgreementProposalsSchema.parse(res.data);
}

export type TPaymentAgreementRequestResponse =
  | {
      state: 'success';
      location: string;
      planId: TPaymentAgreementId;
    }
  | { state: 'pending' };
export async function submitPaymentAgreementRequest(
  data: TPaymentAgreementRequest
): Promise<TPaymentAgreementRequestResponse> {
  const res = await axiosInstance.post(
    `customers/self/payment-agreement-proposals/${data.PaymentAgreementProposalId}/payment-agreement-request`,
    data
  );

  if (res.status === 202) {
    return { state: 'pending' };
  }
  return {
    state: 'success',
    location: res.headers.location,
    planId: res.headers.location.split('payment-agreements/')[1] as TPaymentAgreementId,
  };
}

import { Trans } from 'react-i18next';
import { RouteObject } from 'react-router-dom';

import { NavLayout } from '../../../layouts/nav-layout.component';
import { BasicBreadCrumb } from '../../../shared/desig-system/breadcrumbs.component';
import { withPageViewTracking } from '../../../shared/ui/page-view-tracker.component';
import { Contact } from './contact.component';

export const routes: RouteObject[] = [
  {
    path: '/contact',
    element: withPageViewTracking(
      <NavLayout>
        <Contact />
      </NavLayout>
    ),
    handle: {
      breadcrumb: (
        <BasicBreadCrumb linkTo={'/contact'}>
          <Trans i18nKey={'Breadcrumbs.ContactPage'} />
        </BasicBreadCrumb>
      ),
    },
  },
];

import { FC } from 'react';
import { Trans } from 'react-i18next';

import { TDebtData } from '../../../shared/data-access/debts/debt-data.model';
import { Typography } from '../../../shared/desig-system/typography.component';
import { TPaymentAgreement } from '../data-access-plan-list/payment-plan.model';
import { PlanDetailsCard } from '../ui-card-payment/plan-details-card.component';

interface IPaymentAgreementListProps {
  paymentAgreements: TPaymentAgreement[];
  debts: TDebtData[];
}

export const PaymentAgreementList: FC<IPaymentAgreementListProps> = ({ paymentAgreements, debts }) => {
  if (!paymentAgreements.length) {
    return null;
  }

  return (
    <>
      <Typography className="pb-4" variant="h3">
        <Trans i18nKey={'DashboardView.PaymentAgreements'} />
      </Typography>

      <div className="flex flex-row flex-wrap items-stretch gap-8">
        {paymentAgreements.map((p) => (
          <PlanDetailsCard key={p.PaymentAgreementId} allDebts={debts} plan={p} compact={true} />
        ))}
      </div>
    </>
  );
};

import { FC, ReactNode } from 'react';

export interface INotificationPanelProps {
  title: ReactNode;
  message: ReactNode;
  icon: ReactNode;
  extraClassName?: string;
}

export const NotificationPanel: FC<INotificationPanelProps> = ({ title, message, icon, extraClassName }) => {
  return (
    <div className={'mt-2 mb-2 ' + extraClassName}>
      <div className={'bg-primarywhite flex rounded shadow-card h-[100%]'}>
        {icon}
        <div className="bg-primarywhite l-4 flex-1 pl-4 rounded-r p-12px">
          <div className="font-bold">{title}</div>
          <div>{message}</div>
        </div>
      </div>
    </div>
  );
};

import { z } from 'zod';

export enum DUE_STATUS {
  Upcoming = 'upcoming',
  Late = 'late',
  Paid = 'paid',
}

const currencySchema = z.union([z.literal('EUR'), z.literal('GBP'), z.literal('USD'), z.literal('PLN')]);
const cmsIdSchema = z.string().brand<'cmsId'>();
const customerIdSchema = z.string().brand<'customerId'>();
const claimIdSchema = z.string().brand<'claimId'>();
const instalmentIdSchema = z.string().brand<'instalmentId'>();
const paymentAgreementIdSchema = z.string().brand<'paymentAgreementId'>();

const instalmentSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  InstalmentId: instalmentIdSchema,
  PaymentAgreementId: paymentAgreementIdSchema,
  DueDate: z.string(),
  // DueDate: z.date(), // TODO: [date] check if switching to date type will work out of the box
  DueStatus: z.nativeEnum(DUE_STATUS),
  Currency: currencySchema,
  InstalmentAmount: z.number(),
  PaidAmount: z.number().optional(),
  PaymentStatus: z.union([z.literal('unpaid'), z.literal('partlyPaid'), z.literal('paid')]).optional(),
  CompletelyPaidDate: z.string().optional(),
  // CompletelyPaidDate: z.date(), // TODO: [date] check if switching to date type will work out of the box
});

const paymentAgreementSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  PaymentAgreementId: paymentAgreementIdSchema,
  PaymentAgreementNumber: z.string().optional(),
  PaymentAgreementName: z.string(),
  PaymentAgreementCreated: z.string(),
  // PaymentAgreementCreated: z.date(), // TODO: [date] check if switching to date type will work out of the box
  ClaimsCovered: z.array(claimIdSchema),
  AgreementLengthInMonths: z.number(),
  InstalmentSchedule: z.array(instalmentSchema),
  Currency: currencySchema,
  DebtAmountWhenSigned: z.number(),
  PaymentAgreementTotalAmountRemaining: z.number().optional(),
});

export const paymentAgreementsSchema = z.array(paymentAgreementSchema);

export type TClaimId = z.infer<typeof claimIdSchema>;
export type TInstalmentId = z.infer<typeof instalmentIdSchema>;
export type TInstalment = z.infer<typeof instalmentSchema>;
export type TPaymentAgreementId = z.infer<typeof paymentAgreementIdSchema>;
export type TPaymentAgreement = z.infer<typeof paymentAgreementSchema>;

import { Trans } from 'react-i18next';
import { RouteObject } from 'react-router-dom';

import { NavLayout } from '../../../layouts/nav-layout.component';
import { BasicBreadCrumb } from '../../../shared/desig-system/breadcrumbs.component';
import { withPageViewTracking } from '../../../shared/ui/page-view-tracker.component';
import { MyData } from './my-data.component';

export const routes: RouteObject[] = [
  {
    path: '/my-data',
    element: withPageViewTracking(
      <NavLayout>
        <MyData />
      </NavLayout>
    ),
    handle: {
      breadcrumb: (
        <BasicBreadCrumb linkTo={'/my-data'}>
          <Trans i18nKey={'Breadcrumbs.MyDataPage'} />
        </BasicBreadCrumb>
      ),
    },
  },
];

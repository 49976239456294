import { z } from 'zod';

const featureFlagSchema = z.object({
  name: z.string(),
  enabled: z.boolean(),
});

const featureFlagsSchema = z.array(featureFlagSchema);

type TFeatureFlag = z.infer<typeof featureFlagSchema>;

const getFeatureFlagsFromSessionStorage = (): TFeatureFlag[] => {
  let featureFlags = sessionStorage.getItem('features');
  if (featureFlags) {
    featureFlags = JSON.parse(featureFlags);
    const result = featureFlagsSchema.safeParse(featureFlags);

    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(result.error);
      return [];
    }

    return result.data;
  }
  return [];
};

export const getFeatureFlagStatus = (featureFlagName: string): boolean | undefined => {
  const featureFlags = getFeatureFlagsFromSessionStorage();
  const featureFlag = featureFlags.find((flag) => flag.name === featureFlagName);
  if (featureFlag?.enabled) {
    return true;
  }

  if (featureFlag?.enabled === false) {
    return false;
  }

  return undefined;
};

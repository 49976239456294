import { Listbox } from '@headlessui/react';

import { Typography } from '../typography.component';

type TItem = {
  ClaimId: string;
};

interface ICommonSelectProps<T extends TItem> {
  name: string;
  items: Array<T>;
  placeholder: string;
  displayKey: keyof T;
  disabled: boolean;
  useLabelTranslation: boolean;
}

export interface IMultiSelectProps<T extends TItem> extends ICommonSelectProps<T> {
  selected: number[];
  onChange: (value: number[]) => void;
}

export const MultiSelect = <T extends TItem>({
  name,
  items,
  placeholder,
  selected,
  onChange,
  displayKey,
  disabled,
}: IMultiSelectProps<T>) => {
  return (
    <div className="w-full">
      <Listbox name={name} value={selected} onChange={onChange} disabled={disabled} multiple>
        <Listbox.Button
          className={`
            flex 
            h-12
            w-full
            text-left
            rounded
            border
            border-formborders
            pl-3
            justify-between
            ${disabled && 'text-textdisabled bg-contentbglight'}
            ${selected.length === 0 && 'text-placeholdertext'}
            `}
        >
          <Typography variant="body" className="my-auto">
            {selected.length > 0
              ? selected
                  .map((item) => `${items[Number(item)][displayKey]} - ${items[Number(item)]['ClaimId']}`)
                  .join(', ')
              : placeholder}
          </Typography>
          <i className="h-full pt-1 px-1 bx bx-chevron-down text-3xl text-primarytext" />
        </Listbox.Button>
        <div className="relative">
          <Listbox.Options
            className="
              absolute
              z-10
              top-[1px]
              text-left
              rounded
              border
              w-full
              focus:outline-none
              bg-primarywhite
              disabled:text-textdisabled
              disabled:bg-contentbglight
              placeholder:text-placeholdertext
              placeholder:disabled:text-buttondisabled
              border-formborders
            "
          >
            {items.map((item, index) => (
              <Listbox.Option
                key={item.ClaimId}
                value={index}
                className={({ active }) => `cursor-default select-none py-2 px-3 ${active && 'bg-linkbuttonhover'}`}
              >
                {({ selected }) => (
                  <div className="flex flex-row gap-2">
                    <div
                      className={`w-5 h-5 z-2 rounded border border-formborders pt-[1px] my-auto ${
                        selected ? 'bx bx-check bg-primarybutton text-white text-center text-bold' : ' bg-primarywhite'
                      }`}
                    />
                    <span className="mt-[2px]">{`${items[index][displayKey]} - ${items[index]['ClaimId']}`}</span>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
};

import { RouteObject } from 'react-router-dom';

import { NavLayout } from '../../../layouts/nav-layout.component';
import { withPageViewTracking } from '../../../shared/ui/page-view-tracker.component';
import { ThankYou } from './thank-you.component';

export const routes: RouteObject[] = [
  {
    path: '/thank-you',
    element: withPageViewTracking(
      <NavLayout>
        <ThankYou />
      </NavLayout>
    ),
    handle: {
      hideBackButton: true,
    },
  },
];

import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const LogoutHandler = () => {
  const { instance, accounts } = useMsal();
  useEffect(() => {
    return () => {
      instance
        .logoutRedirect({
          account: accounts[0],
          postLogoutRedirectUri: '/?userLogout=true',
        })
        .then(() => <Navigate replace to="/" />)
        .finally(() => {
          const cache = (instance.getTokenCache() as { storage?: { removeAllAccounts: () => void } })?.storage;
          cache?.removeAllAccounts();
        });
    };
  });
  return <></>;
};

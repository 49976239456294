import { FC } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DataCard } from '../../../shared/desig-system/data-card.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { formatCurrencyValue } from '../../../shared/utils/formatting';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { DUE_STATUS, TPaymentAgreement } from '../data-access-plan-list/payment-plan.model';
import { InstalmentPaymentButtons } from './payment-buttons.component';

export interface IPlanListCardProps {
  plan: TPaymentAgreement;
}

export const PlanListCard: FC<IPlanListCardProps> = ({ plan: { InstalmentSchedule, ...plan } }) => {
  const navigate = useNavigate();
  // Some other way to see/present instalment amount?
  const instalmentAmount = InstalmentSchedule[0]?.InstalmentAmount;

  const nextPayment = [...InstalmentSchedule]
    .sort((a, b) => Date.parse(a.DueDate) - Date.parse(b.DueDate))
    .find(
      (instalment) =>
        instalment.DueStatus === DUE_STATUS.Late ||
        (instalment.DueStatus !== DUE_STATUS.Paid && new Date() < new Date(instalment.DueDate))
    );
  const isDesktop = useBreakpoint('desktop');

  return (
    <DataCard
      collapsibleFooter={{
        collapsedLabel: <Trans i18nKey={'PlansView.LabelDetails'} />,
        onLabelClick: () => {
          navigate(plan.PaymentAgreementId);
        },
        isExpanded: false,
        expandedComponent: <div />,
        expandToggleable: false,
      }}
      className={`flex flex-col ${!isDesktop && 'max-w-[330px]'}`}
    >
      <div className="flex flex-col gap-4 p-8">
        <Typography variant="h6">
          <Trans i18nKey={'PlansView.LabelPlanName'} />
          <span data-matomo-mask={true}> {plan.PaymentAgreementName}</span>
        </Typography>
        <div className="flex flex-col desktop:flex-row justify-between gap-2 desktop:gap-4 w-full">
          <div className="flex flex-col gap-2 desktop:w-[50%] max-w-[400px]">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row gap-1">
                <p className="text-32 font-semibold -mb-1">{formatCurrencyValue(instalmentAmount, plan.Currency)}</p>
                <Typography variant="body" className="mt-auto">
                  / <Trans i18nKey={'DatesAndTimes.Month'} />
                </Typography>
              </div>
              <div className="flex flex-row gap-1 mt-auto">
                <Typography variant="h6">{plan.AgreementLengthInMonths}</Typography>
                <Typography variant="body">
                  <Trans i18nKey={'DatesAndTimes.Months'} />
                </Typography>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-1 mb-3">
              <Typography variant="body" className="mt-auto">
                <Trans i18nKey={'PlansView.NextPayment'} />
              </Typography>
              <Typography variant="h4" className={nextPayment?.DueStatus === DUE_STATUS.Late ? 'text-error' : ''}>
                {nextPayment && new Date(nextPayment.DueDate).toLocaleDateString()}
              </Typography>
            </div>
          </div>
          <InstalmentPaymentButtons
            nextInstalmentOnly={true}
            instalments={InstalmentSchedule}
            className="flex flex-col gap-2 min-w-[260px]"
          />
        </div>
      </div>
    </DataCard>
  );
};

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { t } from 'i18next';
import { FC, Fragment, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { TDebtData } from '../../../shared/data-access/debts/debt-data.model';
import { DataCard } from '../../../shared/desig-system/data-card.component';
import { TabView } from '../../../shared/desig-system/tab-view.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { formatCurrencyValue } from '../../../shared/utils/formatting';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { DUE_STATUS, TInstalment, TPaymentAgreement } from '../data-access-plan-list/payment-plan.model';
import { CompactDebtCard } from './debt-card.component';
import { InstalmentPaymentButtons } from './payment-buttons.component';

export interface IPlanDetailsCardProps {
  plan: TPaymentAgreement;
  allDebts?: TDebtData[];
  compact?: boolean;
}

const DetailRow = ({ title, data }: { title: ReactNode; data: ReactNode }) => {
  return (
    <span className="flex flex-row justify-between">
      <Typography variant="h6-thin">{title}</Typography>
      <Typography variant="h6-thin">{data}</Typography>
    </span>
  );
};

const renderDebtsInPaymentPlanSection = (debts: (TDebtData | string)[], isDesktop: boolean) => {
  return (
    <>
      <div className="inline-flex items-center py-2 w-full">
        <hr className="w-full h-px my-8 dark:bg-gray-200" />
        <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2">
          <Typography variant="h4">
            <Trans i18nKey={'PlanDetailsView.DebtsInPaymentPlan'} />
          </Typography>
        </span>
      </div>
      <div className="pb-4">
        <div className={'grid gap-2 ' + (isDesktop ? 'grid-cols-3' : 'grid-cols-1')}>
          {debts.map((i) => {
            if ((i as TDebtData)?.ClaimId) {
              return (
                <CompactDebtCard
                  key={(i as TDebtData).ClaimId}
                  debt={i as TDebtData}
                  hideButtons={true}
                ></CompactDebtCard>
              );
            }
            return (
              <DataCard key={i as string} className="desktop:max-w-xs">
                <div className="p-4">
                  <Trans
                    i18nKey={'PlanDetailsView.MissingDebtData'}
                    values={{
                      claimId: i,
                    }}
                  />
                </div>
              </DataCard>
            );
          })}
        </div>
      </div>
    </>
  );
};

const AmountRow = ({ data }: { data: TPaymentAgreement | TInstalment | undefined }) => {
  const isInstalment = data && 'InstalmentAmount' in data;
  const amount = isInstalment ? data.InstalmentAmount : data?.DebtAmountWhenSigned;
  const title = isInstalment ? (
    <Trans i18nKey="PlanDetailsView.NextInstalment" />
  ) : (
    <Trans i18nKey="PlanDetailsView.OriginalAmount" />
  );

  const currencyValue = data ? formatCurrencyValue(amount || 0, data.Currency || 'EUR') : '- €';

  return (
    <div className="flex flex-col py-2">
      <Typography variant={isInstalment ? 'h6-bold' : 'h6-thin'}>{title}</Typography>
      <p className={`font-semibold desktop:text-48 text-32 ${!isInstalment && 'text-gray-400'}`}>{currencyValue}</p>
    </div>
  );
};

const ExpandTab = ({ instalments, variant }: { instalments: TInstalment[]; variant: 'due' | 'paid' }) => {
  return (
    <>
      {instalments.map((instalment) => (
        <Fragment key={instalment.InstalmentId}>
          <span className="flex flex-row justify-between p-4">
            <Typography
              variant="h6"
              className={
                new Date().getTime() - new Date(instalment.DueDate).getTime() > 0 && variant === 'due'
                  ? 'text-error'
                  : ''
              }
            >
              {new Date(instalment.DueDate).toLocaleDateString()}
            </Typography>
            <Typography variant="h6">
              {variant === 'due' &&
                formatCurrencyValue(instalment.InstalmentAmount - (instalment.PaidAmount || 0), instalment.Currency)}
              {variant === 'paid' &&
                formatCurrencyValue(instalment.PaidAmount || instalment.InstalmentAmount, instalment.Currency)}
            </Typography>
          </span>
          <hr />
        </Fragment>
      ))}
    </>
  );
};

const ExpandSection = ({ instalments }: { instalments: TInstalment[] }) => {
  const dueItems = instalments.filter((instalment) => instalment.DueStatus !== DUE_STATUS.Paid);
  const paidItems = instalments.filter((instalment) => instalment.DueStatus === DUE_STATUS.Paid);
  return (
    <TabView headers={[t('PlanDetailsView.LabelPaymentsDue'), t('PlanDetailsView.LabelPaid')]}>
      <ExpandTab instalments={dueItems} variant="due" />
      <ExpandTab instalments={paidItems} variant="paid" />
    </TabView>
  );
};

export const PlanDetailsCard: FC<IPlanDetailsCardProps> = ({ plan, allDebts, compact }) => {
  const appInsights = useAppInsightsContext();
  // Some other way to see/present instalment amount?
  const InstalmentSchedule = plan.InstalmentSchedule;
  const instalmentAmount = InstalmentSchedule[0].InstalmentAmount;
  const nextPayment = [...InstalmentSchedule]
    .sort((a, b) => Date.parse(a.DueDate) - Date.parse(b.DueDate))
    .find(
      (instalment) =>
        instalment.DueStatus === DUE_STATUS.Late ||
        (instalment.DueStatus !== DUE_STATUS.Paid && new Date() < new Date(instalment.DueDate))
    );
  const coverdDebts = plan.ClaimsCovered.map((c) => allDebts?.find((d) => d.ClaimId === c) || c);
  // Note: We assume that it should be very unlikely that claim data for covered claim would be unavailable.
  //       However it was decided that we log an warning if such situation occurs.
  const missingDebtData = plan.ClaimsCovered.filter((c) => !allDebts?.find((d) => d.ClaimId === c));
  if (missingDebtData.length > 0) {
    console.warn('Debt data missing while rendering payment plan ' + plan.PaymentAgreementId, missingDebtData);
    appInsights.trackException({
      exception: new Error(
        'Debt data for debts ' +
          missingDebtData.join(', ') +
          ' is missing while rendering payment plan ' +
          plan.PaymentAgreementId
      ),
      severityLevel: SeverityLevel.Warning,
    });
  }

  const isDesktop = useBreakpoint('desktop');

  return (
    <DataCard
      collapsibleFooter={
        compact
          ? undefined
          : {
              collapsedLabel: (
                <Typography variant="h6-bold">
                  <Trans i18nKey={'PlanDetailsView.LabelInstalments'} />
                </Typography>
              ),
              onLabelClick: () => {
                return;
              },
              isExpanded: false,
              expandedComponent: <ExpandSection instalments={InstalmentSchedule} />,
              expandToggleable: true,
            }
      }
    >
      <div className="p-8">
        <div className="flex flex-col">
          <Typography variant="h4">
            <Trans i18nKey={'PlanDetailsView.LabelPlanName'} />
            <span data-matomo-mask={true}> {plan.PaymentAgreementName}</span>
          </Typography>
        </div>

        <AmountRow data={plan} />
        <hr className="py-2" />
        <AmountRow data={nextPayment} />
        <div className="flex">
          <InstalmentPaymentButtons
            nextInstalmentOnly={true}
            instalments={InstalmentSchedule}
            className="flex desktop:flex-row flex-col gap-4 py-1 justify-evenly"
            buttonClassName="px-12"
          />
        </div>
        {coverdDebts && renderDebtsInPaymentPlanSection(coverdDebts, isDesktop)}
        {!compact && (
          <div className="flex flex-col gap-3">
            <hr className="pb-4" />
            <DetailRow
              title={<Trans i18nKey={'PlanDetailsView.SpanTotalAmount'} />}
              data={formatCurrencyValue(plan.DebtAmountWhenSigned, plan.Currency)}
            />
            <DetailRow
              title={<Trans i18nKey={'PlanDetailsView.SpanCurrentAmount'} />}
              data={
                plan.PaymentAgreementTotalAmountRemaining !== undefined
                  ? formatCurrencyValue(plan.PaymentAgreementTotalAmountRemaining, plan.Currency)
                  : String(t('Errors.NotAvailable'))
              }
            />
            <DetailRow
              title={<Trans i18nKey={'PlanDetailsView.SpanMonthlyCost'} />}
              data={formatCurrencyValue(instalmentAmount, plan.Currency)}
            />
            <DetailRow
              title={<Trans i18nKey={'PlanDetailsView.SpanNumberOfInstalments'} />}
              data={plan.AgreementLengthInMonths}
            />
            <Typography variant="label" className="py-2">
              <Trans i18nKey={'PlanDetailsView.BottomText'} />
            </Typography>
          </div>
        )}
      </div>
    </DataCard>
  );
};

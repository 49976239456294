import { z } from 'zod';

const currencySchema = z.union([z.literal('EUR'), z.literal('GBP'), z.literal('USD'), z.literal('PLN')]);
const cmsIdSchema = z.string().brand<'cmsId'>();
const customerIdSchema = z.string().brand<'customerId'>();
const claimIdSchema = z.string().brand<'claimId'>();

const paymentSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  ClaimId: claimIdSchema,
  ClaimName: z.string(),
  // Date: z.date(), // TODO: [date] check if switching to date type will work out of the box
  Date: z.string(),
  Currency: currencySchema,
  Amount: z.number(),
  PaymentSource: z.string().optional(),
  PaymentStatus: z.union([z.literal('pending'), z.literal('booked')]),
});

export const paymentsSchema = z.array(paymentSchema);

export type TPayment = z.infer<typeof paymentSchema>;

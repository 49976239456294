import { Trans } from 'react-i18next';

import { B2Button } from '../../desig-system/button.component';
import { Typography } from '../../desig-system/typography.component';

type TCookieFooterProps = {
  toggleIsVisible: () => void;
  openCookieManagementModal: () => void;
  acceptAllCookies: () => void;
  acceptOnlyNecessaryCookies: () => void;
  isVisible: boolean;
};

export const CookieFooter = ({
  toggleIsVisible,
  openCookieManagementModal,
  acceptAllCookies,
  acceptOnlyNecessaryCookies,
  isVisible,
}: TCookieFooterProps) => {
  if (!isVisible) return null;

  return (
    <div className="absolute w-full bottom-0 bg-contentbglight flex flex-col">
      <div className="items-center self-center max-w-[min(calc(80vw-240px),1024px)] mt-4 mb-4">
        <Typography variant="h4">
          <Trans i18nKey={'CookieConsent.Title'} />
        </Typography>
        <Typography variant="body">
          <Trans i18nKey={'CookieConsent.Description'} />
        </Typography>
        <div className="flex flex-row justify-items-start space-x-4 items mt-2 mb-2">
          <B2Button
            variant="primary"
            onClick={() => {
              acceptAllCookies();
              toggleIsVisible();
            }}
          >
            <Typography variant="button">
              <Trans i18nKey={'CookieConsent.AllowAllButton'} />
            </Typography>
          </B2Button>
          <B2Button
            variant="link"
            onClick={() => {
              acceptOnlyNecessaryCookies();
              toggleIsVisible();
            }}
          >
            <Typography variant="button">
              <Trans i18nKey={'CookieConsent.AcceptOnlyNecessaryButton'} />
            </Typography>
          </B2Button>
          <B2Button
            variant="secondary"
            onClick={() => {
              toggleIsVisible();
              openCookieManagementModal();
            }}
          >
            <Typography variant="button">
              <Trans i18nKey={'CookieConsent.ManageCookiesButton'} />
            </Typography>
          </B2Button>
        </div>
      </div>
    </div>
  );
};

import './index.css';
import './i18n';
import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/700.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Trans } from 'react-i18next';

import { env } from './env.config';
import ConfigurationProvider from './providers/configuration/configuration.provider';
import { Router } from './router';
import * as serviceWorkerRegistration from './service-worker-registration';
import { Typography } from './shared/desig-system/typography.component';
import { reactPlugin } from './shared/utils/application-insights';
import { msalConfig } from './shared/utils/auth/auth.config';

if (!env.matomo.baseUrl) {
  console.error('Matomo URL not found.');
} else if (!env.matomo.siteId) {
  console.error('Matomo site ID not found.');
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: (error) => {
        console.error(error);
        return false;
      },
      refetchOnWindowFocus: false,
    },
  },
});
export const msalInstance = new PublicClientApplication(msalConfig);
const matomoInstance = createInstance({
  urlBase: env.matomo.baseUrl,
  siteId: Number(env.matomo.siteId),
  linkTracking: false, // Reason: https://github.com/jonkoops/matomo-tracker/tree/main/packages/react#spa-link-tracking
});

root.render(
  <StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => (
          <Typography variant="h1">
            <Trans i18nKey={'Errors.SomethingWentWrong'} />
          </Typography>
        )}
        appInsights={reactPlugin}
      >
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <ConfigurationProvider>
              <MatomoProvider value={matomoInstance}>
                <Suspense fallback={<h1>Loading...</h1>}>
                  <Router />
                </Suspense>
              </MatomoProvider>
            </ConfigurationProvider>
          </QueryClientProvider>
        </MsalProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

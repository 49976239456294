import { useCallback, useEffect, useState } from 'react';

const INTERVAL = 2500;
const RESET_COOLDOWN = 10000;

export const useInactivityTimeout = (
  onTimeout: () => void,
  timeMs: number,
  trackerName: string,
  captureDisabled?: boolean,
  reminder?: {
    showReminderAction: () => void;
    hideReminderAction: () => void;
    msBeforeTimeout: number;
  }
) => {
  const [reminderShown, setReminderShown] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [lastResetTimestamp, setLastResetTimestamp] = useState(Date.now());

  useEffect(() => {
    // Set initial expire time to local storage
    localStorage.setItem(trackerName, (Date.now() + timeMs).toString());
    setInitialized(true);
  }, [timeMs, trackerName]);

  const resetTimeout = useCallback(() => {
    setLastResetTimestamp(Date.now());
    localStorage.setItem(trackerName, (Date.now() + timeMs).toString());
    setReminderShown(false);
  }, [timeMs, trackerName]);

  useEffect(() => {
    if (!initialized) return;
    // Function to update timeout time
    const handleAction = () => {
      if (captureDisabled) return;
      if (Date.now() - lastResetTimestamp < RESET_COOLDOWN) return;
      resetTimeout();
    };

    // Interval to check time against local storage expire time
    const intervalRef = setInterval(() => {
      const expireTime = Number(localStorage.getItem(trackerName));

      if (!expireTime) {
        return;
      }

      if (Date.now() > expireTime) {
        onTimeout();
      }
      if (reminder && !reminderShown && Date.now() > expireTime - reminder.msBeforeTimeout) {
        reminder.showReminderAction();
        setReminderShown(true);
      }
      if (reminder && reminderShown && Date.now() < expireTime - reminder.msBeforeTimeout) {
        reminder.hideReminderAction();
        setReminderShown(false);
      }
    }, INTERVAL);

    window.addEventListener('mousemove', handleAction, {
      passive: true,
      capture: true,
    });
    window.addEventListener('click', handleAction, {
      passive: true,
      capture: true,
    });
    window.addEventListener('touchstart', handleAction, {
      passive: true,
      capture: true,
    });
    window.addEventListener('scroll', handleAction, {
      passive: true,
      capture: true,
    });
    window.addEventListener('keydown', handleAction, {
      passive: true,
      capture: true,
    });

    return () => {
      clearInterval(intervalRef);
      window.removeEventListener('mousemove', handleAction, { capture: true });
      window.removeEventListener('click', handleAction, { capture: true });
      window.removeEventListener('touchstart', handleAction, { capture: true });
      window.removeEventListener('scroll', handleAction, { capture: true });
      window.removeEventListener('keydown', handleAction, { capture: true });
    };
  }, [
    lastResetTimestamp,
    initialized,
    onTimeout,
    reminder,
    reminderShown,
    timeMs,
    trackerName,
    captureDisabled,
    resetTimeout,
  ]);
  return resetTimeout;
};

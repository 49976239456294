import { env } from '../../../env.config';
import { axiosInstance } from '../../../shared/utils/axios';
import { featureFlagSchema, featureFlagsSchema, TFeatureFlag } from './feature-flag.model';

export async function fetchAllFeatureFlags(): Promise<TFeatureFlag[]> {
  const res = await axiosInstance.get<TFeatureFlag[]>(`configurations/${env.cmsId}/feature-flags`);
  return featureFlagsSchema.parse(res.data);
}

export async function fetchFeatureFlag(featureFlagName: string): Promise<TFeatureFlag> {
  const res = await axiosInstance.get<TFeatureFlag>(`configurations/${env.cmsId}/feature-flags/${featureFlagName}`);
  return featureFlagSchema.parse(res.data);
}

export async function fetchFeatureFlagsByPrefix(prefix: string): Promise<TFeatureFlag[]> {
  const res = await axiosInstance.get<TFeatureFlag[]>(`configurations/${env.cmsId}/feature-flags?prefix=${prefix}`);
  return featureFlagsSchema.parse(res.data);
}

import { Switch } from '@headlessui/react';
import { ReactNode, useState } from 'react';

import { twMerge } from './tailwind.config';

type TB2SwitchProps = {
  title: string | ReactNode;
  onChange: (checked: boolean) => void;
  isChecked?: boolean;
  disabled?: boolean;
};

export const B2Switch = ({ title, onChange, isChecked = false, disabled = false }: TB2SwitchProps) => {
  const [checked, setChecked] = useState(isChecked);

  return (
    <div className="flex">
      <div className="min-w-11">
        <Switch
          checked={checked}
          onChange={(newChecked) => {
            setChecked(newChecked);
            onChange(newChecked);
          }}
          className={twMerge(
            'relative inline-flex h-6 w-11 items-center rounded-full',
            checked ? 'bg-primarybutton' : 'bg-gray-200',
            disabled ? 'opacity-25' : 'opacity-100'
          )}
          disabled={disabled}
        >
          <span className="sr-only">{title}</span>
          <span
            className={twMerge(
              'inline-block h-4 w-4 transform rounded-full bg-white transition',
              checked ? 'translate-x-6' : 'translate-x-1'
            )}
          />
        </Switch>
      </div>
      <div className="mb-2 ml-4">{title}</div>
    </div>
  );
};

import { z } from 'zod';

const cmsIdSchema = z.string().brand<'cmsId'>();
const customerIdSchema = z.string().brand<'customerId'>();
const personIdSchema = z.string().brand<'personId'>();

const phoneNumberSchema = z.object({
  PhoneNumber: z.string(),
  IsVerified: z.boolean().optional(),
  IsPrimary: z.boolean().optional(),
});

const emailAddressSchema = z.object({
  Email: z.string(),
  IsVerified: z.boolean().optional(),
  IsPrimary: z.boolean().optional(),
});

const postalAddressSchema = z.object({
  FormattedAddress: z.string(),
  IsPrimary: z.boolean().optional(),
});

export const customerInfoDataSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  PersonId: personIdSchema,
  CustomerNumber: z.string(),
  FullName: z.string(),
  GivenName: z.string().optional(),
  Surname: z.string().optional(),
  CallingName: z.string().optional(),
  Language: z.string(),
  PhoneNumbers: z.array(phoneNumberSchema),
  EmailAddresses: z.array(emailAddressSchema),
  PostalAddresses: z.array(postalAddressSchema).nullish(),
});

export type TCmsId = z.infer<typeof cmsIdSchema>;
export type TPhoneNumber = z.infer<typeof phoneNumberSchema>;
export type TEmailAddress = z.infer<typeof emailAddressSchema>;
export type TCustomerInfoData = z.infer<typeof customerInfoDataSchema>;

import { Trans } from 'react-i18next';
import { RouteObject } from 'react-router-dom';

import { MainLayout } from '../../../layouts/main-layout.component';
import { BasicBreadCrumb } from '../../../shared/desig-system/breadcrumbs.component';
import { withPageViewTracking } from '../../../shared/ui/page-view-tracker.component';
import { CookiePolicy } from './cookie-policy.component';

export const routes: RouteObject[] = [
  {
    path: '/cookie-policy',
    element: withPageViewTracking(
      <MainLayout>
        <CookiePolicy />
      </MainLayout>
    ),
    handle: {
      allowUnauthenticated: true,
      breadcrumb: (
        <BasicBreadCrumb linkTo={'/cookie-policy'}>
          <Trans i18nKey={'Breadcrumbs.CookiePolicy'} />
        </BasicBreadCrumb>
      ),
    },
  },
];

import axios from 'axios';
import { z } from 'zod';

import { env } from '../../../env.config';
import { axiosInstance } from '../../utils/axios';
import {
  bankAccountNumberSchema,
  configWithoutAuthResponseSchema,
  contactFormTopicsSchema,
  feedbackLinkSchema,
  localeSchema,
  paymentProviderListSchema,
  TConfigWithoutAuthRequest,
  TConfigWithoutAuthResponse,
} from './config.model';

/* We need to get the privacy policy without AUTH so for
   now we sidestep the global axios by creating a new local api
*/
export const configApi = axios.create({
  baseURL: env.apiUrl,
});

export async function fetchFromConfigWithoutAuth(
  request: TConfigWithoutAuthRequest
): Promise<TConfigWithoutAuthResponse> {
  const res = await configApi.get<TConfigWithoutAuthResponse>(
    `translations/${request.CMSId}/${request.LanguageCode}/${request.TranslationNamespace}`
  );

  return configWithoutAuthResponseSchema.parse(res.data);
}

export async function fetchContactFormTopics() {
  const res = await axiosInstance.get<z.infer<typeof contactFormTopicsSchema>>(
    `configurations/${env.cmsId}/configurations/ContactFormTopics`
  );
  return contactFormTopicsSchema.parse(res.data);
}

export async function fetchLocale() {
  const res = await axiosInstance.get<z.infer<typeof localeSchema>>(
    `configurations/${env.cmsId}/configurations/Locale`
  );
  return localeSchema.parse(res.data);
}

export async function fetchPaymentProviders() {
  const res = await axiosInstance.get<z.infer<typeof paymentProviderListSchema>>(
    `configurations/${env.cmsId}/configurations/PaymentProviders`
  );
  return paymentProviderListSchema.parse(res.data);
}

export async function fetchFeedbackLink() {
  const res = await axiosInstance.get<z.infer<typeof feedbackLinkSchema>>(
    `configurations/${env.cmsId}/configurations/DisplayFeedbackLink`
  );
  return feedbackLinkSchema.parse(res.data);
}

export async function fetchBankAccountNumber() {
  const res = await axiosInstance.get<z.infer<typeof bankAccountNumberSchema>>(
    `configurations/${env.cmsId}/configurations/DisplayBankAccountNumber`
  );
  return bankAccountNumberSchema.parse(res.data);
}

import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { B2Button } from '../shared/desig-system/button.component';
import { Typography } from '../shared/desig-system/typography.component';

export const NotFoundPage = () => {
  const { pushInstruction, trackPageView } = useMatomo();

  useEffect(() => {
    pushInstruction('requireCookieConsent');
    pushInstruction(
      'setDocumentTitle',
      '404/URL = ' +
        encodeURIComponent(document.location.pathname + document.location.search) +
        '/From = ' +
        encodeURIComponent(document.referrer)
    );
    trackPageView();
  }, [pushInstruction, trackPageView]);

  return (
    <>
      <Typography variant="h1" className="text-center">
        <Trans i18nKey={'NotFoundPage.Title'} />
      </Typography>
      <Typography variant="h3" className="text-center">
        <Trans i18nKey={'NotFoundPage.Subtitle'} />
      </Typography>
      <Link to="/home">
        <B2Button variant="primary">
          <Typography variant="button" className="my-auto">
            <Trans i18nKey={'NotFoundPage.Button'} />
          </Typography>
        </B2Button>
      </Link>
    </>
  );
};

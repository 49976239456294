import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { B2Button } from '../../../../../shared/desig-system/button.component';
import { Typography } from '../../../../../shared/desig-system/typography.component';
import { PaymentPlanEvent } from '../../../../../shared/utils/matomo.event';
import { useAiMatomo } from '../../../../../shared/utils/tracking';

export const PaymentAgreementProposalPending = () => {
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  useEffect(() => {
    trackEvent({
      category: PaymentPlanEvent.Category,
      action: PaymentPlanEvent.Pending.Action,
      name: PaymentPlanEvent.Pending.Name,
    });
  }, [trackEvent]);

  return (
    <>
      <Typography variant="h4" className="font-bold">
        <Trans i18nKey={'PaymentAgreementProposals.PendingCongratulation'} />
      </Typography>
      <Typography variant="body" className="mt-7">
        <Trans i18nKey={'PaymentAgreementProposals.PendingBodyText'} />{' '}
      </Typography>
      <div className="flex flex-col desktop:flex-row mt-4 gap-2 w-full relative">
        <Link to="/home">
          <B2Button variant="primary">
            <Trans i18nKey={'PaymentAgreementProposals.BackToHomeButton'} />
          </B2Button>
        </Link>
      </div>
    </>
  );
};

import { sanitize } from 'dompurify';
import { Trans, useTranslation } from 'react-i18next';

import { env } from '../../../env.config';
import { TConfigWithoutAuthRequest } from '../../../shared/data-access/config/config.model';
import { useFetchFromConfigWithoutAuthQuery } from '../../../shared/data-access/config/hooks';
import { Typography } from '../../../shared/desig-system/typography.component';
import { useScrollToTop } from '../../../shared/utils/use-scroll-to-top.hook';
import { TCmsId } from '../data-access-customer-info/customer-info.model';

const TermsOfUseDetails = ({ request }: { request: TConfigWithoutAuthRequest }) => {
  const { data } = useFetchFromConfigWithoutAuthQuery(request);
  return (
    <div
      className="document"
      dangerouslySetInnerHTML={{
        __html: sanitize(data || '', {
          USE_PROFILES: { html: true },
        }),
      }}
    />
  );
};

export const TermsOfUse = () => {
  const { i18n } = useTranslation();
  const tosRequest = {
    CMSId: (env.cmsId || 'CMSID_NOT_FOUND') as TCmsId,
    LanguageCode: i18n.language,
    TranslationNamespace: 'terms-of-use', // TODO: fix hardcoded value for translation namespace
  };

  useScrollToTop();
  return (
    <>
      <Typography variant="h1" className="text-center">
        <Trans i18nKey={'TermsOfUsePage.Title'} />
      </Typography>
      <TermsOfUseDetails request={tosRequest} />
    </>
  );
};

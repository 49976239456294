import { EventMessage, EventType, IPublicClientApplication } from '@azure/msal-browser';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import {
  TUpcomingPayment,
  UPCOMING_PAYMENT_TYPE,
} from '../../features/debt-service/data-access-upcoming-payment/upcoming-payment.model';
import { TPaymentAmountSelectorMode } from '../../features/debt-service/feature-payment/components/payment-amount-selector.component';
import {
  LoginEvent,
  PayAllFullDebtEvent,
  PayCustomEvent,
  PayFullDebtEvent,
  PayInstallmentEvent,
  SignOutEvent,
} from './matomo.event';

const urlParams = new URLSearchParams(window.location.search);

type TTrackEvent = (params: TrackEventParams) => void | undefined;

interface IMatomoTracker {
  trackEvent: (params: TrackEventParams) => void;
}

export const useAiMatomo: (appInsights: ReactPlugin) => IMatomoTracker = (appInsights: ReactPlugin) => {
  const orig = useMatomo().trackEvent;
  return {
    trackEvent: (params: TrackEventParams) => {
      appInsights.trackEvent(
        {
          name: 'Matomo Event: ' + params.name || params.action,
        },
        {}
      );
      orig(params);
    },
  };
};

export const getPaymentCategory = (
  upcomingPaymentType: TUpcomingPayment['UpcomingPaymentType'],
  amountMode: TPaymentAmountSelectorMode
) => {
  switch (upcomingPaymentType) {
    case UPCOMING_PAYMENT_TYPE.AllClaims: {
      return PayAllFullDebtEvent.Category;
    }

    case UPCOMING_PAYMENT_TYPE.Claim: {
      if (amountMode === 'custom') {
        return PayCustomEvent.Category;
      }
      return PayFullDebtEvent.Category;
    }

    case UPCOMING_PAYMENT_TYPE.Instalment:
    case UPCOMING_PAYMENT_TYPE.NextInstalments: {
      return PayInstallmentEvent.Category;
    }

    default: {
      return '';
    }
  }
};

export const getPaymentStartEvent = (
  upcomingPaymentType: TUpcomingPayment['UpcomingPaymentType'],
  amountMode: TPaymentAmountSelectorMode
) => {
  switch (upcomingPaymentType) {
    case UPCOMING_PAYMENT_TYPE.AllClaims: {
      return {
        category: PayAllFullDebtEvent.Category,
        action: PayAllFullDebtEvent.Start.Action,
        name: PayAllFullDebtEvent.Start.Name,
      };
    }

    case UPCOMING_PAYMENT_TYPE.Claim: {
      if (amountMode === 'custom') {
        return {
          category: PayCustomEvent.Category,
          action: PayCustomEvent.Start.Action,
          name: PayCustomEvent.Start.Name,
        };
      }
      return {
        category: PayFullDebtEvent.Category,
        action: PayFullDebtEvent.Start.Action,
        name: PayFullDebtEvent.Start.Name,
      };
    }

    case UPCOMING_PAYMENT_TYPE.Instalment:
    case UPCOMING_PAYMENT_TYPE.NextInstalments: {
      return {
        category: PayInstallmentEvent.Category,
        action: PayInstallmentEvent.Start.Action,
        name: PayInstallmentEvent.Start.Name,
      };
    }

    default: {
      return null;
    }
  }
};

export const getPaymentSuccessEvent = (
  upcomingPaymentType: UPCOMING_PAYMENT_TYPE,
  amountMode: TPaymentAmountSelectorMode
) => {
  switch (upcomingPaymentType) {
    case UPCOMING_PAYMENT_TYPE.AllClaims: {
      return {
        category: PayAllFullDebtEvent.Category,
        action: PayAllFullDebtEvent.Success.Action,
        name: PayAllFullDebtEvent.Success.Name,
      };
    }

    case UPCOMING_PAYMENT_TYPE.Claim: {
      if (amountMode === 'custom') {
        return {
          category: PayCustomEvent.Category,
          action: PayCustomEvent.Success.Action,
          name: PayCustomEvent.Success.Name,
        };
      }
      return {
        category: PayFullDebtEvent.Category,
        action: PayFullDebtEvent.Success.Action,
        name: PayFullDebtEvent.Success.Name,
      };
    }

    case UPCOMING_PAYMENT_TYPE.Instalment:
    case UPCOMING_PAYMENT_TYPE.NextInstalments: {
      return {
        category: PayInstallmentEvent.Category,
        action: PayInstallmentEvent.Success.Action,
        name: PayInstallmentEvent.Success.Name,
      };
    }
  }
};

export const trackLoginViaCallback = (instance: IPublicClientApplication, trackEvent: TTrackEvent) => {
  instance.addEventCallback((message: EventMessage) => {
    if (message.eventType === EventType.LOGIN_SUCCESS || message.eventType === EventType.LOGIN_FAILURE) {
      trackEvent({
        category: LoginEvent.Category,
        action: LoginEvent.AuthComplete.Action,
        name: LoginEvent.AuthComplete.Name,
      });
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        trackEvent({
          category: LoginEvent.Category,
          action: LoginEvent.Success.Action,
          name: LoginEvent.Success.Name,
        });
      }
    }
  });
};

export const trackLogoutSuccess = (trackEvent: TTrackEvent) => {
  const userLogoutParam = urlParams.get('userLogout');
  if (userLogoutParam) {
    trackEvent({
      category: SignOutEvent.Category,
      action: SignOutEvent.Success.Action,
      name: SignOutEvent.Success.Name,
    });
  }
};

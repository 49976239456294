import { z } from 'zod';

const cmsIdSchema = z.string().brand<'cmsId'>();
const paymentProviderIdSchema = z.string().brand<'paymentProviderId'>();

const paymentProviderSchema = z.object({
  id: paymentProviderIdSchema,
  sortOrder: z.string().transform((item) => parseFloat(item)),
  translationNamespace: z.string().optional(),
  name: z.string(),
  nameTranslationKey: z.string(),
  description: z.string(),
  descriptionTranslationKey: z.string(),
});

export const configWithoutAuthResponseSchema = z.string();

const configWithoutAuthRequestSchema = z.object({
  CMSId: cmsIdSchema,
  LanguageCode: z.string(),
  TranslationNamespace: z.string(),
});

export const contactFormTopicsSchema = z.object({
  ContactFormTopics: z.record(
    z.object({
      id: z.string(),
      translationKey: z.string(),
    })
  ),
});

export const localeSchema = z.object({
  Locale: z.string(),
});

export const paymentProviderListSchema = z.object({
  PaymentProviders: z.record(paymentProviderSchema),
});

export const feedbackLinkSchema = z.object({
  DisplayFeedbackLink: z.string(),
});

export const bankAccountNumberSchema = z.object({
  DisplayBankAccountNumber: z.string(),
});

export type TPaymentProvider = z.infer<typeof paymentProviderSchema>;
export type TConfigWithoutAuthResponse = z.infer<typeof configWithoutAuthResponseSchema>;
export type TConfigWithoutAuthRequest = z.infer<typeof configWithoutAuthRequestSchema>;

import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TCustomerInfo } from '../../../providers/customer-info.provider';
import { NotificationPanel } from '../../../shared/desig-system/notification-panel.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { getFeatureFlagStatus } from '../../../shared/utils/feature-flags';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { DUE_STATUS, TPaymentAgreement } from '../data-access-plan-list/payment-plan.model';

interface INotificationListProps {
  customerInfo: TCustomerInfo;
  paymentAgreements: TPaymentAgreement[];
}

export const NotificationList: FC<INotificationListProps> = ({ customerInfo, paymentAgreements }) => {
  const isDesktop = useBreakpoint('desktop');
  const custDataNotificationFlag = getFeatureFlagStatus('Portal.CustomerDataInformation');
  const hasPaymentNotification = !!paymentAgreements.find((i) =>
    i.InstalmentSchedule.find((ii) => ii.DueStatus === DUE_STATUS.Late)
  );
  // Note: CMS customer notifications will be implemented alter when we have the required functinality availble.
  const hasCmsNotification = false;

  let hideCustomerDataNotification = true;
  if (customerInfo.data) {
    const d = customerInfo.data;
    hideCustomerDataNotification = hideCustomerDataNotification && !!d?.PostalAddresses?.length;
    hideCustomerDataNotification = hideCustomerDataNotification && d.PhoneNumbers && d.PhoneNumbers.length > 0;
    hideCustomerDataNotification = hideCustomerDataNotification && d.EmailAddresses && d.EmailAddresses.length > 0;
  }
  const hasCustomerDataNotification = !hideCustomerDataNotification;
  const showDataNotificiation = custDataNotificationFlag && hasCustomerDataNotification;

  if (!hasPaymentNotification && !hasCmsNotification && !hasCustomerDataNotification) {
    return <></>;
  }

  const notificationCount = [hasCmsNotification, hasPaymentNotification, hasCustomerDataNotification].reduce(
    (a, item) => a + (item ? 1 : 0),
    0
  );

  return (
    <>
      <div>
        <Typography className="pr-12px pl-12px pt-12px pb-4" variant="h3">
          <i className="bx bx-comment-error text-black text-4xl pt-12px pr-4" />
          <Trans i18nKey={'DashboardView.ImportantMessagesTitle'} />
        </Typography>
      </div>
      <div className={'mb-6 ' + (notificationCount > 2 && isDesktop ? 'notification-grid' : '')}>
        {hasPaymentNotification && (
          <NotificationPanel
            title={<Trans i18nKey={'DashboardView.NotificationPaymentOverdueTitle'}></Trans>}
            icon={<i className="bx bx-money text-black text-2xl pr-12px pl-12px pt-12px"></i>}
            message={
              <Trans
                i18nKey={'DashboardView.NotificationPaymentOverdueMessage'}
                components={{
                  paymentlink: <Link className="text-blue-800" to="/payments"></Link>,
                  paymentsicon: <i className="bx bx-money pr-8px pl-8px text-2xl align-middle"></i>,
                }}
              ></Trans>
            }
          />
        )}
        {showDataNotificiation && (
          <NotificationPanel
            title={<Trans i18nKey={'DashboardView.NotificationFillOutContactInfoTitle'}></Trans>}
            icon={<i className="bx bx-user text-black text-2xl pr-12px pl-12px pt-12px"></i>}
            message={
              <Trans
                i18nKey={'DashboardView.NotificationFillOutContactInfoMessage'}
                components={{
                  mydatalink: <Link className="text-blue-800" to="/my-data"></Link>,
                  mydataicon: <i className="bx bx-user pr-8px pl-8px text-2xl align-middle"></i>,
                }}
              ></Trans>
            }
          />
        )}
        {hasCmsNotification && (
          <NotificationPanel
            extraClassName="cms-notification"
            title={'Message Title from CMS'}
            icon={<i className="bx bx-message-error text-black text-2xl pr-12px pl-12px pt-12px"></i>}
            message={'Message body from CMS'}
          />
        )}
      </div>
      <hr className="h-0.5 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-400" />
    </>
  );
};

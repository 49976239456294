import { z } from 'zod';

export enum UPCOMING_PAYMENT_TYPE {
  Claim = 'claim',
  AllClaims = 'allClaims',
  Instalment = 'instalment',
  NextInstalments = 'nextInstalments',
}

const currencySchema = z.union([z.literal('EUR'), z.literal('GBP'), z.literal('USD'), z.literal('PLN')]);
const cmsIdSchema = z.string().brand<'cmsId'>();
const customerIdSchema = z.string().brand<'customerId'>();
const claimIdSchema = z.string().brand<'claimId'>();
const instalmentIdSchema = z.string().brand<'instalmentId'>();

const upcomingPaymentBaseSchema = z.object({
  CmsId: cmsIdSchema,
  CustomerId: customerIdSchema,
  UpcomingPaymentId: z.string(),
  Description: z.string(),
  NoteToUser: z.string().optional(),
  Currency: currencySchema,
  MinAmount: z.number(),
  MaxAmount: z.number(),
  BankAccountNumber: z.string().optional(),
  PaymentReference: z.string().optional(),
});

const upcomingPaymentAllClaimsSchema = upcomingPaymentBaseSchema.and(
  z.object({
    UpcomingPaymentType: z.literal('allClaims'),
    ClaimsCovered: z.array(claimIdSchema),
  })
);

const upcomingPaymentClaimSchema = upcomingPaymentBaseSchema.and(
  z.object({
    UpcomingPaymentType: z.literal('claim'),
    ClaimsCovered: z.array(claimIdSchema),
  })
);

const upcomingPaymentInstalmentSchema = upcomingPaymentBaseSchema.and(
  z.object({
    UpcomingPaymentType: z.union([z.literal('instalment'), z.literal('nextInstalments')]),
    InstalmentsCovered: z.array(instalmentIdSchema).optional(),
  })
);

const upcomingPaymentSchema = upcomingPaymentAllClaimsSchema
  .or(upcomingPaymentClaimSchema)
  .or(upcomingPaymentInstalmentSchema);

export const upcomingPaymentsSchema = z.array(upcomingPaymentSchema);

export type TUpcomingPayment = z.infer<typeof upcomingPaymentSchema>;
export type TInstallmentPayment = z.infer<typeof upcomingPaymentInstalmentSchema>;
export type TClaimPayment = z.infer<typeof upcomingPaymentClaimSchema>;

import { useMsal } from '@azure/msal-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Trans } from 'react-i18next';

import { useAiMatomo } from '../../shared/utils/tracking';
import { SignOutEvent } from '../utils/matomo.event';
import { B2Modal } from './b2modal.component';
import { B2Button } from './button.component';

export const LogoutConfirmationModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}) => {
  const { instance, accounts } = useMsal();
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  return (
    <B2Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} className="max-w-[400px]">
      <B2Modal.Title>
        <Trans i18nKey={'Logout.Title'} />
      </B2Modal.Title>
      <div className="flex flex-col gap-[12px]">
        <Trans i18nKey={'Logout.Subtitle'} />
        <B2Button
          variant="primary"
          onClick={async () => {
            trackEvent({
              category: SignOutEvent.Category,
              action: SignOutEvent.Start.Action,
              name: SignOutEvent.Start.Name,
            });
            await instance.logoutRedirect({
              account: accounts[0],
              postLogoutRedirectUri: '/?userLogout=true',
            });
          }}
        >
          <Trans i18nKey={'Logout.LogoutButton'} />
        </B2Button>
        <B2Button variant="secondary" onClick={() => setShowModal(false)}>
          <Trans i18nKey={'Logout.CancelButton'} />
        </B2Button>
      </div>
    </B2Modal>
  );
};

import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TClaimId } from '../../../../shared/data-access/debts/debt-data.model';
import { useDebtQuery } from '../../../../shared/data-access/debts/hooks';
import { Card } from '../../../../shared/desig-system/card.component';
import { Spinner } from '../../../../shared/desig-system/spinner.component';
import { Typography } from '../../../../shared/desig-system/typography.component';
import { usePaymentAgreementProposalsByIdQuery as usePaymentAgreementProposalByIdQuery } from '../../data-access-payment-agreement-proposal/hooks';
import { TPaymentAgreementProposalId } from '../../data-access-payment-agreement-proposal/payment-agreement-proposal.model';
import { PaymentAgreementProposalData } from './components/payment-agreement-proposal-data.component';

export const PaymentAgreementProposalDetails = () => {
  const { id, proposalId } = useParams();
  const debtQuery = useDebtQuery(id as TClaimId);
  const proposalQuery = usePaymentAgreementProposalByIdQuery(proposalId as TPaymentAgreementProposalId);

  if (debtQuery.isLoading || proposalQuery.isLoading) {
    return <Spinner />;
  }
  if (debtQuery.isError || proposalQuery.isError) {
    return (
      <Typography variant="h1" className="text-center">
        <Trans i18nKey={'PaymentAgreementProposals.ErrorLoadingProposals'} />
      </Typography>
    );
  }
  if (!debtQuery.data) {
    return (
      <Typography variant="h1">
        <Trans i18nKey={'PaymentAgreementProposals.NoDebtData'} />
      </Typography>
    );
  }
  if (!proposalQuery.data) {
    return (
      <>
        <Typography variant="h1">{debtQuery.data.ClaimName}</Typography>
        <Typography variant="body">
          <Trans i18nKey={'PaymentAgreementProposals.NoProposals'} />
        </Typography>
      </>
    );
  }

  const proposal = proposalQuery.data;

  return (
    <>
      <Typography variant="h1">{debtQuery.data.ClaimName}</Typography>
      <Card className="p-4">
        <PaymentAgreementProposalData proposal={proposal} />
      </Card>
    </>
  );
};

import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import packageJson from '../../../package.json';
import { env } from '../../env.config';

export const PageViewTracker = ({ children }: { children: ReactNode }) => {
  const { pushInstruction, trackPageView } = useMatomo();
  const { i18n } = useTranslation();

  useEffect(() => {
    pushInstruction('requireCookieConsent');
    trackPageView({
      customDimensions: [
        {
          id: 1,
          value: packageJson.version,
        },
        {
          id: 2,
          value: i18n.language,
        },
        {
          id: 3,
          value: env.cmsId,
        },
      ],
    });
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return <>{children}</>;
};

export const withPageViewTracking = (WrappedComponent: ReactNode) => (
  <PageViewTracker>{WrappedComponent}</PageViewTracker>
);

import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CustomerInfoContext, getPrimaryRecord, TCustomerInfo } from '../../../providers/customer-info.provider';
import { B2Button } from '../../../shared/desig-system/button.component';
import { Card } from '../../../shared/desig-system/card.component';
import { TextField } from '../../../shared/desig-system/input/text-field.component';
import { Spinner } from '../../../shared/desig-system/spinner.component';
import { Typography } from '../../../shared/desig-system/typography.component';
import { useChangeEmail } from '../../../shared/utils/auth/use-change-email.hook';
import { useChangePassword } from '../../../shared/utils/auth/use-change-password.hook';
import { useChangePhoneNumber } from '../../../shared/utils/auth/use-change-phone-number.hook';
import { useRemoveAccount } from '../../../shared/utils/auth/use-remove-account.hook';
import { getFeatureFlagStatus } from '../../../shared/utils/feature-flags';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import type { TEmailAddress, TPhoneNumber } from '../data-access-customer-info/customer-info.model';
import PostalAddressForm from './postal-address-form.component';

interface IDataFieldProps {
  label: string;
  body: string;
}

const PersonalDataField: FC<IDataFieldProps> = ({ label, body }) => {
  return (
    <div>
      <Typography variant="body" className="font-bold">
        {label}
      </Typography>
      <Typography variant="body">
        <span data-matomo-mask={true}>{body}</span>
      </Typography>
    </div>
  );
};

interface IPersonalDataProps {
  customerInfo: TCustomerInfo['data'];
}

const PersonalData: FC<IPersonalDataProps> = ({ customerInfo }) => {
  const { t } = useTranslation();

  if (!customerInfo) {
    return <PersonalDataField label={t('MyDataPage.FullName')} body={''} />;
  }

  if (customerInfo.GivenName && customerInfo.Surname) {
    return (
      <>
        <PersonalDataField label={t('MyDataPage.FirstName')} body={customerInfo.GivenName} />
        <PersonalDataField label={t('MyDataPage.LastName')} body={customerInfo.Surname} />
      </>
    );
  }

  if (customerInfo.FullName) {
    return <PersonalDataField label={t('MyDataPage.FullName')} body={customerInfo.FullName} />;
  }
};

export const MyData = () => {
  const { data, isLoading, isError } = useContext(CustomerInfoContext);
  const isDesktop = useBreakpoint('desktop');
  const addOrChangePostalAddress = getFeatureFlagStatus('Portal.AddOrChangePostalAddress');
  const addOrChangePhoneNumber = getFeatureFlagStatus('Portal.AddOrChangePhoneNumber');
  const addOrChangeEmailAddress = getFeatureFlagStatus('Portal.AddOrChangeEmailAddress');
  const changePhoneNumber = useChangePhoneNumber();
  const changeEmail = useChangeEmail();
  const changePassword = useChangePassword();
  const removeAccount = useRemoveAccount();

  if (isLoading) return <Spinner />;
  if (isError)
    return (
      <>
        <Typography variant="h1">
          <Trans i18nKey="MyDataPage.Title" />
        </Typography>
        <Typography variant="h3">
          <Trans i18nKey="MyDataPage.ErrorLoadingCustomerInfo" />
        </Typography>
      </>
    );

  return (
    <>
      <div className={`${!isDesktop ? 'max-w-xs gap-2 flex flex-col' : 'w-full gap-2 flex flex-col'}`}>
        <Typography variant="h1">
          <Trans i18nKey="MyDataPage.Title" />
        </Typography>
        <Typography variant="bodyTiny">
          <Trans i18nKey="MyDataPage.DataCannotBeChanged" />
        </Typography>
        <Card
          className={`${
            isDesktop ? 'max-w min-w-[500px] flex flex-col gap-2 p-8' : 'max-w-[340px] flex flex-col gap-2 p-2'
          }`}
        >
          <PersonalData customerInfo={data} />
        </Card>

        {!!data?.PhoneNumbers?.length && (
          <Card
            className={`${
              isDesktop ? 'max-w min-w-[500px] flex flex-col gap-2 p-8' : 'max-w-[340px] flex flex-col gap-2 p-2'
            }`}
          >
            <Typography variant="body" className="font-bold">
              <Trans i18nKey="MyDataPage.PhoneNumber" />
            </Typography>
            <div data-matomo-mask={true}>
              <TextField value={getPrimaryRecord<TPhoneNumber>(data?.PhoneNumbers)?.PhoneNumber ?? ''} disabled />
            </div>
          </Card>
        )}
        {!!data?.EmailAddresses?.length && (
          <Card
            className={`${
              isDesktop ? 'max-w min-w-[500px] flex flex-col gap-2 p-8' : 'max-w-[340px] flex flex-col gap-2 p-2'
            }`}
          >
            <Typography variant="body" className="font-bold">
              <Trans i18nKey="MyDataPage.Email" />
            </Typography>
            <div data-matomo-mask={true}>
              <TextField value={getPrimaryRecord<TEmailAddress>(data?.EmailAddresses)?.Email ?? ''} disabled />
            </div>
          </Card>
        )}
        {!!data && addOrChangePostalAddress === true && <PostalAddressForm data={data} />}
        {(!!addOrChangeEmailAddress || !!addOrChangePhoneNumber) && (
          <Card
            className={`${
              isDesktop ? 'max-w min-w-[500px] flex flex-col gap-2 p-8' : 'max-w-[340px] flex flex-col gap-2 p-2'
            }`}
          >
            <Typography variant="body" className="font-bold">
              <Trans i18nKey="MyDataPage.ChangeContactInformation" />
            </Typography>
            {addOrChangePhoneNumber && (
              <B2Button className="max-w-[320px]" variant="primary" onClick={changePhoneNumber}>
                <Trans i18nKey="MyDataPage.ButtonChangePhonenumber" />
              </B2Button>
            )}
            {addOrChangeEmailAddress && (
              <B2Button variant="primary" className="max-w-[320px]" onClick={changeEmail}>
                <Trans i18nKey="MyDataPage.ButtonChangeEmailAddress" />
              </B2Button>
            )}
          </Card>
        )}
        <Card
          className={`${
            isDesktop ? 'max-w min-w-[500px] flex flex-col gap-2 p-8' : 'max-w-[340px] flex flex-col gap-2 p-2'
          }`}
        >
          <Typography variant="body" className="font-bold">
            <Trans i18nKey="MyDataPage.ChangePassword" />
          </Typography>
          <B2Button className="max-w-[320px]" variant="primary" onClick={changePassword}>
            <Trans i18nKey="MyDataPage.ButtonChangePassword" />
          </B2Button>
        </Card>
        <Card
          className={`${
            isDesktop ? 'max-w min-w-[500px] flex flex-col gap-2 p-8' : 'max-w-[340px] flex flex-col gap-2 p-2'
          }`}
        >
          <Typography variant="body" className="font-bold">
            <Trans i18nKey="MyDataPage.RemoveAccountHeader" />
          </Typography>
          {isDesktop ? (
            <B2Button
              className="max-w-[320px]"
              variant="primary"
              onClick={() => removeAccount({ redirectUri: '/account_deleted', state: { cancelToView: '/my-data' } })}
            >
              <Trans i18nKey="MyDataPage.RemoveAccount" />
            </B2Button>
          ) : (
            <B2Button variant="primary" onClick={() => removeAccount()}>
              <Trans i18nKey="MyDataPage.RemoveAccount" />
            </B2Button>
          )}
        </Card>
      </div>
    </>
  );
};

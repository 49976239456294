import { useMutation, useQuery } from '@tanstack/react-query';

import { fetchPaymentSessionStatus, initiatePaymentSession } from './api';
import { TInitiatePaymentRequest } from './initiate-payment-request.model';
import { TPaymentStatusRequest } from './payment-status-request.model';

export const useInitiatePaymentSessionMutation = () =>
  useMutation({
    mutationKey: ['initiate-payment-request'],
    mutationFn: (data: TInitiatePaymentRequest) => initiatePaymentSession(data),
  });

export const useFetchPaymentSessionStatusQuery = (request: TPaymentStatusRequest) => {
  return useQuery({
    queryKey: ['payment-session-status', request],
    queryFn: () => fetchPaymentSessionStatus(request),
  });
};

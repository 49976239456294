type TCurrency = 'EUR' | 'GBP' | 'USD' | 'PLN';

export const isValidInputNumber = (value: string): boolean =>
  isCommaOrDotSuffixedNumber(value) || isCommaOrDotSeparatedNumber(value);

export const isCommaOrDotSuffixedNumber = (value: string): boolean => {
  const regex = /^\d+[,.]$/;
  return !!value.match(regex)?.length;
};

export const isCommaOrDotSeparatedNumber = (value: string): boolean => {
  const regex = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/);
  return !!value.match(regex)?.length;
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export let formatCurrencyValue = (value: number | string, currency: TCurrency): string => String(value); // Return unformatted value if function uninitialized

export const initializeCurrencyFormatter = (locale: string | undefined = 'et-EE') => {
  formatCurrencyValue = (value, currency = 'EUR') =>
    value.toLocaleString(locale, {
      style: 'currency',
      currency,
    });
};

export const formatNumberValue = (value: number | string): number => {
  if (typeof value === 'number') return value;
  if (isCommaOrDotSuffixedNumber(value)) {
    return value.includes(',') ? Number(value.split(',')[0]) : Number(value);
  }
  if (!isCommaOrDotSeparatedNumber(value)) return NaN;
  if (value.includes('.') && !value.includes(',')) return Number(value);
  return Number(value.replace(',', '.'));
};

import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TClaimId } from '../../../../shared/data-access/debts/debt-data.model';
import { useDebtQuery } from '../../../../shared/data-access/debts/hooks';
import { Card } from '../../../../shared/desig-system/card.component';
import { Spinner } from '../../../../shared/desig-system/spinner.component';
import { Typography } from '../../../../shared/desig-system/typography.component';
import { formatCurrencyValue } from '../../../../shared/utils/formatting';
import { useBreakpoint } from '../../../../shared/utils/media-queries';
import { PaymentAgreementProposalsForClaim } from './components/payment-agreement-proposal-for-claims.component';

export const PaymentAgreementProposalList = () => {
  const { id } = useParams();
  const debtQuery = useDebtQuery(id as TClaimId);
  const { t } = useTranslation();
  const isDesktop = useBreakpoint('desktop');

  if (debtQuery.isLoading) return <Spinner />;
  if (debtQuery.isError) {
    return (
      <Typography variant="h1" className="text-center">
        <Trans i18nKey={'PaymentAgreementProposals.ErrorLoadingDebts'} />
      </Typography>
    );
  }

  const debtData = debtQuery.data;

  if (!debtData) {
    return (
      <Typography variant="body">
        <Trans i18nKey={'PaymentAgreementProposals.NoDebtData'} />
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h1">{debtData.ClaimName}</Typography>
      <Card className="p-4">
        <div className="flex flex-row items-end">
          <Typography variant="body" className={`${isDesktop && 'mb-[2px] flex flex-grow flex-grow-0'}`}>
            <Trans i18nKey={'PaymentAgreementProposals.DebtTotalAmount'} />
          </Typography>
          <Typography variant="h4">
            {(debtData.TotalAmount || debtData.TotalAmount === 0) && (
              <span className={`flex font-bold ml-2 ${isDesktop && 'flex font-bold ml-2 ml-4'}`}>
                {formatCurrencyValue(debtData.TotalAmount, debtData.Currency)}
              </span>
            )}
            {(debtData.TotalAmount === undefined || debtData.TotalAmount === null) && (
              <span className={`"flex font-bold ml-2 ${isDesktop && 'ml-4'}"`}>
                {formatCurrencyValue(t('DebtCard.AmountUnknownValue'), debtData.Currency)}
              </span>
            )}
          </Typography>
        </div>
        {(debtData.TotalAmount === undefined || debtData.TotalAmount === null) && (
          <Typography variant="bodyTiny">
            <Trans i18nKey={'DebtCard.AmountUnknownExplaination'} />
          </Typography>
        )}
        <Typography
          variant="h3"
          className={`"text-center ${isDesktop && 'text-start'} my-4 font-semibold break-normal"`}
        >
          <Trans i18nKey="PaymentAgreementProposals.ChoosePaymentPlan" />
        </Typography>
        <PaymentAgreementProposalsForClaim debt={debtData} />
      </Card>
    </>
  );
};

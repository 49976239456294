import { Transition } from '@headlessui/react';
import { FC, PropsWithChildren } from 'react';

export interface IFadeInDivProps extends PropsWithChildren {
  appear?: boolean;
  show?: boolean;
  className?: string;
}

export const FadeInDiv: FC<IFadeInDivProps> = ({ children, className, show, appear }) => {
  return (
    <Transition
      show={show === undefined ? true : show}
      appear={appear === undefined ? true : appear}
      enter="transition-opacity ease-in duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-in duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={className}
    >
      {children}
    </Transition>
  );
};

import { Trans } from 'react-i18next';

import { Typography } from '../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../shared/utils/media-queries';
import { ContactForm } from './components/contact-form.component';
import { PhoneCard } from './components/phone-card.component';

export const Contact = () => {
  const isDesktop = useBreakpoint('desktop');
  return (
    <>
      <Typography
        variant="h1"
        className={`${isDesktop ? 'text-start' : 'text-center'} ${!isDesktop && 'max-w-[340px]'}`}
      >
        <Trans i18nKey={'ContactPage.Title'} />
      </Typography>
      <Typography
        variant="h3"
        className={`${isDesktop ? 'text-start' : 'text-center'} ${!isDesktop && 'max-w-[340px]'}`}
      >
        <Trans i18nKey={'ContactPage.Subtitle'} />
      </Typography>
      <PhoneCard isDesktop={isDesktop} />
      <ContactForm />
    </>
  );
};

import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { B2Modal } from '../../desig-system/b2modal.component';
import { B2Button } from '../../desig-system/button.component';
import { B2Switch } from '../../desig-system/switch.component';
import { Typography } from '../../desig-system/typography.component';
import { consentIsSet } from '../../utils/cookies';
import { CookieContext } from './cookie-management.provider';

type TCookieManagementModalProps = {
  setIsVisible: () => void;
  isVisible: boolean;
};

export const CookieManagementModal = ({ setIsVisible, isVisible }: TCookieManagementModalProps) => {
  const { cookies, acceptAllCookies, setCookiesToClient, setCookieConsentIsOpen, setCookieManagementIsOpen } =
    useContext(CookieContext);

  return (
    <B2Modal isOpen={isVisible} onRequestClose={setIsVisible} className="max-w-[400px]">
      <B2Modal.Title>
        <Trans i18nKey={'CookieConsent.Management.Title'} />
      </B2Modal.Title>

      <Typography variant="body">
        <Trans
          i18nKey={'CookieConsent.Management.Description'}
          components={[
            <Link
              key="cookie-consent-privacy-policy"
              to="/cookie-policy"
              className="hover:underline text-primaryblue"
              onClick={() => {
                setCookieManagementIsOpen(false);
                if (!consentIsSet) setCookieConsentIsOpen(true);
              }}
            ></Link>,
          ]}
        />
      </Typography>

      <div className="flex flex-col align-middle space-y-4 mt-4 mb-4">
        {cookies.map((cookie) => (
          <div key={cookie.id}>
            <B2Switch
              title={<Trans ns="cookies" i18nKey={cookie.titleTranslationId} />}
              onChange={(checked: boolean) => {
                const cookieToAccept = cookies.find(({ id }) => cookie.id === id);
                if (cookieToAccept) cookieToAccept.accepted = checked;
              }}
              isChecked={cookie.accepted}
              disabled={cookie.strictlyNecessary}
            />
            <Typography variant="body">
              <Trans ns="cookies" i18nKey={cookie.descriptionTranslationId} />
            </Typography>
          </div>
        ))}
      </div>

      <div className="flex flex-col align-middle space-y-4 mt-4 mb-4">
        <B2Button
          variant="primary"
          onClick={() => {
            setCookiesToClient();
            setIsVisible();
          }}
        >
          <Typography variant="button">
            <Trans i18nKey={'CookieConsent.Management.ConfirmButton'} />
          </Typography>
        </B2Button>
        <B2Button
          variant="secondary"
          onClick={() => {
            acceptAllCookies();
            setIsVisible();
          }}
        >
          <Typography variant="button">
            <Trans i18nKey={'CookieConsent.Management.AcceptAllButton'} />
          </Typography>
        </B2Button>
      </div>
    </B2Modal>
  );
};

/*
 * Here is a list of static keys for possible cookie values, so i18next-parser
 * does not remove these keys when it doesn't detect them from the code.
 *
 * t('Azure.DescriptionAdB2c', { ns: 'cookies' })
 * t('Azure.DescriptionApplicationInsights', { ns: 'cookies' })
 * t('Azure.TitleAdB2c', { ns: 'cookies' })
 * t('Azure.TitleApplicationInsights', { ns: 'cookies' })
 * t('Consent.Description', { ns: 'cookies' })
 * t('Consent.Title', { ns: 'cookies' })
 * t('Matomo.DescriptionEssential', { ns: 'cookies' })
 * t('Matomo.DescriptionFirstParty', { ns: 'cookies' })
 * t('Matomo.DescriptionUserId', { ns: 'cookies' })
 * t('Matomo.TitleEssential', { ns: 'cookies' })
 * t('Matomo.TitleFirstParty', { ns: 'cookies' })
 * t('Matomo.TitleUserId', { ns: 'cookies' })
 *
 * */

import { Trans, useTranslation } from 'react-i18next';
import { Outlet, RouteObject, useParams } from 'react-router-dom';

import { NavLayout } from '../../../layouts/nav-layout.component';
import { BasicBreadCrumb } from '../../../shared/desig-system/breadcrumbs.component';
import { withPageViewTracking } from '../../../shared/ui/page-view-tracker.component';
import { usePlanQuery } from '../data-access-plan-list/hooks';
import { TPaymentAgreementId } from '../data-access-plan-list/payment-plan.model';
import { PlanDetails } from './plan-details.component';
import { PlanList } from './plan-list.component';

export const PaymentPlanBreadCrumb = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = usePlanQuery(id as TPaymentAgreementId);
  return (
    <BasicBreadCrumb linkTo={'/debts/:id'}>
      <span data-matomo-mask={true}>
        {t('Breadcrumbs.PaymentPlan', {
          PaymentAgreementName: data?.PaymentAgreementName ?? 'Payment plan',
        })}
      </span>
    </BasicBreadCrumb>
  );
};

export const routes: RouteObject[] = [
  {
    path: '/payment-plans',
    element: (
      <NavLayout>
        <Outlet />
      </NavLayout>
    ),
    handle: {
      breadcrumb: (
        <BasicBreadCrumb linkTo={'/payment-plans'}>
          <Trans i18nKey={'Breadcrumbs.PaymentPlans'} />
        </BasicBreadCrumb>
      ),
    },
    children: [
      {
        path: '/payment-plans',
        element: withPageViewTracking(<PlanList />),
      },
      {
        path: '/payment-plans/:id',
        element: withPageViewTracking(<PlanDetails />),
        handle: {
          breadcrumb: <PaymentPlanBreadCrumb />,
        },
      },
    ],
  },
];

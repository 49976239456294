import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';

import { twMerge } from './tailwind.config';

export interface IB2ButtonProps
  extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'className'> {
  variant: 'primary' | 'link' | 'secondary';
  children: ReactNode | ReactNode[];
  disabled?: boolean;
  className?: string;
}

const buttonConfig = {
  primary: {
    bgColorClass: 'bg-primarybutton',
    bgColorHoverClass: 'hover:bg-primarybuttonhover',
    textColor: 'text-primarywhite',
    border: 'border-2 border-primarybutton  ',
  },
  link: {
    bgColorClass: 'bg-linkbutton',
    bgColorHoverClass: 'hover:bg-linkbuttonhover',
    textColor: 'text-primaryblue',
    border: 'border-2 border-linkbutton',
  },
  secondary: {
    bgColorClass: 'bg-primarywhite',
    bgColorHoverClass: 'hover:bg-primarywhite',
    textColor: 'text-primaryblue',
    border: 'border-2 border-primaryblue',
  },
};

export const B2Button: FC<IB2ButtonProps> = (props) => {
  const { children, variant, className, ...otherProps } = props;
  return (
    <button
      {...otherProps}
      className={twMerge(
        `
      relative
      font-sans
      font-semibold
      w-full 
      px-6
      py-3
      min-w-[120px]
      rounded-full
      hover:shadow-buttonhover
      transition-all
      focus:outline
      focus:outline-2
      focus:outline-offset-2
      focus:outline-buttonfocus
      disabled:shadow-none
      disabled:text-buttontextdisabled
      disabled:bg-buttondisabled
      disabled:border-transparent
      ${buttonConfig[variant].bgColorClass}
      ${buttonConfig[variant].bgColorHoverClass}
      ${buttonConfig[variant].textColor}
      ${buttonConfig[variant].border}`,
        className
      )}
    >
      {children}
    </button>
  );
};

import { useMsal } from '@azure/msal-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TPaymentProvider } from '../../../../shared/data-access/config/config.model';
import { useBankAccountNumberQuery, usePaymentProviderListQuery } from '../../../../shared/data-access/config/hooks';
import { TClaimId, TDebtData } from '../../../../shared/data-access/debts/debt-data.model';
import { useDebtsListQuery } from '../../../../shared/data-access/debts/hooks';
import { B2Button } from '../../../../shared/desig-system/button.component';
import { DataCard } from '../../../../shared/desig-system/data-card.component';
import { Spinner, SpinnerWrapper } from '../../../../shared/desig-system/spinner.component';
import { Typography } from '../../../../shared/desig-system/typography.component';
import { getFeatureFlagStatus } from '../../../../shared/utils/feature-flags';
import { formatCurrencyValue } from '../../../../shared/utils/formatting';
import { PaymentFailed, PaymentStart } from '../../../../shared/utils/matomo.event';
import { getPaymentCategory, getPaymentStartEvent, useAiMatomo } from '../../../../shared/utils/tracking';
import { useInitiatePaymentSessionMutation } from '../../data-access-payment-session/hooks';
import { TInitiatePaymentRequest } from '../../data-access-payment-session/initiate-payment-request.model';
import { usePlansListQuery } from '../../data-access-plan-list/hooks';
import { useUpcomingPaymentByIdQuery } from '../../data-access-upcoming-payment/hooks';
import { TUpcomingPayment, UPCOMING_PAYMENT_TYPE } from '../../data-access-upcoming-payment/upcoming-payment.model';
import { PaymentAmountSelector, TPaymentAmountSelectorMode } from '../components/payment-amount-selector.component';

const Divider = () => {
  return <div className="border-t-2 border-contentbglight" />;
};

const BankAccountNumberFromConfig = () => {
  const { data: bankAccountNumberData, isLoading: isLoadingBankAccountNumber } = useBankAccountNumberQuery();
  if (isLoadingBankAccountNumber)
    return (
      <div className="relative">
        <Spinner size="extra-small" noMargin isInline />
      </div>
    );
  return (
    <Typography variant="body" className="font-bold">
      {bankAccountNumberData?.DisplayBankAccountNumber}
    </Typography>
  );
};

export const PaymentNew = () => {
  const { upcomingPaymentId } = useParams();
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  const { accounts } = useMsal();
  const {
    mutate: initiatePaymentSession,
    isPending: isLoadingInitiateSession,
    isError: isErrorInitiateSession,
  } = useInitiatePaymentSessionMutation();
  const {
    data: upcomingPayment,
    isLoading: isLoadingUpcomingPayment,
    isError: isErrorUpcomingPayment,
  } = useUpcomingPaymentByIdQuery(upcomingPaymentId);
  const { data: claims, isLoading: isLoadingClaims, isError: isErrorClaims } = useDebtsListQuery();
  const {
    data: paymentPlansData,
    isLoading: isLoadingPaymentPlans,
    isError: isErrorPaymentPlans,
  } = usePlansListQuery();
  const {
    data: paymentProvidersData,
    isLoading: isLoadingPaymentProviders,
    isError: isErrorPaymentProviders,
  } = usePaymentProviderListQuery();
  const [paymentAmount, setPaymentAmount] = useState<number | undefined>(undefined);
  const showBankAccountNumberFromConfig = getFeatureFlagStatus('Portal.Payments.ShowBankAccountNumberFromConfig');

  const [amountMode, setAmountMode] = useState<TPaymentAmountSelectorMode>('all');
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (upcomingPayment && !isLoadingUpcomingPayment && !isErrorUpcomingPayment) {
      const event = getPaymentStartEvent(upcomingPayment.UpcomingPaymentType, amountMode);
      if (event) {
        trackEvent(event);
      }
    }
  }, [trackEvent, upcomingPayment, amountMode, isLoadingUpcomingPayment, isErrorUpcomingPayment]);

  const setPaymentAmountCallback = (newAmount: number | null) => {
    if (newAmount === undefined || newAmount === null) {
      setPaymentAmount(undefined);
    } else {
      setPaymentAmount(newAmount);
    }
  };

  const buildRedirectUri = (
    paymentType: TUpcomingPayment['UpcomingPaymentType'],
    amountMode: TPaymentAmountSelectorMode
  ) => {
    const { protocol, host } = window.location;
    let redirectUri = `${protocol}//${host}/payments/payment-result?paymentType=${paymentType}`;

    if (paymentType === UPCOMING_PAYMENT_TYPE.Claim) {
      redirectUri += `&amountMode=${amountMode}`;
    }
    return redirectUri;
  };

  const getPaymentRequestByType = useCallback(
    (providerId: TPaymentProvider['id']): TInitiatePaymentRequest | undefined => {
      if (!upcomingPayment) {
        return;
      }

      // const isClaimPayment = (payment): payment is => {
      //
      // }

      const requestSkeleton = {
        PaymentProviderId: providerId,
        CmsId: upcomingPayment.CmsId,
        CustomerId: upcomingPayment.CustomerId,
        CurrencyCode: upcomingPayment.Currency,
        RedirectUri: buildRedirectUri(upcomingPayment.UpcomingPaymentType, amountMode),
        PayerEmail: accounts?.[0]?.idTokenClaims?.emails?.[0] ?? '',
      };

      switch (upcomingPayment.UpcomingPaymentType) {
        case UPCOMING_PAYMENT_TYPE.AllClaims:
          return {
            ...requestSkeleton,
            UpcomingPaymentType: UPCOMING_PAYMENT_TYPE.AllClaims,
            ClaimsCovered: upcomingPayment.ClaimsCovered || [],
          };
        case UPCOMING_PAYMENT_TYPE.Claim:
          return {
            ...requestSkeleton,
            UpcomingPaymentType: UPCOMING_PAYMENT_TYPE.Claim,
            Amount: amountMode === 'all' ? upcomingPayment.MaxAmount : paymentAmount || 0,
            ClaimsCovered: [upcomingPayment.ClaimsCovered[0]],
          };
        case UPCOMING_PAYMENT_TYPE.Instalment:
          return {
            ...requestSkeleton,
            UpcomingPaymentType: UPCOMING_PAYMENT_TYPE.Instalment,
            InstalmentsCovered: upcomingPayment.InstalmentsCovered,
          };
        case UPCOMING_PAYMENT_TYPE.NextInstalments:
          return {
            ...requestSkeleton,
            UpcomingPaymentType: UPCOMING_PAYMENT_TYPE.NextInstalments,
            InstalmentsCovered: upcomingPayment.InstalmentsCovered,
          };
        default:
          return;
      }
    },
    [upcomingPayment, accounts, amountMode, paymentAmount]
  );

  // Handle create payment session request when user clicks on the button
  const onClickInitiatePaymentSession = async (provider: TPaymentProvider) => {
    if (!upcomingPayment) {
      return;
    }

    const request = getPaymentRequestByType(provider.id);
    if (!request) {
      return;
    }

    initiatePaymentSession(request, {
      onSuccess: (data) => {
        trackEvent({
          category: getPaymentCategory(upcomingPayment.UpcomingPaymentType, amountMode),
          action: PaymentStart.Action,
          name: PaymentStart.Name,
        });
        if (data.RedirectLink) {
          // Manually controlling a state variable to show a loading spinner while redirect is loading
          setIsRedirecting(true);
          window.location.href = data.RedirectLink;
        }
      },
      onError: () => {
        trackEvent({
          category: getPaymentCategory(upcomingPayment.UpcomingPaymentType, amountMode),
          action: PaymentFailed.Action,
          name: PaymentFailed.Name,
        });
      },
    });
  };

  const isError = isErrorClaims || isErrorUpcomingPayment || isErrorPaymentPlans;

  const paymentAmountCanBeChanged = upcomingPayment?.MinAmount !== upcomingPayment?.MaxAmount;

  // Figure out claims covered by upcoming payment & get their data, so we can display claim names
  let paymentCoveredClaimsData: TDebtData[] = [];
  if (upcomingPayment && claims && paymentPlansData) {
    let claimsCovered: TClaimId[] = [];

    // For Claim / AllClaims payments the object contains the claim ids covered directly
    if (
      upcomingPayment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.AllClaims ||
      upcomingPayment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.Claim
    ) {
      claimsCovered = upcomingPayment.ClaimsCovered || [];
    }

    // For Instalment / NextInstalments type payments, the object contains the instalments covered
    // which then have to be mapped to paymentPlans and those then have to be mapped to claimIds
    // ¯\_(ツ)_/¯
    if (
      upcomingPayment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.Instalment ||
      upcomingPayment.UpcomingPaymentType === UPCOMING_PAYMENT_TYPE.NextInstalments
    ) {
      claimsCovered =
        upcomingPayment.InstalmentsCovered?.flatMap(
          (instalmentId) =>
            paymentPlansData.find((plan) =>
              plan.InstalmentSchedule.some((instalment) => instalment.InstalmentId === instalmentId)
            )?.ClaimsCovered ?? []
        ) || [];
    }

    paymentCoveredClaimsData = claimsCovered
      .map((claimId) => claims.find((claim) => claim.ClaimId === claimId))
      .filter((claim) => claim != undefined) as TDebtData[];
  }

  let collapsibleFooter = undefined;
  if (upcomingPayment?.BankAccountNumber && upcomingPayment.PaymentReference) {
    collapsibleFooter = {
      collapsedLabel: <Trans i18nKey="NewPaymentPage.ExpandTransferData" />,
      expandToggleable: true,
      isExpanded: false,
      expandedComponent: (
        <div className="flex flex-col gap-2 p-4">
          <div>
            <Typography variant="body">
              <Trans i18nKey={'NewPaymentPage.BankAccountNumber'} />
            </Typography>
            {showBankAccountNumberFromConfig === true && <BankAccountNumberFromConfig />}
            {!showBankAccountNumberFromConfig && (
              <Typography variant="body" className="font-bold">
                {upcomingPayment?.BankAccountNumber}
              </Typography>
            )}
          </div>
          <div>
            <Typography variant="body">
              <Trans i18nKey={'NewPaymentPage.ReferenceNumber'} />
            </Typography>
            <Typography variant="body" className="font-bold">
              {upcomingPayment?.PaymentReference}
            </Typography>
          </div>
        </div>
      ),
      onLabelClick: () => {
        return;
      },
    };
  }

  return (
    <>
      <Typography variant="h1">
        <Trans i18nKey="NewPaymentPage.Title" />
      </Typography>
      <SpinnerWrapper isLoading={isLoadingUpcomingPayment || isLoadingClaims || isLoadingPaymentPlans}>
        {isError && (
          <Typography variant="h1" className="text-center text-error">
            <Trans i18nKey={'NewPaymentPage.ErrorLoadingPayment'} />
          </Typography>
        )}
        {!isError && upcomingPayment && paymentCoveredClaimsData && paymentCoveredClaimsData.length > 0 && (
          <DataCard collapsibleFooter={collapsibleFooter}>
            <div className="p-4">
              {paymentCoveredClaimsData.map((claim) => (
                <Typography variant="h6" className="font-bold" key={claim.ClaimId}>
                  {claim.ClaimName}
                </Typography>
              ))}

              <div className="flex flex-col gap-3 mt-4">
                <Divider />
                {paymentAmountCanBeChanged && (
                  <PaymentAmountSelector
                    upcomingPayment={upcomingPayment}
                    onChangeCustomAmount={setPaymentAmountCallback}
                    onChangeMode={setAmountMode}
                    mode={amountMode}
                  />
                )}
                {!paymentAmountCanBeChanged && (
                  <div>
                    <Typography variant="body">
                      <Trans i18nKey={'NewPaymentPage.PaymentAmount'} />
                    </Typography>
                    <Typography variant="body" className="font-bold">
                      {formatCurrencyValue(upcomingPayment.MaxAmount, upcomingPayment.Currency)}
                    </Typography>
                  </div>
                )}
                <Divider />
                <Typography variant="body">
                  <Trans i18nKey={'NewPaymentPage.BodyTextAboveButton'} />
                </Typography>
                {(isErrorInitiateSession || isErrorPaymentProviders) && (
                  <Typography variant="bodyTiny" className="text-error">
                    <Trans i18nKey={'NewPaymentPage.ErrorInitializingPayment'} />
                  </Typography>
                )}
                <SpinnerWrapper isLoading={isLoadingInitiateSession || isRedirecting || isLoadingPaymentProviders}>
                  {paymentProvidersData && (
                    <div className="flex flex-col gap-4">
                      {Object.keys(paymentProvidersData.PaymentProviders).map((key) => (
                        <B2Button
                          key={key}
                          variant="primary"
                          disabled={
                            amountMode === 'custom' &&
                            (!paymentAmount ||
                              paymentAmount < upcomingPayment.MinAmount ||
                              paymentAmount > upcomingPayment.MaxAmount)
                          }
                          className="desktop:max-w-[300px]"
                          onClick={() => {
                            onClickInitiatePaymentSession(paymentProvidersData.PaymentProviders[key]);
                          }}
                        >
                          {paymentProvidersData.PaymentProviders[key].description}
                        </B2Button>
                      ))}
                    </div>
                  )}
                </SpinnerWrapper>
              </div>
            </div>
          </DataCard>
        )}
      </SpinnerWrapper>
    </>
  );
};

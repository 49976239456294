import { FC, ReactNode } from 'react';
import { Link, useMatches, useParams } from 'react-router-dom';

import { useBreakpoint } from '../utils/media-queries';
import { IRouteHandle } from '../utils/router';
import { twMerge } from './tailwind.config';

export interface IGenericBreadcrumbProps {
  linkTo: string;
  children: ReactNode | ReactNode[];
  className?: string;
}

export const BasicBreadCrumb: FC<IGenericBreadcrumbProps> = ({ linkTo, children, className }) => {
  const params = useParams();
  let linkWithParams = linkTo;

  // Fill in route params to path in linkTo
  Object.keys(params).forEach((key) => {
    const matcher = new RegExp(`(\\/)(:${key})(\\/|$)`, 'gm');
    linkWithParams = linkWithParams.replace(matcher, (_match, p1, _p2, p3) => {
      return `${p1}${params[key]}${p3}`;
    });
  });
  return (
    <Link to={linkWithParams} className={twMerge('', className)}>
      {children}
    </Link>
  );
};

export interface IBreadCrumbsDisplayerProps {
  className?: string;
}

export const BreadCrumbsDisplayer: FC<IBreadCrumbsDisplayerProps> = ({ className }) => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => {
      return (match?.handle as IRouteHandle)?.breadcrumb;
    })
    .map((match) => (match?.handle as IRouteHandle).breadcrumb);

  const isDesktopLayout = useBreakpoint('desktop');

  return (
    <ol className={twMerge('flex flex-row whitespace-pre-wrap', className)}>
      {crumbs.map((crumb, index) => {
        const isLastCrumb = index === crumbs.length - 1;
        const isSecondToLastCrumb = index === crumbs.length - 2;
        return (
          <li key={index} className="flex flex-row items-center text-center">
            {!isDesktopLayout && isSecondToLastCrumb && <i className="bx bx-chevron-left" />}
            <span
              className={`
              text-primaryblue 
                ${isLastCrumb ? 'font-semibold ' : ''}
              `}
            >
              {isDesktopLayout ? crumb : isSecondToLastCrumb && crumb}
            </span>
            {isDesktopLayout && !isLastCrumb && <i className="bx bx-chevron-right" />}
          </li>
        );
      })}
    </ol>
  );
};

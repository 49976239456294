import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';

import { env } from '../../../env.config';

const B2C_AUTHORITY_DOMAIN = env.auth.authorityDomain;
const B2C_SIGNUPSIGNIN_FLOW_URL = env.auth.flows.signUpSignIn;
const B2C_CHANGEPASSWORD_FLOW_URL = env.auth.flows.changePassword;
const B2C_REMOVEACCOUNT_FLOW_URL = env.auth.flows.removeAccount;
const REACT_APP_B2C_CHANGEEMAIL_FLOW_URL = env.auth.flows.changeEmail;
const REACT_APP_B2C_CHANGEPHONENUMBER_FLOW_URL = env.auth.flows.changePhoneNumber;

export const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: B2C_SIGNUPSIGNIN_FLOW_URL,
    },
    changePassword: {
      authority: B2C_CHANGEPASSWORD_FLOW_URL,
    },
    removeAccount: {
      authority: B2C_REMOVEACCOUNT_FLOW_URL,
    },
  },
  authorityDomain: B2C_AUTHORITY_DOMAIN,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: env.auth.clientId, // This is the ONLY mandatory field that you need to supply.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    redirectUri: '/',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [B2C_AUTHORITY_DOMAIN],
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  authority: B2C_SIGNUPSIGNIN_FLOW_URL,
  scopes: env.auth.scopes,
  redirectStartPage: `/home`,
};

export const changePasswordRequest: RedirectRequest = {
  authority: B2C_CHANGEPASSWORD_FLOW_URL,
  scopes: env.auth.scopes,
};

export const removeAccountRequest: RedirectRequest = {
  authority: B2C_REMOVEACCOUNT_FLOW_URL,
  scopes: env.auth.scopes,
};

export const changeEmailRequest: RedirectRequest = {
  authority: REACT_APP_B2C_CHANGEEMAIL_FLOW_URL,
  scopes: env.auth.scopes,
};

export const changePhonenumberRequest: RedirectRequest = {
  authority: REACT_APP_B2C_CHANGEPHONENUMBER_FLOW_URL,
  scopes: env.auth.scopes,
};

import 'react-datepicker/dist/react-datepicker.css';

import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { B2Button } from '../../../../../shared/desig-system/button.component';
import { SpinnerWrapper } from '../../../../../shared/desig-system/spinner.component';
import { Typography } from '../../../../../shared/desig-system/typography.component';
import { TProposal } from '../../../data-access-payment-agreement-proposal/payment-agreement-proposal.model';
import { usePlanQuery } from '../../../data-access-plan-list/hooks';
import { TPaymentAgreementId } from '../../../data-access-plan-list/payment-plan.model';

interface IPaymentAgreementProposalSuccessProps {
  chosenProposalOption: TProposal;
  createdPlanId: TPaymentAgreementId;
}

export const PaymentAgreementProposalSuccess: FC<IPaymentAgreementProposalSuccessProps> = ({
  chosenProposalOption,
  createdPlanId,
}) => {
  const { i18n } = useTranslation();
  const { isLoading, isError } = usePlanQuery(createdPlanId);

  if (isError) {
    return (
      <Typography variant="h5" className="text-center">
        <Trans i18nKey={'PaymentAgreementProposals.ErrorLoadingCreatedPlan'} />
      </Typography>
    );
  }

  return (
    <SpinnerWrapper isLoading={isLoading}>
      <Typography variant="h4" className="font-bold">
        <Trans i18nKey={'PaymentAgreementProposals.SuccessCongratulation'} />
      </Typography>
      <Typography variant="body" className="mt-7">
        <Trans i18nKey={'PaymentAgreementProposals.SuccessBodyText'} />{' '}
        <span className="font-bold">
          {new Date(chosenProposalOption.FirstInstalmentDate).toLocaleDateString(i18n.language)}
        </span>
      </Typography>
      <div className="flex flex-col desktop:flex-row mt-4 gap-2 w-full relative">
        <Link to={`/payment-plans/${createdPlanId}`}>
          <B2Button variant="primary">
            <Trans
              i18nKey={
                'PaymentAgreementProposals.PayFirstInstalmentNowButton'
              } /* TODO: Add correct link path here once we have functionality to pay instalments*/
            />
          </B2Button>
        </Link>
        <Link to={`/payment-plans/${createdPlanId}`}>
          <B2Button variant="secondary">
            <Trans i18nKey={'PaymentAgreementProposals.PaymentPlanDetailsButton'} />
          </B2Button>
        </Link>
        <Link to="/home">
          <B2Button variant="secondary">
            <Trans i18nKey={'PaymentAgreementProposals.BackToHomeButton'} />
          </B2Button>
        </Link>
      </div>
    </SpinnerWrapper>
  );
};

import { RadioGroup } from '@headlessui/react';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TextField } from '../../../../shared/desig-system/input/text-field.component';
import { Typography } from '../../../../shared/desig-system/typography.component';
import { formatCurrencyValue, formatNumberValue, isValidInputNumber } from '../../../../shared/utils/formatting';
import { TUpcomingPayment } from '../../data-access-upcoming-payment/upcoming-payment.model';

export type TPaymentAmountSelectorMode = 'all' | 'custom';
interface IPaymentAmountSelectorProps {
  upcomingPayment: TUpcomingPayment;
  onChangeCustomAmount: (amount: number | null) => void;
  onChangeMode: (mode: TPaymentAmountSelectorMode) => void;
  mode: TPaymentAmountSelectorMode;
}

/**
 * Handle receiving user input for either "I want to pay the full amount" or a custom amount from the user
 */
export const PaymentAmountSelector: FC<IPaymentAmountSelectorProps> = ({
  upcomingPayment,
  onChangeCustomAmount,
  onChangeMode,
  mode,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleChangeMode = (mode: TPaymentAmountSelectorMode) => {
    if (mode === 'all') {
      onChangeCustomAmount(null);
    }
    if (mode === 'custom') {
      setTimeout(() => inputRef?.current?.focus(), 50); // To prevent race condition
    }
    onChangeMode(mode);
  };

  const checkValidity = (newValue?: string) => {
    const valueToCheck = newValue ? newValue : inputValue;
    if (
      valueToCheck !== '' &&
      (!isValidInputNumber(valueToCheck) ||
        formatNumberValue(valueToCheck) > upcomingPayment.MaxAmount ||
        formatNumberValue(valueToCheck) < upcomingPayment.MinAmount)
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    checkValidity(value);
    if (isValidInputNumber(value)) {
      const numberValue = formatNumberValue(value);
      onChangeCustomAmount(numberValue);
    } else {
      onChangeCustomAmount(null);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <table>
        <RadioGroup value={mode} onChange={handleChangeMode} as="tbody" className="flex flex-col gap-3 desktop:w-max">
          <RadioGroup.Option value="all" as="tr" className="cursor-pointer">
            {({ checked }) => (
              <>
                <td className="pr-3 align-top">
                  <input type={'radio'} readOnly checked={checked} />
                </td>
                <td>
                  <Typography variant="body">
                    <Trans i18nKey={'NewPaymentPage.PayAllNowLabel'} />
                  </Typography>
                  <Typography variant="body" className="mt-2">
                    <Trans i18nKey={'NewPaymentPage.PaymentAmount'} />
                  </Typography>
                  <Typography variant="body" className="font-bold">
                    {formatCurrencyValue(upcomingPayment.MaxAmount, upcomingPayment.Currency)}
                  </Typography>
                </td>
              </>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="custom" as="tr" className="cursor-pointer">
            {({ checked }) => (
              <>
                <td className="pr-3 align-top">
                  <input type={'radio'} readOnly checked={checked} />
                </td>
                <td>
                  <Typography variant="body">
                    {t('NewPaymentPage.PayCustomAmountLabel', {
                      minAmount: formatCurrencyValue(upcomingPayment.MinAmount, upcomingPayment.Currency),
                      maxAmount: formatCurrencyValue(upcomingPayment.MaxAmount, upcomingPayment.Currency),
                    })}
                  </Typography>
                  {!isValid && mode === 'custom' && (
                    <Typography variant="bodyTiny" className="text-error">
                      <Trans i18nKey={'NewPaymentPage.PaymentAmountMustBeBetweenMinMax'} />
                    </Typography>
                  )}
                  <TextField
                    ref={inputRef}
                    placeholder={t('NewPaymentPage.PaymentAmountPlaceholder', {
                      currency: upcomingPayment.Currency,
                    })}
                    value={inputValue}
                    onChange={handleOnChange}
                    onBlur={() => checkValidity()}
                  />
                </td>
              </>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </table>
    </div>
  );
};

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { B2Button } from '../../../../shared/desig-system/button.component';
import { Card } from '../../../../shared/desig-system/card.component';
import { Typography } from '../../../../shared/desig-system/typography.component';
import { getFeatureFlagStatus } from '../../../../shared/utils/feature-flags';
import { PaymentComplete, PaymentFailed } from '../../../../shared/utils/matomo.event';
import { getPaymentCategory, getPaymentSuccessEvent, useAiMatomo } from '../../../../shared/utils/tracking';
import { UPCOMING_PAYMENT_TYPE } from '../../data-access-upcoming-payment/upcoming-payment.model';
import { TPaymentAmountSelectorMode } from '../components/payment-amount-selector.component';

export const PaymentResult = () => {
  const { trackEvent } = useAiMatomo(useAppInsightsContext());
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const paymentType = params.get('paymentType') as UPCOMING_PAYMENT_TYPE;
  const amountMode = (params.get('amountMode') || 'all') as TPaymentAmountSelectorMode;
  const isSuccess = params.get('statusGroup') === 'completed';
  const showWarningThatPaymentUpdateIsDelayed = getFeatureFlagStatus('Portal.ShowWarningThatPaymentUpdateIsDelayed');

  useEffect(() => {
    trackEvent({
      category: getPaymentCategory(paymentType, amountMode),
      action: PaymentComplete.Action,
      name: PaymentComplete.Name,
    });
  }, [trackEvent, paymentType, amountMode]);

  useEffect(() => {
    if (!isSuccess) {
      trackEvent({
        category: getPaymentCategory(paymentType, amountMode),
        action: PaymentFailed.Action,
        name: PaymentFailed.Name,
      });
    }
  }, [trackEvent, paymentType, amountMode, isSuccess]);

  useEffect(() => {
    if (isSuccess && paymentType) {
      trackEvent(getPaymentSuccessEvent(paymentType, amountMode));
    }
  }, [trackEvent, paymentType, amountMode, isSuccess]);

  return (
    <Card className="p-5 flex flex-col gap-4">
      {isSuccess && (
        <Typography variant="h4">
          <Trans i18nKey={'PaymentResultPage.CardTitlePaymentSuccess'} />
        </Typography>
      )}
      {!isSuccess && (
        <Typography variant="h4">
          <Trans i18nKey={'PaymentResultPage.CardTitlePaymentFailed'} />
        </Typography>
      )}

      {isSuccess && showWarningThatPaymentUpdateIsDelayed === true && (
        <Typography variant="body" className="text-error">
          <Trans i18nKey={'PaymentResultPage.CardBodyPaymentSuccessWithWarnings'} />
        </Typography>
      )}
      {isSuccess && !showWarningThatPaymentUpdateIsDelayed && (
        <Typography variant="body">
          <Trans i18nKey={'PaymentResultPage.CardBodyPaymentSuccess'} />
        </Typography>
      )}

      {!isSuccess && (
        <Typography variant="body">
          <Trans i18nKey={'PaymentResultPage.CardBodyPaymentFailed'} />
        </Typography>
      )}

      <Link to="/home">
        <B2Button variant="primary">
          <Typography variant="button" className="my-auto">
            <Trans i18nKey={'PaymentResultPage.BackToHomeButton'} />
          </Typography>
        </B2Button>
      </Link>
    </Card>
  );
};

import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import companyLogo from '../assets/company_logo.png';
import { B2Button } from '../shared/desig-system/button.component';
import { Topbar } from '../shared/desig-system/top-bar.component';
import { Typography } from '../shared/desig-system/typography.component';
import { LanguageSelector } from '../shared/ui/language-select.component';
import { useBreakpoint } from '../shared/utils/media-queries';

type TTopbarButtonsProps = {
  showLogoutConfirmationModal: () => void;
};

const TopBarButtons: FC<TTopbarButtonsProps> = ({ showLogoutConfirmationModal }) => {
  return (
    <div className="flex flex-row justify-end grow max-h-full pr-4 gap-8">
      <div className="flex flex-row gap-4 items-center">
        <LanguageSelector
          renderOption={(option, isSelected, selectOption) => {
            return (
              <span
                key={option.value}
                className={`text-lg select-none ${
                  isSelected ? 'underline cursor-default' : 'hover:underline cursor-pointer'
                }`}
                onClick={() => {
                  if (!isSelected) {
                    selectOption();
                  }
                }}
              >
                {option.value.toUpperCase()}
              </span>
            );
          }}
        />
      </div>
      <B2Button
        variant="secondary"
        onClick={showLogoutConfirmationModal}
        className="relative max-w-max max-h-full desktop:py-0"
      >
        <span className="flex flex-row items-center">
          <i className="bx bx-lock-alt text-2xl pr-2px" />

          <Typography variant="body">
            <Trans i18nKey={'Navigationlink.Logout'} />
          </Typography>
        </span>
      </B2Button>
    </div>
  );
};

type TNavLayoutTopbarProps = {
  openSidebar: () => void;
  closeSidebar: () => void;
  showLogoutConfirmationModal: () => void;
  isAuthenticated: boolean;
  menuButtonIsHidden?: boolean;
};

export const NavLayoutTopBar = ({
  closeSidebar,
  openSidebar,
  showLogoutConfirmationModal,
  isAuthenticated,
  menuButtonIsHidden,
}: TNavLayoutTopbarProps) => {
  const isDesktopLayout = useBreakpoint('desktop');

  return (
    <Topbar
      onClick={closeSidebar}
      className={`
            ${isDesktopLayout ? 'min-h-[75px] max-h-[86px]' : 'min-h-[68px] max-h-[78px]'}
      `}
    >
      {!menuButtonIsHidden && !isDesktopLayout && isAuthenticated && (
        <i
          className="bx bx-menu text-2xl h-full max-h-full overflow-hidden aspect-square text-center flex flex-col justify-center cursor-pointer rounded-full transition-all duration-75 hover:text-3xl"
          onClick={(e) => {
            e.stopPropagation();
            openSidebar();
          }}
        />
      )}
      <div
        className={`
              absolute 
              left-0
              right-0
              top-0
              bottom-0
              pointer-events-none
              `}
      >
        {/* <Link to="/home">{resolveCompanyLogo(data?.CmsId)}</Link> */}
        <Link to="/home">
          <img
            alt="Company logo"
            src={companyLogo}
            className="absolute h-5/6 m-auto desktop:left-2 desktop:right-auto top-1 left-0 right-0 pointer-events-auto"
          />
        </Link>
      </div>

      {isDesktopLayout && isAuthenticated && (
        <TopBarButtons showLogoutConfirmationModal={showLogoutConfirmationModal} />
      )}
    </Topbar>
  );
};

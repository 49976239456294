import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

import { env } from '../../env.config';

export const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: env.logger.applicationInsightKey,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    distributedTracingMode: DistributedTracingModes.W3C,
    correlationHeaderExcludedDomains: [],
    correlationHeaderExcludePatterns: [],
    enableCorsCorrelation: true,
    disableCorrelationHeaders: false,
    disableCookiesUsage: true, // Disable by default, enable in cookie modal
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();

import i18n from '../../../i18n';
import { axiosInstance } from '../../../shared/utils/axios';
import { TInitiatePaymentRequest } from './initiate-payment-request.model';
import { initiatePaymentResponseSchema, TInitiatePaymentResponse } from './initiate-payment-response.model';
import { TPaymentStatusRequest } from './payment-status-request.model';
import { paymentStatusResponseSchema, TPaymentStatusResponse } from './payment-status-response.model';

export const initiatePaymentSession = async (request: TInitiatePaymentRequest) => {
  const res = await axiosInstance.post<TInitiatePaymentResponse>(
    `payment-providers/${request.PaymentProviderId}/payments`,
    request,
    {
      params: {
        lang: i18n.language,
      },
    }
  );

  return initiatePaymentResponseSchema.parse(res.data);
};

export const fetchPaymentSessionStatus = async (request: TPaymentStatusRequest) => {
  const res = await axiosInstance.get<TPaymentStatusResponse>(
    `payment-providers/${request.PaymentProviderId}/payments/${request.PaymentId}/status`
  );

  return paymentStatusResponseSchema.parse(res.data);
};

import { t } from 'i18next';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomerInfoContext, getPrimaryRecord } from '../../../../providers/customer-info.provider';
import { useContactFormTopics } from '../../../../shared/data-access/config/hooks';
import { useDebtsListQuery } from '../../../../shared/data-access/debts/hooks';
import { B2Button } from '../../../../shared/desig-system/button.component';
import { Card } from '../../../../shared/desig-system/card.component';
import { File } from '../../../../shared/desig-system/input/file.component';
import { MultiSelect } from '../../../../shared/desig-system/input/multiselect.component';
import { Select } from '../../../../shared/desig-system/input/select.component';
import { TextArea } from '../../../../shared/desig-system/input/text-area.component';
import { SpinnerWrapper } from '../../../../shared/desig-system/spinner.component';
import { Typography } from '../../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../../shared/utils/media-queries';
import { useFormSubmitMutation } from '../../data-access-contact/hooks';
import { TCmsId, TEmailAddress, TPhoneNumber } from '../../data-access-customer-info/customer-info.model';

const TEXT_AREA_LIMIT = 1000;

export interface IFormValues {
  SelectedIndexes: number[];
  Topic:
    | {
        translationKey: string;
        value: string;
      }
    | undefined;
  EmailAddress: string | undefined;
  MessageText: string;
  Files?: FileList;
  CmsId: TCmsId;
  CustomerId: string | undefined;
  PhoneNumber: string | undefined;
}

// Modified so that Send only navigates to a mailto: link.
// Original onSubmit function, email field and file input commented out.
export const ContactForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm<IFormValues>();

  const [selectDisabled, setSelectDisabled] = useState(false);

  const debts = useDebtsListQuery();
  const configTopics = useContactFormTopics();
  const [topics, setTopics] = useState([] as { translationKey: string }[]);
  const customerInfo = useContext(CustomerInfoContext);
  const submitMutation = useFormSubmitMutation(debts.data || []);
  const [fileError, setFileError] = useState<boolean>(false);

  const [text, setText] = useState('');

  const validateFiles = (files: FileList) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
    const maxSize = 5 * 1024 * 1024;

    if (files.length > 5) {
      return false;
    }
    if (Array.from(files).some((file) => file.size > maxSize)) {
      setFileError(true);
      setError('Files', {
        message: t('Input.FileSizeError'),
      });
      return false;
    }
    if (Array.from(files).some((file) => !validTypes.includes(file.type))) {
      return false;
    }
    return true;
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors('Files');
    if (!e.target?.files) {
      setText('');
      return setError('Files', {
        message: t('Input.FileTypeError'),
      });
    }
    if (validateFiles(e.target?.files)) {
      setText(
        Array.from(e.target.files)
          .map((file) => file.name)
          .join(', ')
      );
      setValue('Files', e.target.files);
      return e.target.files;
    }
    setError('Files', {
      message: t('Input.FileTypeError'),
    });
  };

  useEffect(() => {
    if (fileError) {
      setText('');
      setError('Files', {
        message: t('Input.FileTypeError'),
      });
    }
  }, [fileError, setError]);

  useEffect(() => {
    const topic: {
      translationKey: string;
      value: string;
    }[] = [];
    if (configTopics.isSuccess) {
      for (const prop in configTopics.data.ContactFormTopics) {
        const obj = configTopics.data.ContactFormTopics[prop];
        topic.push({
          translationKey: obj.translationKey,
          value: t(obj.translationKey),
        });
      }
      setTopics(topic);
    }
  }, [configTopics.isSuccess, configTopics.data]);

  useEffect(() => {
    if (customerInfo?.data) {
      setValue('CmsId', customerInfo.data.CmsId);
      setValue('CustomerId', customerInfo.data.CustomerId);
    }
    setValue('EmailAddress', getPrimaryRecord<TEmailAddress>(customerInfo?.data?.EmailAddresses)?.Email ?? '');
    setValue('PhoneNumber', getPrimaryRecord<TPhoneNumber>(customerInfo?.data?.PhoneNumbers)?.PhoneNumber ?? '');
  }, [customerInfo, setValue]);

  useEffect(() => {
    if (debts.data && debts.data.length === 1) {
      setValue('SelectedIndexes', [0]);
      setSelectDisabled(true);
    }
  }, [debts.data, setValue]);

  useEffect(() => {
    if (submitMutation.isSuccess) navigate('/thank-you');
  }, [navigate, submitMutation.isSuccess]);

  const onSubmit: SubmitHandler<IFormValues> = ({ ...data }) => {
    submitMutation.mutate(data);
  };

  const isDesktop = useBreakpoint('desktop');

  return (
    <Card className={`${!isDesktop && 'max-w-[340px]'}`}>
      {!debts.isError ? (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="p-8 flex flex-col gap-3">
          <Typography variant="h4">
            <Trans i18nKey={'ContactView.Title'} />
          </Typography>

          <div className="flex flex-row justify-between">
            <Typography variant="button">
              <Trans i18nKey={'ContactView.LabelTopic'} />
            </Typography>
            <Typography variant="body" className="text-error">
              {errors.Topic && <>{t('Errors.FieldEmpty')}</>}
            </Typography>
          </div>
          <Controller
            control={control}
            name="Topic"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                name="foobar"
                placeholder={t('ContactView.PlaceholderTopic')}
                onChange={onChange}
                items={topics}
                displayKey="translationKey"
                useLabelTranslation={true}
                selected={value || null}
                disabled={false}
              />
            )}
          />

          <div className="flex flex-row justify-between">
            <Typography variant="button">
              <Trans i18nKey={'ContactView.LabelSelect'} />
            </Typography>
            <Typography variant="body" className="text-error">
              {errors.SelectedIndexes && <>{t('Errors.FieldEmpty')}</>}
            </Typography>
          </div>
          <SpinnerWrapper isLoading={debts.isLoading}>
            {debts.data && (
              <Controller
                control={control}
                name="SelectedIndexes"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    name="SelectedIndexes"
                    selected={value || []}
                    placeholder={t('ContactView.PlaceholderSelect')}
                    items={debts.data}
                    onChange={onChange}
                    displayKey={'ClaimName'}
                    useLabelTranslation={false}
                    disabled={selectDisabled}
                  />
                )}
              />
            )}
          </SpinnerWrapper>
          {debts.isError && 'Error in debts.'}

          <div className="flex flex-row justify-between">
            <Typography variant="button">
              <Trans i18nKey={'ContactView.LabelMessage'} />
            </Typography>
            <Typography variant="body" className="text-error">
              {errors.MessageText && <>{t('Errors.FieldEmpty')}</>}
            </Typography>
          </div>
          <TextArea
            placeholder={t('ContactView.PlaceholderMessage')}
            maxLength={TEXT_AREA_LIMIT}
            {...register('MessageText', { required: true })}
          />

          <div />

          <div className="flex flex-col justify-between">
            <Typography variant="button">
              <Trans i18nKey={'ContactView.LabelAttachment'} />
            </Typography>
            <Typography variant="bodyTiny">
              <Trans i18nKey={'ContactView.LabelAttachmentInfo'} />
            </Typography>

            <Typography variant="body" className="text-error">
              {errors.Files && <>{t('Errors.FileUploadError')}</>}
            </Typography>
          </div>

          <File
            onFileChange={onFileChange}
            resetFunc={() => {
              reset((formValues) => ({
                ...formValues,
                Files: undefined,
              }));
            }}
            setText={setText}
            text={text}
            multiple
          />

          <Typography variant="body" className="text-error text-center h-5 -mb-2 -mt-3">
            {submitMutation.isError && <Trans i18nKey={'Errors.SubmitFailed'} />}
          </Typography>
          <SpinnerWrapper isLoading={submitMutation.isPending}>
            <B2Button variant="primary" type="submit">
              <Typography variant="button">
                <Trans i18nKey={'ContactView.ButtonSubmit'} />
              </Typography>
            </B2Button>
          </SpinnerWrapper>
        </form>
      ) : (
        <Typography variant="h1" className="p-2">
          <Trans i18nKey={'ContactView.ClaimError'} />
        </Typography>
      )}
    </Card>
  );
};

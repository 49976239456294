import { FC, PropsWithChildren, ReactNode, useState } from 'react';

import { Card } from './card.component';
import { Typography } from './typography.component';

export interface IDataCardProps extends PropsWithChildren {
  label?: {
    left?: ReactNode;
    right?: ReactNode;
  };
  collapsibleFooter?: {
    collapsedLabel: ReactNode;
    onLabelClick: () => void;
    isExpanded: boolean;
    expandedComponent: ReactNode;
    expandToggleable: boolean;
  };
  className?: string;
}

export const DataCard: FC<IDataCardProps> = ({ children, label, collapsibleFooter, className }) => {
  const [expandOpen, setExpandOpen] = useState(collapsibleFooter?.isExpanded);
  return (
    <Card className={className}>
      {label && (
        <div className="flex flex-row justify-between">
          {label.left ? (
            <Typography variant="h6" className="bg-linkbutton px-4 mt-6">
              {label.left}
            </Typography>
          ) : (
            <div></div>
          )}
          <Typography variant="label" className="mt-5 pr-3">
            {label.right}
          </Typography>
        </div>
      )}
      {children}
      {collapsibleFooter && (
        <>
          {(!expandOpen || collapsibleFooter.expandToggleable) && (
            <>
              <hr className="border-t-1 border-t-dividerslight" />
              <div
                className="px-8 flex justify-between py-2 hover:bg-linkbuttonhover cursor-pointer"
                onClick={() => {
                  if (collapsibleFooter.expandToggleable) setExpandOpen(!expandOpen);
                  collapsibleFooter.onLabelClick();
                }}
              >
                <Typography
                  variant="body"
                  className={`my-auto ${collapsibleFooter.expandToggleable && 'text-primaryblue'}`}
                >
                  {collapsibleFooter.collapsedLabel}
                </Typography>
                <i
                  className={`bx ${
                    collapsibleFooter.expandToggleable
                      ? `${expandOpen && 'bx-chevron-up'} ${!expandOpen && 'bx-chevron-down'} text-primaryblue`
                      : 'bx-chevron-right text-dividersdark'
                  } text-2xl`}
                />
              </div>
            </>
          )}
          {expandOpen && collapsibleFooter.expandedComponent}
        </>
      )}
    </Card>
  );
};

import { Trans } from 'react-i18next';

import { useDebtsTotalAmountQuery } from '../../../../shared/data-access/debts/hooks';
import { SpinnerWrapper } from '../../../../shared/desig-system/spinner.component';
import { Typography } from '../../../../shared/desig-system/typography.component';
import { useBreakpoint } from '../../../../shared/utils/media-queries';
import { TotalDebtCard } from '../../ui-card-payment/debt-card.component';
import { PaymentTabs } from '../../ui-card-payment/payment-tabs.component';

export const PaymentsList = () => {
  const total = useDebtsTotalAmountQuery();
  const isDesktop = useBreakpoint('desktop');

  return (
    <>
      <div
        className={`${!isDesktop ? 'max-w-[340px] gap-2 flex flex-col' : 'gap-2 flex flex-col w-full min-h-[100px]'}`}
      >
        <Typography variant="h1">
          <Trans i18nKey="PaymentsView.Title" />
        </Typography>
        <SpinnerWrapper isLoading={total.isLoading} className={`min-h-[100px]`}>
          {total.data && <TotalDebtCard debtsTotalAmountData={total.data} />}
        </SpinnerWrapper>

        <PaymentTabs />
      </div>
    </>
  );
};

import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';

import { useBreakpoint } from '../utils/media-queries';
import { twMerge } from './tailwind.config';

export interface IB2ModalTitleProps {
  children?: ReactNode;
}

const B2ModalTitle = ({ children }: IB2ModalTitleProps) => {
  return <Dialog.Title className="font-bold text-xl mb-12px">{children}</Dialog.Title>;
};

export interface IModalProps {
  isOpen: boolean;
  onRequestClose: (value: boolean) => void;
  children?: ReactNode;
  className?: string;
}
export interface IModalSubComponents {
  Title: typeof B2ModalTitle;
}

export const B2Modal: FC<IModalProps> & IModalSubComponents = ({ isOpen, onRequestClose, children, className }) => {
  const isDesktop = useBreakpoint('desktop');
  return (
    <Transition show={isOpen}>
      <Dialog onClose={onRequestClose} className="relative">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-70"
          leave="ease-in duration-150"
          leaveFrom="opacity-70"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-backdropoverlay bg-opacity-75" aria-hidden="true" />
        </Transition.Child>

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center px-16px py-48px flex-row">
          {/* The actual dialog panel  */}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-30 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-30 scale-95"
          >
            <Dialog.Panel
              className={`${
                isDesktop
                  ? twMerge(
                      'flex-1 relative rounded bg-contentbglight px-4 py-7 max-w-[600px] desktop:max-w-[700px] desktop:max-h-[850px] overflow-auto',
                      className
                    )
                  : twMerge(
                      'flex-1 relative rounded bg-contentbglight px-4 py-7 max-w-[600px] max-h-full overflow-auto',
                      className
                    )
              }`}
            >
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

B2Modal.Title = B2ModalTitle;

import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { B2Button } from '../../../shared/desig-system/button.component';
import { Card } from '../../../shared/desig-system/card.component';
import { Typography } from '../../../shared/desig-system/typography.component';

export const ThankYouCard = () => {
  return (
    <Card className="p-5 flex flex-col gap-4">
      <Typography variant="h4">
        <Trans i18nKey={'ThankYouCard.Title'} />
      </Typography>
      <Link to="/home">
        <B2Button variant="primary">
          <Typography variant="button" className="my-auto">
            <Trans i18nKey={'ThankYouCard.Button'} />
          </Typography>
        </B2Button>
      </Link>
    </Card>
  );
};

import { Tab } from '@headlessui/react';
import { Children, FC, PropsWithChildren } from 'react';

import { Typography } from './typography.component';

export const TabView: FC<
  PropsWithChildren<{
    headers: string[];
  }>
> = ({ headers, children }) => {
  return (
    <Tab.Group>
      <Tab.List className="flex overflow-hidden">
        {headers.map((name, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              `flex-1 h-12 focus:outline-none ${
                selected ? 'border-b-4 border-b-dividersdark' : 'border-b border-b-dividerslight pb-[3px]'
              }`
            }
          >
            <Typography variant="label" className="cursor-pointer">
              {name}
            </Typography>
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {Children.toArray(children).map((child, index) => (
          <Tab.Panel key={index}>{child}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

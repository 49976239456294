import { Dispatch, FC, MouseEventHandler, ReactNode, SetStateAction, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';

import { twMerge } from '../shared/desig-system/tailwind.config';
import { Typography } from '../shared/desig-system/typography.component';
import { LanguageSelector } from '../shared/ui/language-select.component';

interface ISidebarLinkProps {
  to?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  icon: ReactNode;
  label: ReactNode;
  className?: string;
}

const SidebarLink: FC<ISidebarLinkProps> = ({ to, disabled, onClick, icon, label, className }) => {
  const [isHovered, setIsHovered] = useState(false);
  const matchesUrl = useMatch(to ? `${to}/*` : '');
  const isCurrentlyActive = matchesUrl && to;

  return (
    <Link
      to={to ?? ''}
      className={`relative ${disabled ? 'pointer-events-none opacity-30' : ''} `}
      onClick={(evt) => {
        if (!to && onClick !== undefined) {
          evt.preventDefault();
          onClick?.(evt);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={twMerge(
          `
        pl-8 
        mr-4 
        py-2 
        flex 
        flex-row 
        items-center 
        rounded-r-full 
        break-word
        overflow-hidden
        text-start
        ${isCurrentlyActive ? 'bg-primaryblue text-primarywhite' : 'text-primaryblue'}`,
          className
        )}
      >
        {icon}
        <span className={`${isHovered ? 'underline' : ''}`}>{label}</span>
      </div>
    </Link>
  );
};

const SidebarDivider = () => {
  return <div className=" border-t-black border-t mx-8 my-2 box-border" />;
};

interface ISidebarProps {
  open: boolean;
  isDesktop?: boolean;
  setShowLogoutConfirmationModal: Dispatch<SetStateAction<boolean>>;
  hidden?: boolean;
}
export const Sidebar: FC<ISidebarProps> = ({ open, isDesktop, hidden, setShowLogoutConfirmationModal }) => {
  return (
    <div className={`${hidden ? 'hidden' : ''}`}>
      <div
        className={`
        ${hidden || isDesktop ? 'hidden' : ''}
        ${open ? 'opacity-70' : 'opacity-0'} 
        ${open ? 'visible' : 'invisible'}
        fixed
        z-50
        w-full
        h-full
        bg-backdropoverlay
        transition-all
        duration-200
        pointer-events-none
        `}
      />

      <div
        className={`
        ${isDesktop ? 'block h-full' : 'fixed z-50 top-0 bottom-0'}
        ${isDesktop ? 'bg-primarywhite' : ' bg-contentbglight'}
        ${open ? 'left-0' : 'left-[-100%]'}
        min-w-[240px] 
        max-w-[240px]
        overflow-y-auto
        overflow-x-auto
        pt-24px
        transition-all
        ease-in
        duration-200`}
      >
        <nav>
          <SidebarLink
            to="/home"
            icon={<i className="bx bx-home text-2xl pr-8px" />}
            label={
              <Typography variant="body">
                <Trans i18nKey={'Navigationlink.Home'} />
              </Typography>
            }
          />
          <SidebarLink
            to="/debts"
            icon={<i className="bx bx-info-circle text-2xl pr-8px" />}
            label={
              <Typography variant="body">
                <Trans i18nKey={'Navigationlink.DebtInformation'} />
              </Typography>
            }
          />

          <SidebarLink
            to="/payments"
            icon={<i className="bx bx-money text-2xl pr-8px" />}
            label={
              <Typography variant="body">
                <Trans i18nKey={'Navigationlink.Payments'} />
              </Typography>
            }
          />

          <SidebarLink
            to="/payment-plans"
            icon={<i className="bx bx-star text-2xl pr-8px" />}
            label={
              <Typography variant="body">
                <Trans i18nKey={'Navigationlink.PaymentPlans'} />
              </Typography>
            }
          />
          <SidebarLink
            to="/my-data"
            icon={<i className="bx bx-user text-2xl pr-8px" />}
            label={
              <Typography variant="body">
                <Trans i18nKey={'Navigationlink.MyData'} />
              </Typography>
            }
          />

          <SidebarLink
            to="/contact"
            icon={<i className="bx bx-support text-2xl pr-8px" />}
            label={
              <Typography variant="body">
                <Trans i18nKey={'Navigationlink.ContactSupport'} />
              </Typography>
            }
          />

          <SidebarDivider />

          <SidebarLink
            onClick={() => setShowLogoutConfirmationModal(true)}
            icon={<i className="bx bx-lock-alt text-2xl pr-8px" />}
            label={
              <Typography variant="body">
                <Trans i18nKey={'Navigationlink.Logout'} />
              </Typography>
            }
          />

          {!isDesktop && (
            <>
              <SidebarDivider />
              <LanguageSelector
                renderOption={(option, isSelected, selectOption) => {
                  return (
                    <SidebarLink
                      key={option.value}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectOption();
                      }}
                      icon={
                        <Typography
                          variant="body"
                          className="min-w-[32px] min-h-[32px] max-h-[32px] max-w-[32px] flex items-center justify-start"
                        >
                          {option.value.toUpperCase()}
                        </Typography>
                      }
                      label={
                        <Typography variant="body" className={`${isSelected ? 'underline' : ''}`}>
                          {option.label}
                        </Typography>
                      }
                    />
                  );
                }}
              />
            </>
          )}
        </nav>
      </div>
    </div>
  );
};

import { Combobox } from '@headlessui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ICommonSelectProps<T extends Record<string, string>> {
  name: string;
  items: Array<T>;
  placeholder: string;
  displayKey: keyof T;
  disabled: boolean;
  useLabelTranslation: boolean;
}

export interface ISelectProps<T extends Record<string, string>> extends ICommonSelectProps<T> {
  selected: T | null;
  onChange: (value: T) => void;
}

export const Select = <T extends Record<string, string>>({
  name,
  items,
  placeholder,
  selected,
  onChange,
  displayKey,
  disabled,
  useLabelTranslation,
}: ISelectProps<T>) => {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const filteredItems =
    query === ''
      ? items
      : items.filter((item) =>
          item[displayKey].toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  function toDisplayValue(useTranslation: boolean, displayKey: keyof T, item: T | null) {
    return useTranslation && item ? t(item[displayKey]) : item ? item[displayKey] : '';
  }

  return (
    <div className="w-full">
      <Combobox name={name} value={selected} onChange={onChange} disabled={disabled}>
        <div
          className={`
            flex 
            w-full
            h-12
            text-left
            rounded
            border
            border-formborders
            pl-3
            ${disabled ? 'text-textdisabled bg-contentbglight' : ''}
            `}
        >
          <Combobox.Input
            className="
              w-full
              focus:outline-none
            disabled:text-textdisabled
            disabled:bg-contentbglight
            placeholder:text-placeholdertext
            placeholder:disabled:text-buttondisabled
            "
            placeholder={placeholder}
            value={typeof selected === 'string' ? selected : toDisplayValue(useLabelTranslation, displayKey, selected)}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
          />
          <Combobox.Button>
            <i className="h-full pt-1 px-1 bx bx-chevron-down text-3xl" />
          </Combobox.Button>
        </div>
        {filteredItems.length > 0 && (
          <div className="relative">
            <Combobox.Options
              className="
              absolute
              z-10
              top-[1px]
              text-left
              rounded
              border
              w-full
              focus:outline-none
              bg-primarywhite
              disabled:text-textdisabled
              disabled:bg-contentbglight
              placeholder:text-placeholdertext
              placeholder:disabled:text-buttondisabled
              border-formborders
            "
            >
              {filteredItems.map((item, index) => (
                <Combobox.Option
                  key={index}
                  className={({ active }) =>
                    `cursor-default select-none py-2 px-3 ${active && 'bg-buttonhover text-primarywhite'}`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span className={`${selected ? 'font-medium' : 'font-normal'}`}>
                        {toDisplayValue(useLabelTranslation, displayKey, item)}
                      </span>
                      {selected ? <span className="bg-buttonhover text-primarywhite"></span> : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        )}
      </Combobox>
    </div>
  );
};

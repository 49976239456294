import { z } from 'zod';

export const featureFlagSchema = z.object({
  name: z.string(),
  enabled: z.boolean(),
});

export const featureFlagsSchema = z.array(featureFlagSchema);

export type TFeatureFlag = z.infer<typeof featureFlagSchema>;
